#faceted-form {
  border-top: 3px solid #999;
  padding-top: 30px;
  margin-top: 50px;

  .plain-txt #faceted-results {
    td {
      font-weight: normal !important;
    }
  }

  fieldset {
    border: unset;
  }

  .faceted-results {
    .description {
      line-height: 1.59;
      font-size: 1.063rem;
    }
  }

  #faceted-top-column {
    padding-bottom: 40px;
  }

  #center-area {
    border-top: 3px solid #999;
    padding-top: 50px;
  }

  .faceted-top-widgets {
    width: calc(100% + 40px) !important;
    margin-left: calc(40px / -2) !important;
    margin-right: calc(40px / -2) !important;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: calc(10px / -2);
    margin-bottom: calc(10px / -2);
  }

  .faceted-widget {
    width: calc(99.99999% * 4 / 12);
    padding-left: calc(40px / 2) !important;
    padding-right: calc(40px / 2) !important;

    legend {
      font-size: 1.1rem;
    }

    &.section-funktion {
      legend {
        min-height: 33px;
        @media (max-width: 104em) {
          padding-top: 32px;
        }
      }
    }

    &.faceted-select-widget label.select-field {
      position: absolute;
      left: -10000px;
      top: auto;
      width: 1px;
      height: 1px;
      overflow: hidden;
    }
  }

  .faceted-alphabetic-widget {
    width: 100%;

    legend {
      text-align: left;
    }

    .faceted_letter_selected {
      color: black !important;
    }

    .faceted_letter:hover {
      color: black;
    }

    .faceted_letter {
      color: #666;
      font-size: 1rem;
      font-weight: 700;
      cursor: pointer;
      padding: 0 0.2em;
      background-color: transparent;
    }

    .faceted_letter_selected {
      font-size: 1.5rem;
    }

    .widget-body {
      text-align: left;
    }
  }

  table#staffmember_list tr {
    border-bottom: 1px solid #e1e1df;
  }

  @media screen and (max-width: 768px) {
    .faceted-widget {
      width: 100%;
    }
  }
}
