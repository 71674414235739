#page-footer {
  margin: 0 auto;
  width: 100%;
  max-width: 104em;
  padding: 0 20px;
  @media (min-width: 52em) {
    padding: 0 calc(15.625vw + -3.75rem);
  }
  @media (min-width: 72em) {
    padding: 0 calc(23.4375vw + -8.75rem);
  }
  @media (min-width: 104em) {
    padding: 0 250px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  a {
    color: #333333;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 500;
  }

  img {
    height: 16px;
    float: left;
    padding-right: 4px;
  }

  .nav {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @media (min-width: 1400px) {
      flex-direction: row;
    }

    .social-links {
      padding: 20px 0;

      li {
        line-height: 16px;
        display: inline-block;
        padding: 0 5px 0 0;
        @media (max-width: 52em) {
          padding-right: 20px;
        }
        &:last-child {
          padding: 0;
        }

        a {
          display: flex;
        }

        img {
          height: 16px;
          @media (max-width: 52em) {
            height: 30px;
            width: 30px;
          }
        }
      }
    }

    .footer-nav {
      padding: 20px 0;

      li {
        line-height: 16px;
        display: inline-block;
        padding: 0 10px 0 0;

        &:last-child {
          padding: 0;
        }
      }
    }
  }

  .logos {
    border-top: 1px solid gray;

    ul {
      padding: 20px 0;
      text-align: right;

      li {
        display: inline-block;
        padding: 0 0 0 100px;

        &:first-child {
          padding: 0;
        }

        a {
          display: flex;
        }

        img {
          display: block;
          height: 68px;
        }
      }
    }
  }
}
