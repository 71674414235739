/* Fixes the bootstrap css *
   Todo
*/

section[class^="metainfo-"] {
  margin-top: 70px !important;
}

div[class^="metainfo-"] a {
  color: #333;
}

.metainfo-white {
  background-color: #ffffff;
  color: #000;
}

.metainfo-orange {
  background-color: #fee6c7;
  color: #000;
}

.metainfo-bbf-red {
  background-color: #bf6060;
  color: #000;
}

.metainfo-raspberry-red {
  background-color: #de5e73;
  color: #000;
}

.metainfo-violet-blue {
  background-color: #5560a4;
  color: #fff;
}

.metainfo-signal-blue {
  background-color: #004a7f;
  color: #fff;
}

.metainfo-dipf-blue {
  background-color: #5c8db6;
  color: #000;
}

.metainfo-dipf-grey-hell {
  background-color: #dbdcdd;
  color: #000;
}

.metainfo-dipf-grey {
  background-color: #8b8c8e;
  color: #000;
}

.metainfo-may-green {
  background-color: #e8eccd;
  color: #000;
}

.metainfo-may-green {
  background-color: #7dba61;
  color: #000;
}
