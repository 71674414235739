// The base css from the leibniz prototyp
@import "./leibniz/app.scss";
@import "./leibniz/lib.css";

// Dipf custom css
@import "custom-scss/staffmember";
@import "custom-scss/footer";
@import "custom-scss/portalMessage";
@import "custom-scss/faceted_navigation";
@import "custom-scss/metainfo";
@import "custom-scss/plone_loader_spinner";
@import "custom-scss/search";

// Basic bootstrap vars
@import "fonts/fonts";

$font-family-base: "Signika", sans-serif;
$headings-font-family: "Signika", sans-serif;
$headings-font-weight: 500;

:root {
  -webkit-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}

* {
  scroll-margin-top: 80px;
}

.column,
.columns {
  float: left;
  min-height: 1px;
  padding: 0 30px;
  position: relative;
}

/* */
.one,
.row .one {
  width: 8.33333%;
}

/* */
.two,
.row .two {
  width: 16.66667%;
}

/* */
.three,
.row .three {
  width: 25%;
}

/* */
.four,
.row .four {
  width: 33.33333%;
}

/* */
.five,
.row .five {
  width: 41.66667%;
}

/* */
.six,
.row .six {
  width: 50%;
}

/* */
.seven,
.row .seven {
  width: 58.33333%;
}

/* */
.eight,
.row .eight {
  width: 66.66667%;
}

/* */
.nine,
.row .nine {
  width: 75%;
}

/* */
.ten,
.row .ten {
  width: 83.33333%;
}

/* */
.eleven,
.row .eleven {
  width: 91.66667%;
}

/* */
.twelve,
.row .twelve {
  width: 100%;
}

.image-left {
  float: left;
  margin: 0.5em 1em 0.5em 0 !important;
  pointer-events: auto;
}

.image-right {
  float: right;
  margin: 0.5em 0 0.5em 1em !important;
  pointer-events: auto;
}

.module {
  ul {
    margin-top: 30px;

    li {
      padding-left: 2.5em;
      counter-increment: ul-li;
      position: relative;

      &:before {
        display: inline-block;
        width: 1.75em;
        text-align: right;
        position: absolute;
        top: 0;
        right: calc(100% - 1.75em);
        font-size: inherit;
        color: inherit;
        height: 1em;
        content: counter(ul-li, disc);
      }
    }
  }

  .text-container a {
    color: inherit;
    text-decoration: none;
    background-image: linear-gradient(currentColor, currentColor);
    background-size: 1px 1px;
    background-position: 0 bottom;
    background-repeat: repeat-x;
    background-clip: border-box;
  }

  &.banner {
    .stop {
      margin-top: 30px;
    }
  }

  &.banner_leibniz {
    .bg-dipf-blue {
      background-color: #c5dbf0;
    }

    img {
      max-height: 100%;
    }

    .advert-main-inner {
      padding-top: 20px;
      @media screen and (max-width: 768px) {
        padding-top: 0px !important;
      }
      @media screen and (min-width: 36em) and (max-width: 51.999em) {
        padding-top: 0px !important;
      }
    }
  }

  &.mediathek {
    .dipf-border {
      border-top: 4px solid #cfcfcf;
      @media only screen and (max-width: 1150px) {
        border-top: 0 !important;
      }
    }

    .col {
      padding-left: 0;
      padding-right: 0;
    }

    .result__image,
    .result__body,
    .teaser-link {
      padding-right: calc(var(--bs-gutter-x) / 2);
      padding-left: calc(var(--bs-gutter-x) / 2);
    }
  }

  &.relation {
    .item-illu {
      @media screen and (max-width: 35.999em) {
        display: block !important;
        width: calc(99.99999% * 3 / 4);
      }
    }

    .item-text {
      @media screen and (max-width: 35.999em) {
        width: calc(99.99999% * 3 / 4);
        margin-left: calc(99.99999% * 0.5 / 4);
        margin-right: calc(99.99999% * 0.5 / 4);
      }
    }
  }

  &.moduletype-relationmodule {
    &.modulevariant-three_item_row_landingpages {
      .teaser-item {
        background-color: #c5dbf0;

        .mb-4 img {
          object-position: center center;
          aspect-ratio: 2/1;
          object-fit: cover;
          height: 200px;
          width: 100%;
        }

        .link-outer {
          padding: 20px;
        }
      }
    }
  }
}

.modulesEdit {
  margin-top: 10px;
}

#module_aktuelles a {
  color: inherit;
}

// Special Square image in Apropos module - https://support.starzel.de/issues/12481
.modulevariant-three_item_row {
  img {
    margin: 0 auto;
    height: 330px;
    min-height: 300px;
    width: 100%;
    object-fit: cover;
    @media screen and (min-width: 52em) and (max-width: 71.999em) {
      height: 230px;
      min-height: 200px;
    }
    @media (min-width: 35em) and (max-width: 51.999em) {
      height: 130px;
      min-height: 100px;
    }
  }
}

.plain-txt > ul > li,
.plain-txt > ol > li {
  position: relative;
  padding-left: 2.5em;
}

.plain-txt > ul > li::before {
  content: counter(ul-li, disc);
}

.plain-txt > ul.accordion > li {
  padding-left: 0;
}

.plain-txt > ul.accordion > li::before {
  content: none;
}

.plain-txt > ul > li > ul > li::before {
  position: absolute;
  content: counter(ul-li, disc);
  right: calc(100% - 3.25em);
}

.plain-txt > ul > li > ul > li,
.plain-txt > ol > li > ol > li {
  padding-left: 1.5em;
}

.plain-txt .entries {
  padding-top: 40px;
}

.portaltype-frontpage {
  #portal-slider {
    .container {
      max-width: 100% !important;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.gridImageContainer {
  figcaption {
    float: right;
  }
}

body.template-newsitem_view {
  #primary-area > .sans-t1.strong {
    margin-top: 0;
  }

  .newsImageContainer {
    figcaption {
      float: right;
    }
  }

  #content-core {
    .documentDescription {
      margin-bottom: 30px;
    }
  }
}

.news-date {
  margin-bottom: 30px;
  text-transform: uppercase;
  font-size: 15px;
}

.news-category {
  display: inline list-item;
  font-size: 14px;
  text-transform: uppercase;
}

.peoplePortrait {
  padding: 0;
}

.peopleContact {
  padding-left: 15px;
}

#page-header {
  #portal-logo {
    width: 170px;
    height: 100px;
  }

  .search-toggle label {
    cursor: pointer;
  }

  #portal-languageselector {
    padding-top: 0;

    li {
      float: left;
      margin-left: 0;
      margin-right: 0;
      display: block;

      &.currentLanguage {
        display: none;
      }
    }

    span {
      float: left;
    }
  }

  #cookieswitcher {
    padding-bottom: 0;
    bottom: 0;
    margin-left: 20px;

    a:hover {
      color: unset;
    }
  }

  .dipfpresse {
    display: none;
  }

  .menu-meta #personaltools-login {
    color: transparent;
  }
}

body[data-subsite-id="dipf"] {
  .dipfpresse {
    display: block !important;
  }
}

#portal-breadcrumbs {
  a,
  span {
    text-transform: uppercase;
  }
}

#page-main {
  background: #f2f5f7;
}

#page-footer {
  .columns {
    width: 200px;
  }
}

/* */

.plone-toolbar-left #menu-desktop .tabs-inner.fix {
  left: 30px;
}

.plone-toolbar-left.plone-toolbar-left-expanded #menu-desktop .tabs-inner.fix {
  left: 110px;
}

/* Hide toolbar for authenticated users without editing-roles */
body.userrole-authenticated #edit-zone,
body.userrole-reader #edit-zone {
  display: none;
}

body.userrole-owner #edit-zone,
body.userrole-contributor #edit-zone,
body.userrole-editor #edit-zone,
body.userrole-manager #edit-zone,
body.userrole-reviewer #edit-zone,
body.userrole-site-administrator #edit-zone {
  display: block;
}

body.plone-toolbar-left-expanded.userrole-authenticated,
body.plone-toolbar-left-expanded.userrole-reader,
body.plone-toolbar-left-default.userrole-authenticated,
body.plone-toolbar-left-default.userrole-reader {
  padding-left: 0;
}

body.plone-toolbar-left-expanded.userrole-owner,
body.plone-toolbar-left-expanded.userrole-contributor,
body.plone-toolbar-left-expanded.userrole-editor,
body.plone-toolbar-left-expanded.userrole-manager,
body.plone-toolbar-left-expanded.userrole-reviewer,
body.plone-toolbar-left-expanded.userrole-site-administrator {
  padding-left: 120px;
}

body.plone-toolbar-left-default.userrole-owner,
body.plone-toolbar-left-default.userrole-contributor,
body.plone-toolbar-left-default.userrole-editor,
body.plone-toolbar-left-default.userrole-manager,
body.plone-toolbar-left-default.userrole-reviewer,
body.plone-toolbar-left-default.userrole-site-administrator {
  padding-left: 60px;
}

/* */

.description {
  line-height: 1.125;
}

body .plain-txt {
  margin-top: 0 !important;
}

#login-form #buttons-login {
  border: 3px solid #d7733c;
  padding: 12px 25px;
}

#login-form .footer {
  padding: 10px 0 0 0;
  display: none;
}

.navTreeLevel0 > .navTreeItem {
  padding-bottom: 10px;
}

.navTreeLevel0 > .navTreeItem > a {
  font-weight: bold;
}

.navTreeLevel0 > .navTreeItem > a span {
  padding: 10px 30px;
  width: 100%;
  border: 2px solid #999;
}

.navTreeLevel2 {
  /* padding: 15px 0 0 30px; */
  padding: 0 0 0 30px;
}

.navTreeLevel3 {
  /* padding: 10px 0 10px 30px; */
  padding: 0 0 0 30px;
}

/* .listing-cell:nth-child(odd) { */

/*    background-color:#fbfbfb; */

/* } */

#portal-sitemap a {
  background: none;
}

#portal-sitemap a:hover {
  color: #000;
}

.tree {
  min-height: 20px;

  /* padding:19px; */

  /* margin-bottom:20px; */

  /* background-color:#fbfbfb; */

  /* -webkit-border-radius:4px; */

  /* -moz-border-radius:4px; */

  /* border-radius:4px; */

  /* -webkit-box-shadow:inset 0 1px 1px rgba(0, 0, 0, 0.05); */

  /* -moz-box-shadow:inset 0 1px 1px rgba(0, 0, 0, 0.05); */

  /* box-shadow:inset 0 1px 1px rgba(0, 0, 0, 0.05) */
}

.tree li {
  list-style-type: none;
  margin: 0;
  padding: 10px 0 0 0;
  position: relative;
}

.tree li::before,
.tree li::after {
  content: "";
  left: -20px;
  position: absolute;
  right: auto;
}

.tree li::before {
  border-left: 1px solid #999;
  bottom: 50px;
  height: 100%;
  top: 0;
  width: 1px;
}

.tree li::after {
  border-top: 1px solid #999;
  height: 20px;
  top: 30px;
  width: 20px;
}

.tree li span {
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border: 1px solid #999;
  border-radius: 0;
  display: inline-block;
  padding: 3px 18px;
  text-decoration: none;
}

.tree li.parent_li > span {
  cursor: pointer;
}

.tree > ul > li::before,
.tree > ul > li::after {
  border: 0;
}

.tree li:last-child::before {
  height: 30px;
}

.tree li.parent_li > span:hover,
.tree li.parent_li > span:hover + ul li span {
  background: #eee;
  border: 1px solid #94a0b4;
  color: #000;
}

/* */
.plain-txt table {
  hyphens: auto;
}

.plain-txt table.projektdaten th,
.plain-txt table.projektdaten td,
.plain-txt table.projektdaten tr {
  text-align: left;
  font-size: 1.0625rem;
  line-height: 1.58824;
  font-weight: 400;
  background-color: transparent;
  border: 0;
}

.plain-txt #parent-fieldname-text p,
.plain-txt #parent-fieldname-text h3,
.plain-txt #parent-fieldname-text h4 {
  margin-bottom: 10px;
}

.plain-txt #parent-fieldname-text > * + h3,
.plain-txt #parent-fieldname-text > * + h4 {
  margin-top: 30px;
}

h2.accordion-spacer {
  margin-top: 70px;
}

.accordion {
  margin-bottom: 30px !important;

  .listing-cell {
    h3 {
      font-size: 1.47em;
    }

    h4 {
      font-size: 1.3em;
    }

    h3,
    h4,
    ul,
    p {
      margin-bottom: 10px;
    }

    .listing-cell-body > * + h3,
    .listing-cell-body > * + h4 {
      margin-top: 30px;
    }

    .listing-cell-body {
      -moz-transition: display 0.5s ease-out;
      -webkit-transition: display 0.5s ease-out;
      -o-transition: display 0.5s ease-out;
      transition: display 0.5s ease-out;
      display: none;
      padding-bottom: 50px;
    }

    &.active .listing-cell-body {
      -moz-transition: display 0.5s ease-in;
      -webkit-transition: display 0.5s ease-in;
      -o-transition: display 0.5s ease-in;
      transition: display 0.5s ease-in;
      display: block;
      padding-bottom: 50px;
    }

    .listing-cell-head {
      position: relative;

      button {
        &:focus {
          outline: none;
        }

        .cell-content {
          font-weight: 400;
          font-size: 20px;
          padding-right: 40px;
        }
      }

      .cell-icon {
        position: absolute;
        top: 22px;
        right: 0;
        width: 24px;
        height: 24px;
        display: block;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cg stroke='currentColor' stroke-width='2' fill='none' fill-rule='evenodd'%3E%3Cpath d='M12 1C5.925 1 1 5.925 1 12s4.925 11 11 11 11-4.925 11-11S18.075 1 12 1z'/%3E%3Cpath d='M17.657 9.343L12 15 6.343 9.343'/%3E%3C/g%3E%3C/svg%3E");
      }
    }

    &.active .cell-icon {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }
}

.documentActions {
  margin: 0;
  padding: 30px 0 0 0;
}

.documentByLine {
  margin-top: 50px !important;

  .documentAuthor a {
    color: #333;
    text-decoration: none;
    background-image: linear-gradient(currentColor, currentColor);
    background-size: 1px 1px;
    background-position: 0 bottom;
    background-repeat: repeat-x;
    background-clip: border-box;
    text-decoration: none;
  }
}

.header-generic-index {
  .banner_container.banner-scale {
    .banner-wrapper {
      position: relative;
      @media (min-width: 52em) {
        margin-left: -40px;
        margin-right: -40px;
      }
    }
  }

  #portal-slider {
    @media (min-width: 52em) {
      .gigantic {
        margin-left: -40px;
        margin-right: -40px;
      }
      &.bleed {
        .gigantic {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }

  .inner {
    h1.sans-h3 {
      font-weight: 500;
      line-height: 1.5;
    }
  }

  .contain-shape {
    position: relative;
    height: 100%;
    padding-bottom: 0 !important;
  }

  .hold-shape {
    position: relative;
    height: 100%;
  }

  .shape-outer {
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 50%;
    margin-left: 1.2%;
  }

  .show-content-title {
    margin-bottom: 0;

    .contentTitle {
      display: flex;
      position: relative;
      align-items: center;
      padding-top: 50px;
      padding-left: 20px;
      padding-right: 20px;

      @media (min-width: 36em) {
        padding-left: calc(15.625vw + -3.75rem);
        padding-right: calc(15.625vw + -3.75rem);
      }

      @media (min-width: 52em) {
        position: absolute;
        top: 20%;
        bottom: 10%;
        height: 80%;
        padding-top: 0;
        padding-left: 16%;
        padding-right: 0;
        width: 38%;
      }
    }
  }
}

.portal-banner {
  position: relative;
}

.bannerImage {
  margin: 0;

  img {
    width: 100%;
  }
}

.show-content-title {
  .bannerContent {
    position: relative;

    .contain-shape {
      display: none;
    }

    @media (min-width: 52em) {
      position: absolute;
      .contain-shape {
        display: block;
      }
    }
  }
}

.bannerContent {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  @media screen and (min-width: 52em) {
    position: absolute;
  }

  &.frame {
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 30px;

    @media screen and (min-width: 36em) {
      padding-left: calc(15.625vw + -3.75rem);
      padding-right: calc(15.625vw + -3.75rem);
    }

    @media screen and (min-width: 52em) {
      padding-left: 16%;
      padding-right: 15%;
      padding-top: 0;
      top: 10%;
    }
  }

  .bannerTitle {
    font-size: 30px;
  }

  .bannerText,
  .contentTitle {
    color: #333;
  }

  a {
    color: #333;
  }

  .col-1-3 {
    width: 100%;
    margin-left: unset;
    margin-right: auto;
  }

  .col-1-2 {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .col-2-3 {
    width: 100%;
    margin-left: auto;
    margin-right: unset;
  }

  //@media screen and (min-width: 36em) {
  //  .bannerTitle {
  //    font-size: 18px;
  //  }
  //
  //  .col-1-3,
  //  .col-1-2,
  //  .col-2-3 {
  //    //width: 200px;
  //    width: 34%;
  //  }
  //}

  @media screen and (min-width: 52em) {
    .bannerTitle {
      font-size: 22px;
    }

    .col-1-3,
    .col-1-2,
    .col-2-3 {
      //width: 250px;
      width: 34%;
    }
  }

  @media screen and (min-width: 72em) {
    .bannerTitle {
      font-size: 26px;
    }

    .col-1-3,
    .col-1-2,
    .col-2-3 {
      //width: 300px;
      width: 34%;
    }
  }

  @media screen and (min-width: 104em) {
    .bannerTitle {
      font-size: 30px;
    }

    .col-1-3,
    .col-1-2,
    .col-2-3 {
      //width: 400px;
      width: 34%;
    }
  }
}

.portaltype-frontpage {
  .bannerContent {
    position: relative;

    @media screen and (min-width: 52em) {
      position: absolute;
    }

    &.frame {
      padding-left: 20px;
      padding-right: 20px;

      @media screen and (min-width: 36em) {
        padding-left: calc(15.625vw + -3.75rem);
        padding-right: calc(15.625vw + -3.75rem);
      }

      @media screen and (min-width: 52em) {
        padding-left: calc(15.625vw + -3.75rem);
        padding-right: calc(15.625vw + -3.75rem);
      }

      @media screen and (min-width: 72em) {
        padding-left: calc(23.4375vw + -8.75rem);
        padding-right: calc(23.4375vw + -8.75rem);
      }

      @media screen and (min-width: 104em) {
        padding-left: 250px;
        padding-right: 250px;
      }

      .bannerTitle {
        font-size: 22px;

        @media screen and (min-width: 36em) {
          font-size: 26px;
        }

        @media screen and (min-width: 52em) {
          font-size: 30px;
        }

        @media screen and (min-width: 72em) {
          font-size: 34px;
        }

        @media screen and (min-width: 104em) {
          font-size: 38px;
        }
      }
    }
  }
}

.bannerEdit {
  display: none;
  position: absolute;
  right: 10px;
  top: 10px;
  background: white;
  padding: 10px;
  border: 1px solid silver;
  border-radius: 5px;

  @media screen and (min-width: 36em) {
    display: block;
  }

  a {
    text-decoration: none;
    color: #111;
  }
}

.shape-outer {
  .shape {
    max-height: 100%;
  }

  .fg-theme {
    color: #afc8b9;
    opacity: 0.9;
  }

  .fg-red {
    color: #e2a897;
    opacity: 0.9;
  }

  .fg-green {
    color: #a2beaf;
    opacity: 0.9;
  }

  .fg-blue {
    color: #97ace2;
    opacity: 0.9;
  }
}

//
// Carousel
// --------------------------------------------------
.flickity-play-button-container {
  margin: 0 16px !important;

  &:focus {
    outline: none;
  }
}

.carousel {
  position: relative;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner > .carousel-item {
  position: relative;
  transition: transform 1.5s ease-in-out;
}

.carousel-control {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 15%;
  font-size: 20px;
  color: #fff;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  background-color: rgba(0, 0, 0, 0);
  filter: alpha(opacity=50);
  opacity: 0.5;
}

@media screen and (max-width: 768px) {
  .carousel .carousel-inner {
    margin-bottom: 50px;
  }

  [class^="stack-"] .push-ml {
    margin-top: 10px;
  }
}

.carousel-control:hover,
.carousel-control:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  filter: alpha(opacity=90);
  opacity: 0.9;
}

.carousel-control .icon-prev,
.carousel-control .icon-next,
.carousel-control .glyphicon-chevron-left,
.carousel-control .glyphicon-chevron-right {
  position: absolute;
  top: 50%;
  z-index: 5;
  display: inline-block;
  margin-top: -10px;
}

.carousel-control .icon-prev,
.carousel-control .glyphicon-chevron-left {
  left: 50%;
  margin-left: -10px;
}

.carousel-control .icon-next,
.carousel-control .glyphicon-chevron-right {
  right: 50%;
  margin-right: -10px;
}

.carousel-control .icon-prev,
.carousel-control .icon-next {
  width: 20px;
  height: 20px;
  font-family: serif;
  line-height: 1;
}

.carousel-control .icon-prev::before {
  content: "\2039";
}

.carousel-control .icon-next::before {
  content: "\203a";
}

.thumbnail-indicator li img {
  height: 100%;
}

.carousel-indicators {
  position: absolute;
  bottom: -40px;
  left: 50%;
  z-index: 15;
  width: 60%;
  padding-left: 0;
  margin-left: -30%;
  text-align: center;
  list-style: none;

  @media screen and (min-width: 52em) {
    bottom: 10px;
  }
}

.carousel-indicators li {
  display: inline-block;
  width: 10px !important;
  height: 10px !important;
  margin: 3px 5px 0 !important;
  text-indent: -999px;
  cursor: pointer;
  border-radius: 50% !important;
  background-color: #333 !important;
  opacity: 0.25 !important;
  border-top: none !important;
  border-bottom: none !important;
}

.carousel-indicators .active {
  background-color: #333 !important;
  opacity: 1 !important;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
}

.carousel-caption .btn {
  text-shadow: none;
}

@media screen and (min-width: 768px) {
  .carousel-control .glyphicon-chevron-left,
  .carousel-control .glyphicon-chevron-right,
  .carousel-control .icon-prev,
  .carousel-control .icon-next {
    width: 30px;
    height: 30px;
    margin-top: -10px;
    font-size: 30px;
  }

  .carousel-control .glyphicon-chevron-left,
  .carousel-control .icon-prev {
    margin-left: -10px;
  }

  .carousel-control .glyphicon-chevron-right,
  .carousel-control .icon-next {
    margin-right: -10px;
  }

  .carousel-caption {
    right: 20%;
    left: 20%;
    padding-bottom: 30px;
  }

  .carousel-indicators {
    bottom: -40px;

    @media screen and (min-width: 52em) {
      bottom: 10px;
    }
  }
}

.plain-txt {
  .listing-h2 {
    font-size: 1.375rem;
    line-height: 1.36364;
  }
}

.btn-link {
  text-transform: uppercase;
  text-decoration: none;
  color: black;

  &:hover {
    color: black;
    border-bottom: 2px solid black;
  }
}

.full-text-link {
  text-decoration: none;
  color: black;

  &:hover {
    color: black;
  }
}

.form {
  .alert {
    margin-bottom: 20px !important;
  }
}

.form-label,
.form-check-label {
  .required {
    display: inline-flex;
  }
}

.easyform-thankspage {
  .discreet {
    margin: 0 0 20px;
  }

  .field {
    margin: 0 0 10px;

    .horizontal {
      color: gray;
    }
  }
}

.invalid-feedback {
  display: block;
}

.field input,
.field textarea,
.field select,
[class*=" field-"] input,
[class*=" field-"] textarea,
[class*=" field-"] select,
[class^="field-"] input,
[class^="field-"] textarea,
[class^="field-"] select {
  border: 3px solid #999;

  &:focus {
    box-shadow: none;
  }
}

.field select,
[class*=" field-"] select,
[class^="field-"] select {
  background-image: url("./images/dropdown.svg") !important;
  background-color: rgba(255, 255, 255, 0.786) !important;
  background-blend-mode: overlay;
  background-size: 15px !important;
  background-repeat: no-repeat !important;
  background-position: 96% 50% !important;
}

.field select[multiple],
[class^="field-"] select[multiple],
[class*=" field-"] select[multiple] {
  background-image: none !important;
  height: auto;
}

.field {
  [type="checkbox"],
  [type="radio"] {
    width: 18px;
    height: 18px;
    padding: 0;

    &:focus {
      box-shadow: none;
    }
  }
}

.field select,
[class*=" field-"] select,
[class^="field-"] select {
  height: 50px;
}

.field input,
[class^="field-"] input,
[class*=" field-"] input {
  height: 50px;
}

#pubdatenbank {
  margin-top: 15px;

  & > .field {
    margin-bottom: 30px;
  }

  // Hack away the double h1!!!??
  h1 {
    display: none;
  }

  .abstand {
    margin: 20px 0;
  }

  .displaynone {
    display: none;
  }

  .start-suche {
    display: block;
    width: 18px;
    height: 18px;
    background-image: none;
    margin-top: 20px;
  }

  dl.trefferausgabe {
    margin-bottom: 30px !important;
  }
}

/* Hack to hide the bad search template html */
.template-search {
  .plain-txt,
  #content-core {
    margin-top: 0;
  }

  .plain-txt {
    #sorting-options {
      margin-bottom: 15px;

      a {
        background-image: unset;
      }
    }
  }

  #primary-area > #search-term {
    display: none;
  }
}

nav.phablet-layer .menu-foot {
  margin-top: 0;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.plain-txt .pagebrowser a {
  background: none;
}

body.template-summary_view #content-core article {
  .tileImage {
    margin-top: 7px;
  }

  @media screen and (max-width: 36em) {
    flex-direction: column;
  }

  .tileImage {
    margin-bottom: 1rem;
  }
}

// Hide bbf - see #12531
body.subsection-arbeitsbereiche-servicebereiche,
body.subsection-units {
  #faceted-results {
    article.BBF {
      display: none !important;
    }
  }
}

ul.callout,
div.callout,
p.callout {
  background: #eee;
  padding: 1em;
  border-left: 1em solid #ccc;
  clear: both;
  display: table;
}

.discreet {
  color: #777;
  font-weight: 300;
}

div.box {
  background: #dfe7f4;
  padding: 1em;
  border-left: 0.4em dotted #6ca4d4;
  clear: both;

  ul > li,
  ol > li {
    position: relative;
    padding-left: 2.5em;
  }

  ul > li::before {
    content: counter(ul-li, disc);
    position: absolute;
    top: 0;
    right: calc(100% - 1.75em);
  }

  ul.accordion > li {
    padding-left: 0;
  }

  ul.accordion > li::before {
    content: none;
  }

  ul > li > ul > li::before {
    position: absolute;
    content: counter(ul-li, disc);
    right: calc(100% - 1em);
  }

  ul > li > ul > li,
  ol > li > ol > li {
    padding-left: 1.5em;
  }
}

ul,
ol {
  padding-left: unset !important;
}

.plain-txt p.linkbutton {
  margin: 2em 0 !important;
}

p.linkbutton a {
  background-color: #b9b9b9;
  padding: 1rem;
  border: 0.1rem solid grey;
  border-radius: 0.375rem;
  font-size: 16px;
  font-weight: 700;
  background-image: none;
  letter-spacing: 0.75px;
  line-height: 20px;
}

p.linkbutton a:hover {
  background-color: #cccccc;
}

p.quote {
  padding: 10px 10px 10px 20%;
  font-size: 1.5rem !important;
  color: rgb(92, 141, 193);
}

p.quote::before {
  content: "»";
}

p.quote::after {
  content: "«";
}

// Load default bootstrap at the end
@import "./bootstrap.scss";
