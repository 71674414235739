// Bootstrap was added and overrides some leibniz css
// This is to fix overrides. Maybe we find a way to get rid of bootstrap

.breadcrumb {
  margin-bottom: unset;
}

.h1,
h1 {
  font-size: 1.875rem;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.33;
}

.h2,
h2,
.plain-txt h2 {
  font-size: 1.5rem;
  line-height: 32px;
}

h2.bannerTitle {
  line-height: 1.33;
}

.h3,
h3,
.module .plain-txt h3,
#primary-area .plain-txt h3 {
  font-size: 1.25rem;
  line-height: 28px;
}

.h4,
h4,
.module .plain-txt h4,
#primary-area .plain-txt h4 {
  font-size: 1.063rem;
  line-height: 1.58824;
  font-weight: 600;
}

.documentDescription,
.lead {
  line-height: unset;
  font-weight: 400;
  font-size: 21px;
  margin-bottom: unset;
}

.description {
  font-size: 1.0625rem;
}

.form-check-input:checked {
  background-color: #565e64;
  border-color: #565e64;
}

.bg-primary {
  background-color: #565e64 !important;
}

.event-summary .meta-label {
  display: inline;
}

.image-left {
  &.picture-variant-large,
  &.picture-variant-medium,
  &.picture-variant-small {
    margin-right: 1em;
  }
}
