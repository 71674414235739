/* From here https://google-webfonts-helper.herokuapp.cm/fonts/signika?subsets=latin */

/* signika-regular - latin */
@font-face {
  font-family: "Signika";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/signika-v12-latin-regular.eot"); /* IE9 Compat Modes */
  src:
    local(""),
    url("./fonts/signika-v12-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/signika-v12-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./fonts/signika-v12-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */ url("./fonts/signika-v12-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./fonts/signika-v12-latin-regular.svg#Signika") format("svg"); /* Legacy iOS */
}

/* signika-500 - latin */
@font-face {
  font-family: "Signika";
  font-style: normal;
  font-weight: 500;
  src: url("./fonts/signika-v12-latin-500.eot"); /* IE9 Compat Modes */
  src:
    local(""),
    url("./fonts/signika-v12-latin-500.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/signika-v12-latin-500.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./fonts/signika-v12-latin-500.woff")
      format("woff"),
    /* Modern Browsers */ url("./fonts/signika-v12-latin-500.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/signika-v12-latin-500.svg#Signika")
      format("svg"); /* Legacy iOS */
}

/* signika-700 - latin */
@font-face {
  font-family: "Signika";
  font-style: normal;
  font-weight: 700;
  src: url("./fonts/signika-v12-latin-700.eot"); /* IE9 Compat Modes */
  src:
    local(""),
    url("./fonts/signika-v12-latin-700.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/signika-v12-latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./fonts/signika-v12-latin-700.woff")
      format("woff"),
    /* Modern Browsers */ url("./fonts/signika-v12-latin-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/signika-v12-latin-700.svg#Signika")
      format("svg"); /* Legacy iOS */
}
