.field-select {
  display: flex !important;
  margin-bottom: 30px;

  input.dipf-search {
    border: none !important;
    border-bottom: 3px solid !important;
    background: transparent !important;
    font-size: 22px;
    font-weight: 700;
    height: 35px;
  }
}
.sort-by-label {
  padding-left: 3%;
}

.tx-indexedsearch-search-submit {
  position: absolute;
  right: 0;
}

.dipf-search-option-wrapper {
  display: flex;

  @media (max-width: 34.999em) {
    flex-direction: column !important;
  }

  #search-filter {
    text-align: center;
    .field-select select {
      border: unset !important;
      border-bottom: 1px solid;
      background: transparent !important;
      padding: 0 !important;
      height: 24px;
    }
  }

  .option-item {
    @media (max-width: 34.999em) {
      margin-bottom: 10px;
    }
    flex: 1 0 auto !important;
    text-align: center;
  }
}
