*,
*:before,
*:after {
  word-break: inherit;
  word-wrap: inherit;
  overflow-wrap: inherit;
  -webkit-hyphens: inherit;
  -ms-hyphens: inherit;
  hyphens: inherit;
}

:root {
  word-break: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

body {
  .maxaspect {
    position: relative;
  }

  .maxaspect > * {
    width: 100%;
  }

  .maxaspect:before {
    content: "";
    display: block;
    width: 0.01px;
    height: 0;
    padding-bottom: calc(1 / var(--aspect, 1) * 100%);
  }

  .ellipsis {
    overflow: hidden;
  }

  .ellipsis::before {
    content: "";
    float: left;
    width: 1px;
    height: 100%;
  }

  .ellipsis > *:first-child {
    float: right;
    width: 100%;
    margin-left: -1px;
  }

  .ellipsis::after {
    font-weight: bold;
    content: "...";
    box-sizing: content-box;
    float: right;
    position: relative;
    top: 0;
    left: 100%;
    width: 100%;
    height: 3em;
    margin-left: -100%;
    padding-right: 1px;
    text-align: right;
    background-size: 100% 100%;
    transform: translateY(-100%);
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .bg-scirocco .ellipsis::after {
    background: linear-gradient(
      to bottom,
      rgba(106, 122, 113, 0),
      #4d7396 80%,
      #4d7396
    );
  }

  .theme-scirocco .bg-theme .ellipsis::after {
    background: linear-gradient(
      to bottom,
      rgba(106, 122, 113, 0),
      #4d7396 80%,
      #4d7396
    );
  }

  .bg-haze .ellipsis::after {
    background: linear-gradient(
      to bottom,
      rgba(247, 248, 246, 0),
      #f7f8f6 80%,
      #f7f8f6
    );
  }

  .theme-haze .bg-theme .ellipsis::after {
    background: linear-gradient(
      to bottom,
      rgba(247, 248, 246, 0),
      #f7f8f6 80%,
      #f7f8f6
    );
  }

  .bg-twilight .ellipsis::after {
    background: linear-gradient(
      to bottom,
      rgba(245, 245, 235, 0),
      #f5f5eb 80%,
      #f5f5eb
    );
  }

  .theme-twilight .bg-theme .ellipsis::after {
    background: linear-gradient(
      to bottom,
      rgba(245, 245, 235, 0),
      #f5f5eb 80%,
      #f5f5eb
    );
  }

  .bg-satin .ellipsis::after {
    background: linear-gradient(
      to bottom,
      rgba(230, 230, 215, 0),
      #e6e6d7 80%,
      #e6e6d7
    );
  }

  .theme-satin .bg-theme .ellipsis::after {
    background: linear-gradient(
      to bottom,
      rgba(230, 230, 215, 0),
      #e6e6d7 80%,
      #e6e6d7
    );
  }

  .bg-gumleaf .ellipsis::after {
    background: linear-gradient(
      to bottom,
      rgba(175, 200, 185, 0),
      #afc8b9 80%,
      #afc8b9
    );
  }

  .theme-gumleaf .bg-theme .ellipsis::after {
    background: linear-gradient(
      to bottom,
      rgba(175, 200, 185, 0),
      #afc8b9 80%,
      #afc8b9
    );
  }

  .bg-limette .ellipsis::after {
    background: linear-gradient(
      to bottom,
      rgba(210, 220, 60, 0),
      #d2dc3c 80%,
      #d2dc3c
    );
  }

  .theme-limette .bg-theme .ellipsis::after {
    background: linear-gradient(
      to bottom,
      rgba(210, 220, 60, 0),
      #d2dc3c 80%,
      #d2dc3c
    );
  }

  .bg-purple .ellipsis::after {
    background: linear-gradient(
      to bottom,
      rgba(145, 140, 195, 0),
      #918cc3 80%,
      #918cc3
    );
  }

  .theme-purple .bg-theme .ellipsis::after {
    background: linear-gradient(
      to bottom,
      rgba(145, 140, 195, 0),
      #918cc3 80%,
      #918cc3
    );
  }

  .bg-sage .ellipsis::after {
    background: linear-gradient(
      to bottom,
      rgba(155, 165, 120, 0),
      #9ba578 80%,
      #9ba578
    );
  }

  .theme-sage .bg-theme .ellipsis::after {
    background: linear-gradient(
      to bottom,
      rgba(155, 165, 120, 0),
      #9ba578 80%,
      #9ba578
    );
  }

  .bg-rust .ellipsis::after {
    background: linear-gradient(
      to bottom,
      rgba(215, 115, 60, 0),
      #d7733c 80%,
      #d7733c
    );
  }

  .theme-rust .bg-theme .ellipsis::after {
    background: linear-gradient(
      to bottom,
      rgba(215, 115, 60, 0),
      #d7733c 80%,
      #d7733c
    );
  }

  .bg-lite .ellipsis::after {
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      #fff 80%,
      #fff
    );
  }

  .theme-lite .bg-theme .ellipsis::after {
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      #fff 80%,
      #fff
    );
  }

  .bg-dark .ellipsis::after {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000 80%, #000);
  }

  .theme-dark .bg-theme .ellipsis::after {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000 80%, #000);
  }

  .case-upper {
    text-transform: uppercase;
  }

  .case-lower {
    text-transform: lowercase;
  }

  .case-title {
    text-transform: capitalize;
  }

  img,
  svg {
    display: block;
    max-width: 100%;
    height: auto;
  }

  video,
  object,
  iframe {
    display: block;
    max-width: 100%;
    object-fit: fill;
  }

  img[sizes] {
    width: 100%;
  }

  video {
    max-width: 100%;
    height: auto;
  }

  .hold,
  [class^="hold-"],
  [class*=" hold-"] {
    position: relative;
  }

  .hold-svg svg {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .icon > svg,
  [class^="icon-"] > svg,
  [class*=" icon-"] > svg {
    overflow: visible;
    flex: 0 0 auto;
    -ms-grid-row-align: center;
    align-self: center;
    height: auto;
    transform: inherit;
  }

  span.icon,
  span[class^="icon-"],
  span[class*=" icon-"] {
    display: inline-block;
    vertical-align: middle;
    height: 1em;
    width: 1em;
  }

  span.icon > svg,
  span[class^="icon-"] > svg,
  span[class*=" icon-"] > svg {
    height: 1em;
    width: 100%;
    position: relative;
  }

  div.icon,
  span.icon.d-block,
  div[class^="icon-"],
  span.d-block[class^="icon-"],
  div[class*=" icon-"],
  span.d-block[class*=" icon-"] {
    width: 100%;
    position: relative;
  }

  div.icon::before,
  span.icon.d-block::before,
  div[class^="icon-"]::before,
  span.d-block[class^="icon-"]::before,
  div[class*=" icon-"]::before,
  span.d-block[class*=" icon-"]::before {
    content: "";
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
  }

  div.icon > svg,
  span.icon.d-block > svg,
  div[class^="icon-"] > svg,
  span.d-block[class^="icon-"] > svg,
  div[class*=" icon-"] > svg,
  span.d-block[class*=" icon-"] > svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  span.icon-s {
    width: 24px !important;
  }

  span.icon-s > svg {
    height: 24px !important;
    top: calc((1em - 24px) / 2);
  }

  div.icon-s,
  span.icon-s.d-block {
    width: 24px !important;
    height: 24px !important;
  }

  span.icon-s2 {
    width: 30px !important;
  }

  span.icon-s2 > svg {
    height: 30px !important;
    top: calc((1em - 30px) / 2);
  }

  div.icon-s2,
  span.icon-s2.d-block {
    width: 30px !important;
    height: 30px !important;
  }

  span.icon-m {
    width: 40px !important;
  }

  span.icon-m > svg {
    height: 40px !important;
    top: calc((1em - 40px) / 2);
  }

  div.icon-m,
  span.icon-m.d-block {
    width: 40px !important;
    height: 40px !important;
  }

  span.icon-l {
    width: 80px !important;
  }

  span.icon-l > svg {
    height: 80px !important;
    top: calc((1em - 80px) / 2);
  }

  div.icon-l,
  span.icon-l.d-block {
    width: 80px !important;
    height: 80px !important;
  }

  a:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none;
    font-weight: normal;
  }

  a:not([href]):not([tabindex]):focus,
  a:not([href]):not([tabindex]):hover,
  a:not([href]):not([tabindex]):active {
    color: inherit;
    text-decoration: none;
  }

  a.link,
  a[class^="link-"],
  a[class*=" link-"],
  span.link,
  span[class^="link-"],
  span[class*=" link-"],
  button.link,
  button[class^="link-"],
  button[class*=" link-"] {
    display: inline;
    vertical-align: baseline;
    border: none;
    cursor: pointer;
    color: inherit;
    text-decoration: none;
  }

  a.link:not(.link-outer):not(.link-btn),
  a.link .link-inner,
  a[class^="link-"] .link-inner,
  a[class*=" link-"] .link-inner,
  span.link:not(.link-outer):not(.link-btn),
  span.link .link-inner,
  span[class^="link-"]:not(.link-outer):not(.link-btn),
  span[class^="link-"] .link-inner,
  span[class*=" link-"]:not(.link-outer):not(.link-btn),
  span[class*=" link-"] .link-inner,
  button.link:not(.link-outer):not(.link-btn),
  button.link .link-inner,
  button[class^="link-"]:not(.link-outer):not(.link-btn),
  button[class^="link-"] .link-inner,
  button[class*=" link-"]:not(.link-outer):not(.link-btn),
  button[class*=" link-"] .link-inner {
    font-weight: bold;
  }

  a.link:not(.link-outer):not(.link-btn):focus,
  a.link:not(.link-outer):not(.link-btn):hover,
  a.link:not(.link-outer):not(.link-btn):active,
  a[class^="link-"]:not(.link-outer):not(.link-btn):focus,
  a[class^="link-"]:not(.link-outer):not(.link-btn):hover,
  a[class^="link-"]:not(.link-outer):not(.link-btn):active,
  a[class*=" link-"]:not(.link-outer):not(.link-btn):focus,
  a[class*=" link-"]:not(.link-outer):not(.link-btn):hover,
  a[class*=" link-"]:not(.link-outer):not(.link-btn):active,
  span.link:not(.link-outer):not(.link-btn):focus,
  span.link:not(.link-outer):not(.link-btn):hover,
  span.link:not(.link-outer):not(.link-btn):active,
  span[class^="link-"]:not(.link-outer):not(.link-btn):focus,
  span[class^="link-"]:not(.link-outer):not(.link-btn):hover,
  span[class^="link-"]:not(.link-outer):not(.link-btn):active,
  span[class*=" link-"]:not(.link-outer):not(.link-btn):focus,
  span[class*=" link-"]:not(.link-outer):not(.link-btn):hover,
  span[class*=" link-"]:not(.link-outer):not(.link-btn):active,
  button.link:not(.link-outer):not(.link-btn):focus,
  button.link:not(.link-outer):not(.link-btn):hover,
  button.link:not(.link-outer):not(.link-btn):active,
  button[class^="link-"]:not(.link-outer):not(.link-btn):focus,
  button[class^="link-"]:not(.link-outer):not(.link-btn):hover,
  button[class^="link-"]:not(.link-outer):not(.link-btn):active,
  button[class*=" link-"]:not(.link-outer):not(.link-btn):focus,
  button[class*=" link-"]:not(.link-outer):not(.link-btn):hover,
  button[class*=" link-"]:not(.link-outer):not(.link-btn):active {
    background-image: linear-gradient(currentColor, currentColor);
    background-size: 1px 1px;
    background-position: 0 bottom;
    background-repeat: repeat-x;
    background-clip: border-box;
    text-decoration: none;
  }

  a.link:not(.link-outer):not(.link-btn).focus-visible,
  a[class^="link-"]:not(.link-outer):not(.link-btn).focus-visible,
  a[class*=" link-"]:not(.link-outer):not(.link-btn).focus-visible,
  span.link:not(.link-outer):not(.link-btn).focus-visible,
  span[class^="link-"]:not(.link-outer):not(.link-btn).focus-visible,
  span[class*=" link-"]:not(.link-outer):not(.link-btn).focus-visible,
  button.link:not(.link-outer):not(.link-btn).focus-visible,
  button[class^="link-"]:not(.link-outer):not(.link-btn).focus-visible,
  button[class*=" link-"]:not(.link-outer):not(.link-btn).focus-visible {
    box-shadow: 0 0 0 3px #cdcdcd;
  }

  a.link.link-outer,
  a.link.link-btn,
  a[class^="link-"].link-outer,
  a[class^="link-"].link-btn,
  a[class*=" link-"].link-outer,
  a[class*=" link-"].link-btn,
  span.link.link-outer,
  span.link.link-btn,
  span[class^="link-"].link-outer,
  span[class^="link-"].link-btn,
  span[class*=" link-"].link-outer,
  span[class*=" link-"].link-btn,
  button.link.link-outer,
  button.link.link-btn,
  button[class^="link-"].link-outer,
  button[class^="link-"].link-btn,
  button[class*=" link-"].link-outer,
  button[class*=" link-"].link-btn {
    display: flex;
    font-weight: inherit;
  }

  a.link.link-outer:hover .link-inner,
  a.link.link-outer:hover .link-hover,
  a.link.link-outer:active .link-inner,
  a.link.link-outer:active .link-hover,
  a.link.link-outer:focus .link-inner,
  a.link.link-outer:focus .link-hover,
  a.link.link-btn:hover .link-inner,
  a.link.link-btn:hover .link-hover,
  a.link.link-btn:active .link-inner,
  a.link.link-btn:active .link-hover,
  a.link.link-btn:focus .link-inner,
  a.link.link-btn:focus .link-hover,
  a[class^="link-"].link-outer:hover .link-inner,
  a[class^="link-"].link-outer:hover .link-hover,
  a[class^="link-"].link-outer:active .link-inner,
  a[class^="link-"].link-outer:active .link-hover,
  a[class^="link-"].link-outer:focus .link-inner,
  a[class^="link-"].link-outer:focus .link-hover,
  a[class^="link-"].link-btn:hover .link-inner,
  a[class^="link-"].link-btn:hover .link-hover,
  a[class^="link-"].link-btn:active .link-inner,
  a[class^="link-"].link-btn:active .link-hover,
  a[class^="link-"].link-btn:focus .link-inner,
  a[class^="link-"].link-btn:focus .link-hover,
  a[class*=" link-"].link-outer:hover .link-inner,
  a[class*=" link-"].link-outer:hover .link-hover,
  a[class*=" link-"].link-outer:active .link-inner,
  a[class*=" link-"].link-outer:active .link-hover,
  a[class*=" link-"].link-outer:focus .link-inner,
  a[class*=" link-"].link-outer:focus .link-hover,
  a[class*=" link-"].link-btn:hover .link-inner,
  a[class*=" link-"].link-btn:hover .link-hover,
  a[class*=" link-"].link-btn:active .link-inner,
  a[class*=" link-"].link-btn:active .link-hover,
  a[class*=" link-"].link-btn:focus .link-inner,
  a[class*=" link-"].link-btn:focus .link-hover,
  span.link.link-outer:hover .link-inner,
  span.link.link-outer:hover .link-hover,
  span.link.link-outer:active .link-inner,
  span.link.link-outer:active .link-hover,
  span.link.link-outer:focus .link-inner,
  span.link.link-outer:focus .link-hover,
  span.link.link-btn:hover .link-inner,
  span.link.link-btn:hover .link-hover,
  span.link.link-btn:active .link-inner,
  span.link.link-btn:active .link-hover,
  span.link.link-btn:focus .link-inner,
  span.link.link-btn:focus .link-hover,
  span[class^="link-"].link-outer:hover .link-inner,
  span[class^="link-"].link-outer:hover .link-hover,
  span[class^="link-"].link-outer:active .link-inner,
  span[class^="link-"].link-outer:active .link-hover,
  span[class^="link-"].link-outer:focus .link-inner,
  span[class^="link-"].link-outer:focus .link-hover,
  span[class^="link-"].link-btn:hover .link-inner,
  span[class^="link-"].link-btn:hover .link-hover,
  span[class^="link-"].link-btn:active .link-inner,
  span[class^="link-"].link-btn:active .link-hover,
  span[class^="link-"].link-btn:focus .link-inner,
  span[class^="link-"].link-btn:focus .link-hover,
  span[class*=" link-"].link-outer:hover .link-inner,
  span[class*=" link-"].link-outer:hover .link-hover,
  span[class*=" link-"].link-outer:active .link-inner,
  span[class*=" link-"].link-outer:active .link-hover,
  span[class*=" link-"].link-outer:focus .link-inner,
  span[class*=" link-"].link-outer:focus .link-hover,
  span[class*=" link-"].link-btn:hover .link-inner,
  span[class*=" link-"].link-btn:hover .link-hover,
  span[class*=" link-"].link-btn:active .link-inner,
  span[class*=" link-"].link-btn:active .link-hover,
  span[class*=" link-"].link-btn:focus .link-inner,
  span[class*=" link-"].link-btn:focus .link-hover,
  button.link.link-outer:hover .link-inner,
  button.link.link-outer:hover .link-hover,
  button.link.link-outer:active .link-inner,
  button.link.link-outer:active .link-hover,
  button.link.link-outer:focus .link-inner,
  button.link.link-outer:focus .link-hover,
  button.link.link-btn:hover .link-inner,
  button.link.link-btn:hover .link-hover,
  button.link.link-btn:active .link-inner,
  button.link.link-btn:active .link-hover,
  button.link.link-btn:focus .link-inner,
  button.link.link-btn:focus .link-hover,
  button[class^="link-"].link-outer:hover .link-inner,
  button[class^="link-"].link-outer:hover .link-hover,
  button[class^="link-"].link-outer:active .link-inner,
  button[class^="link-"].link-outer:active .link-hover,
  button[class^="link-"].link-outer:focus .link-inner,
  button[class^="link-"].link-outer:focus .link-hover,
  button[class^="link-"].link-btn:hover .link-inner,
  button[class^="link-"].link-btn:hover .link-hover,
  button[class^="link-"].link-btn:active .link-inner,
  button[class^="link-"].link-btn:active .link-hover,
  button[class^="link-"].link-btn:focus .link-inner,
  button[class^="link-"].link-btn:focus .link-hover,
  button[class*=" link-"].link-outer:hover .link-inner,
  button[class*=" link-"].link-outer:hover .link-hover,
  button[class*=" link-"].link-outer:active .link-inner,
  button[class*=" link-"].link-outer:active .link-hover,
  button[class*=" link-"].link-outer:focus .link-inner,
  button[class*=" link-"].link-outer:focus .link-hover,
  button[class*=" link-"].link-btn:hover .link-inner,
  button[class*=" link-"].link-btn:hover .link-hover,
  button[class*=" link-"].link-btn:active .link-inner,
  button[class*=" link-"].link-btn:active .link-hover,
  button[class*=" link-"].link-btn:focus .link-inner,
  button[class*=" link-"].link-btn:focus .link-hover {
    background-image: linear-gradient(currentColor, currentColor);
    background-size: 1px 1px;
    background-position: 0 bottom;
    background-repeat: repeat-x;
    background-clip: border-box;
    text-decoration: none;
  }

  a.link.link-outer.focus-visible .link-focus,
  a.link.link-outer.focus-visible .link-inner,
  a.link.link-btn.focus-visible .link-focus,
  a.link.link-btn.focus-visible .link-inner,
  a[class^="link-"].link-outer.focus-visible .link-focus,
  a[class^="link-"].link-outer.focus-visible .link-inner,
  a[class^="link-"].link-btn.focus-visible .link-focus,
  a[class^="link-"].link-btn.focus-visible .link-inner,
  a[class*=" link-"].link-outer.focus-visible .link-focus,
  a[class*=" link-"].link-outer.focus-visible .link-inner,
  a[class*=" link-"].link-btn.focus-visible .link-focus,
  a[class*=" link-"].link-btn.focus-visible .link-inner,
  span.link.link-outer.focus-visible .link-focus,
  span.link.link-outer.focus-visible .link-inner,
  span.link.link-btn.focus-visible .link-focus,
  span.link.link-btn.focus-visible .link-inner,
  span[class^="link-"].link-outer.focus-visible .link-focus,
  span[class^="link-"].link-outer.focus-visible .link-inner,
  span[class^="link-"].link-btn.focus-visible .link-focus,
  span[class^="link-"].link-btn.focus-visible .link-inner,
  span[class*=" link-"].link-outer.focus-visible .link-focus,
  span[class*=" link-"].link-outer.focus-visible .link-inner,
  span[class*=" link-"].link-btn.focus-visible .link-focus,
  span[class*=" link-"].link-btn.focus-visible .link-inner,
  button.link.link-outer.focus-visible .link-focus,
  button.link.link-outer.focus-visible .link-inner,
  button.link.link-btn.focus-visible .link-focus,
  button.link.link-btn.focus-visible .link-inner,
  button[class^="link-"].link-outer.focus-visible .link-focus,
  button[class^="link-"].link-outer.focus-visible .link-inner,
  button[class^="link-"].link-btn.focus-visible .link-focus,
  button[class^="link-"].link-btn.focus-visible .link-inner,
  button[class*=" link-"].link-outer.focus-visible .link-focus,
  button[class*=" link-"].link-outer.focus-visible .link-inner,
  button[class*=" link-"].link-btn.focus-visible .link-focus,
  button[class*=" link-"].link-btn.focus-visible .link-inner {
    box-shadow: 0 0 0 3px #cdcdcd;
  }

  a.link.link-btn,
  a.link[class^="link-btn-"],
  a.link[class*=" link-btn-"],
  a.link-btn[class^="link-"],
  a[class^="link-"][class^="link-btn-"],
  a[class^="link-"][class*=" link-btn-"],
  a.link-btn[class*=" link-"],
  a[class*=" link-"][class^="link-btn-"],
  a[class*=" link-"][class*=" link-btn-"],
  span.link.link-btn,
  span.link[class^="link-btn-"],
  span.link[class*=" link-btn-"],
  span.link-btn[class^="link-"],
  span[class^="link-"][class^="link-btn-"],
  span[class^="link-"][class*=" link-btn-"],
  span.link-btn[class*=" link-"],
  span[class*=" link-"][class^="link-btn-"],
  span[class*=" link-"][class*=" link-btn-"],
  button.link.link-btn,
  button.link[class^="link-btn-"],
  button.link[class*=" link-btn-"],
  button.link-btn[class^="link-"],
  button[class^="link-"][class^="link-btn-"],
  button[class^="link-"][class*=" link-btn-"],
  button.link-btn[class*=" link-"],
  button[class*=" link-"][class^="link-btn-"],
  button[class*=" link-"][class*=" link-btn-"] {
    display: inline-flex;
    align-items: center;
    vertical-align: baseline;
    letter-spacing: 0.75px;
    font-size: var(--font-size-sans-m2);
    text-transform: uppercase;
    text-decoration: none;
    font-weight: bold;
  }

  a.link.link-btn.icon-left,
  a.link[class^="link-btn-"].icon-left,
  a.link[class*=" link-btn-"].icon-left,
  a.link-btn[class^="link-"].icon-left,
  a[class^="link-"][class^="link-btn-"].icon-left,
  a[class^="link-"][class*=" link-btn-"].icon-left,
  a.link-btn[class*=" link-"].icon-left,
  a[class*=" link-"][class^="link-btn-"].icon-left,
  a[class*=" link-"][class*=" link-btn-"].icon-left,
  span.link.link-btn.icon-left,
  span.link[class^="link-btn-"].icon-left,
  span.link[class*=" link-btn-"].icon-left,
  span.link-btn[class^="link-"].icon-left,
  span[class^="link-"][class^="link-btn-"].icon-left,
  span[class^="link-"][class*=" link-btn-"].icon-left,
  span.link-btn[class*=" link-"].icon-left,
  span[class*=" link-"][class^="link-btn-"].icon-left,
  span[class*=" link-"][class*=" link-btn-"].icon-left,
  button.link.link-btn.icon-left,
  button.link[class^="link-btn-"].icon-left,
  button.link[class*=" link-btn-"].icon-left,
  button.link-btn[class^="link-"].icon-left,
  button[class^="link-"][class^="link-btn-"].icon-left,
  button[class^="link-"][class*=" link-btn-"].icon-left,
  button.link-btn[class*=" link-"].icon-left,
  button[class*=" link-"][class^="link-btn-"].icon-left,
  button[class*=" link-"][class*=" link-btn-"].icon-left {
    text-transform: uppercase;
  }

  a.link.link-btn.icon-left > .icon,
  a.link[class^="link-btn-"].icon-left > .icon,
  a.link[class*=" link-btn-"].icon-left > .icon,
  a.link-btn[class^="link-"].icon-left > .icon,
  a[class^="link-"][class^="link-btn-"].icon-left > .icon,
  a[class^="link-"][class*=" link-btn-"].icon-left > .icon,
  a.link-btn[class*=" link-"].icon-left > .icon,
  a[class*=" link-"][class^="link-btn-"].icon-left > .icon,
  a[class*=" link-"][class*=" link-btn-"].icon-left > .icon,
  span.link.link-btn.icon-left > .icon,
  span.link[class^="link-btn-"].icon-left > .icon,
  span.link[class*=" link-btn-"].icon-left > .icon,
  span.link-btn[class^="link-"].icon-left > .icon,
  span[class^="link-"][class^="link-btn-"].icon-left > .icon,
  span[class^="link-"][class*=" link-btn-"].icon-left > .icon,
  span.link-btn[class*=" link-"].icon-left > .icon,
  span[class*=" link-"][class^="link-btn-"].icon-left > .icon,
  span[class*=" link-"][class*=" link-btn-"].icon-left > .icon,
  button.link.link-btn.icon-left > .icon,
  button.link[class^="link-btn-"].icon-left > .icon,
  button.link[class*=" link-btn-"].icon-left > .icon,
  button.link-btn[class^="link-"].icon-left > .icon,
  button[class^="link-"][class^="link-btn-"].icon-left > .icon,
  button[class^="link-"][class*=" link-btn-"].icon-left > .icon,
  button.link-btn[class*=" link-"].icon-left > .icon,
  button[class*=" link-"][class^="link-btn-"].icon-left > .icon,
  button[class*=" link-"][class*=" link-btn-"].icon-left > .icon {
    margin-right: 10px;
  }

  a.link.link-btn.icon-right,
  a.link[class^="link-btn-"].icon-right,
  a.link[class*=" link-btn-"].icon-right,
  a.link-btn[class^="link-"].icon-right,
  a[class^="link-"][class^="link-btn-"].icon-right,
  a[class^="link-"][class*=" link-btn-"].icon-right,
  a.link-btn[class*=" link-"].icon-right,
  a[class*=" link-"][class^="link-btn-"].icon-right,
  a[class*=" link-"][class*=" link-btn-"].icon-right,
  span.link.link-btn.icon-right,
  span.link[class^="link-btn-"].icon-right,
  span.link[class*=" link-btn-"].icon-right,
  span.link-btn[class^="link-"].icon-right,
  span[class^="link-"][class^="link-btn-"].icon-right,
  span[class^="link-"][class*=" link-btn-"].icon-right,
  span.link-btn[class*=" link-"].icon-right,
  span[class*=" link-"][class^="link-btn-"].icon-right,
  span[class*=" link-"][class*=" link-btn-"].icon-right,
  button.link.link-btn.icon-right,
  button.link[class^="link-btn-"].icon-right,
  button.link[class*=" link-btn-"].icon-right,
  button.link-btn[class^="link-"].icon-right,
  button[class^="link-"][class^="link-btn-"].icon-right,
  button[class^="link-"][class*=" link-btn-"].icon-right,
  button.link-btn[class*=" link-"].icon-right,
  button[class*=" link-"][class^="link-btn-"].icon-right,
  button[class*=" link-"][class*=" link-btn-"].icon-right {
    text-transform: uppercase;
  }

  a.link.link-btn.icon-right > .icon,
  a.link[class^="link-btn-"].icon-right > .icon,
  a.link[class*=" link-btn-"].icon-right > .icon,
  a.link-btn[class^="link-"].icon-right > .icon,
  a[class^="link-"][class^="link-btn-"].icon-right > .icon,
  a[class^="link-"][class*=" link-btn-"].icon-right > .icon,
  a.link-btn[class*=" link-"].icon-right > .icon,
  a[class*=" link-"][class^="link-btn-"].icon-right > .icon,
  a[class*=" link-"][class*=" link-btn-"].icon-right > .icon,
  span.link.link-btn.icon-right > .icon,
  span.link[class^="link-btn-"].icon-right > .icon,
  span.link[class*=" link-btn-"].icon-right > .icon,
  span.link-btn[class^="link-"].icon-right > .icon,
  span[class^="link-"][class^="link-btn-"].icon-right > .icon,
  span[class^="link-"][class*=" link-btn-"].icon-right > .icon,
  span.link-btn[class*=" link-"].icon-right > .icon,
  span[class*=" link-"][class^="link-btn-"].icon-right > .icon,
  span[class*=" link-"][class*=" link-btn-"].icon-right > .icon,
  button.link.link-btn.icon-right > .icon,
  button.link[class^="link-btn-"].icon-right > .icon,
  button.link[class*=" link-btn-"].icon-right > .icon,
  button.link-btn[class^="link-"].icon-right > .icon,
  button[class^="link-"][class^="link-btn-"].icon-right > .icon,
  button[class^="link-"][class*=" link-btn-"].icon-right > .icon,
  button.link-btn[class*=" link-"].icon-right > .icon,
  button[class*=" link-"][class^="link-btn-"].icon-right > .icon,
  button[class*=" link-"][class*=" link-btn-"].icon-right > .icon {
    margin-left: 10px;
  }

  a.btn,
  a[class^="btn-"],
  a[class*=" btn-"],
  span.btn,
  span[class^="btn-"],
  span[class*=" btn-"],
  button.btn,
  button[class^="btn-"],
  button[class*=" btn-"],
  input.btn[type="button"],
  input[type="button"][class^="btn-"],
  input[type="button"][class*=" btn-"],
  input.btn[type="reset"],
  input[type="reset"][class^="btn-"],
  input[type="reset"][class*=" btn-"],
  input.btn[type="submit"],
  input[type="submit"][class^="btn-"],
  input[type="submit"][class*=" btn-"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: inline-flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    vertical-align: baseline;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: bold;
    white-space: nowrap;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 3px solid transparent;
    transition: all 100ms linear;
    transition-property: color, border-color, background-color;
  }

  @media screen and (max-width: 25.999em) {
    a.btn,
    a[class^="btn-"],
    a[class*=" btn-"],
    span.btn,
    span[class^="btn-"],
    span[class*=" btn-"],
    button.btn,
    button[class^="btn-"],
    button[class*=" btn-"],
    input.btn[type="button"],
    input[type="button"][class^="btn-"],
    input[type="button"][class*=" btn-"],
    input.btn[type="reset"],
    input[type="reset"][class^="btn-"],
    input[type="reset"][class*=" btn-"],
    input.btn[type="submit"],
    input[type="submit"][class^="btn-"],
    input[type="submit"][class*=" btn-"] {
      font-size: 0.75rem;
      line-height: 1.66667;
    }
  }

  @media screen and (min-width: 26em) and (max-width: 35.999em) {
    a.btn,
    a[class^="btn-"],
    a[class*=" btn-"],
    span.btn,
    span[class^="btn-"],
    span[class*=" btn-"],
    button.btn,
    button[class^="btn-"],
    button[class*=" btn-"],
    input.btn[type="button"],
    input[type="button"][class^="btn-"],
    input[type="button"][class*=" btn-"],
    input.btn[type="reset"],
    input[type="reset"][class^="btn-"],
    input[type="reset"][class*=" btn-"],
    input.btn[type="submit"],
    input[type="submit"][class^="btn-"],
    input[type="submit"][class*=" btn-"] {
      font-size: 0.75rem;
      line-height: 1.66667;
    }
  }

  @media screen and (min-width: 36em) and (max-width: 51.999em) {
    a.btn,
    a[class^="btn-"],
    a[class*=" btn-"],
    span.btn,
    span[class^="btn-"],
    span[class*=" btn-"],
    button.btn,
    button[class^="btn-"],
    button[class*=" btn-"],
    input.btn[type="button"],
    input[type="button"][class^="btn-"],
    input[type="button"][class*=" btn-"],
    input.btn[type="reset"],
    input[type="reset"][class^="btn-"],
    input[type="reset"][class*=" btn-"],
    input.btn[type="submit"],
    input[type="submit"][class^="btn-"],
    input[type="submit"][class*=" btn-"] {
      font-size: 0.75rem;
      line-height: 1.66667;
    }
  }

  @media screen and (min-width: 52em) and (max-width: 71.999em) {
    a.btn,
    a[class^="btn-"],
    a[class*=" btn-"],
    span.btn,
    span[class^="btn-"],
    span[class*=" btn-"],
    button.btn,
    button[class^="btn-"],
    button[class*=" btn-"],
    input.btn[type="button"],
    input[type="button"][class^="btn-"],
    input[type="button"][class*=" btn-"],
    input.btn[type="reset"],
    input[type="reset"][class^="btn-"],
    input[type="reset"][class*=" btn-"],
    input.btn[type="submit"],
    input[type="submit"][class^="btn-"],
    input[type="submit"][class*=" btn-"] {
      font-size: 0.75rem;
      line-height: 1.66667;
    }
  }

  @media screen and (min-width: 72em) and (max-width: 103.999em) {
    a.btn,
    a[class^="btn-"],
    a[class*=" btn-"],
    span.btn,
    span[class^="btn-"],
    span[class*=" btn-"],
    button.btn,
    button[class^="btn-"],
    button[class*=" btn-"],
    input.btn[type="button"],
    input[type="button"][class^="btn-"],
    input[type="button"][class*=" btn-"],
    input.btn[type="reset"],
    input[type="reset"][class^="btn-"],
    input[type="reset"][class*=" btn-"],
    input.btn[type="submit"],
    input[type="submit"][class^="btn-"],
    input[type="submit"][class*=" btn-"] {
      font-size: 0.75rem;
      line-height: 1.66667;
    }
  }

  @media screen and (min-width: 104em) {
    a.btn,
    a[class^="btn-"],
    a[class*=" btn-"],
    span.btn,
    span[class^="btn-"],
    span[class*=" btn-"],
    button.btn,
    button[class^="btn-"],
    button[class*=" btn-"],
    input.btn[type="button"],
    input[type="button"][class^="btn-"],
    input[type="button"][class*=" btn-"],
    input.btn[type="reset"],
    input[type="reset"][class^="btn-"],
    input[type="reset"][class*=" btn-"],
    input.btn[type="submit"],
    input[type="submit"][class^="btn-"],
    input[type="submit"][class*=" btn-"] {
      font-size: 0.75rem;
      line-height: 1.66667;
    }
  }

  .btn,
  [class^="btn-"],
  [class*=" btn-"] {
    min-width: 12em;
    padding: 0 20px;
    height: 50px;
    color: #000;
    background-color: #cfcfcf;
  }

  .btn span:not(.icon),
  [class^="btn-"] span:not(.icon),
  [class*=" btn-"] span:not(.icon) {
    position: relative;
    top: 0.05em;
  }

  .link-outer:hover .btn,
  .link-outer:active .btn,
  .link-outer:focus .btn,
  .btn:hover,
  .btn:active,
  .btn:focus,
  .link-outer:hover [class^="btn-"],
  .link-outer:active [class^="btn-"],
  .link-outer:focus [class^="btn-"],
  [class^="btn-"]:hover,
  [class^="btn-"]:active,
  [class^="btn-"]:focus,
  .link-outer:hover [class*=" btn-"],
  .link-outer:active [class*=" btn-"],
  .link-outer:focus [class*=" btn-"],
  [class*=" btn-"]:hover,
  [class*=" btn-"]:active,
  [class*=" btn-"]:focus {
    text-decoration: none;
    background-color: #cfcfcf;
  }

  .link-outer:hover .btn.btn-link span.text,
  .link-outer:active .btn.btn-link span.text,
  .link-outer:focus .btn.btn-link span.text,
  .btn:hover.btn-link span.text,
  .btn:active.btn-link span.text,
  .btn:focus.btn-link span.text,
  .link-outer:hover [class^="btn-"].btn-link span.text,
  .link-outer:active [class^="btn-"].btn-link span.text,
  .link-outer:focus [class^="btn-"].btn-link span.text,
  [class^="btn-"]:hover.btn-link span.text,
  [class^="btn-"]:active.btn-link span.text,
  [class^="btn-"]:focus.btn-link span.text,
  .link-outer:hover [class*=" btn-"].btn-link span.text,
  .link-outer:active [class*=" btn-"].btn-link span.text,
  .link-outer:focus [class*=" btn-"].btn-link span.text,
  [class*=" btn-"]:hover.btn-link span.text,
  [class*=" btn-"]:active.btn-link span.text,
  [class*=" btn-"]:focus.btn-link span.text {
    background-image: linear-gradient(currentColor, currentColor);
    background-size: 1px 1px;
    background-position: 0 bottom;
    background-repeat: repeat-x;
    background-clip: border-box;
    text-decoration: none;
  }

  .link-outer.focus-visible .btn,
  .btn.focus-visible,
  .link-outer.focus-visible [class^="btn-"],
  [class^="btn-"].focus-visible,
  .link-outer.focus-visible [class*=" btn-"],
  [class*=" btn-"].focus-visible {
    box-shadow: 0 0 0 3px #cdcdcd;
  }

  .btn.disabled,
  .btn:disabled,
  [class^="btn-"].disabled,
  [class^="btn-"]:disabled,
  [class*=" btn-"].disabled,
  [class*=" btn-"]:disabled {
    color: inherit !important;
    opacity: 0.6;
    pointer-events: none;
    cursor: default;
  }

  .btn.trim-left,
  [class^="btn-"].trim-left,
  [class*=" btn-"].trim-left {
    margin-left: -20px;
  }

  .btn.trim-right,
  [class^="btn-"].trim-right,
  [class*=" btn-"].trim-right {
    margin-left: -20px;
  }

  .btn.trim,
  [class^="btn-"].trim,
  [class*=" btn-"].trim {
    height: auto !important;
    padding: 0 !important;
  }

  .btn-icon-left {
    padding-left: 10px;
  }

  .btn-icon-left.trim-left {
    margin-left: -10px;
  }

  .btn-icon-left.btn-s {
    height: 40px;
  }

  .btn-icon-left > .icon {
    margin-right: 10px;
  }

  .btn-icon-right {
    padding-right: 10px;
  }

  .btn-icon-right.trim-right {
    margin-left: -10px;
  }

  .btn-icon-right.btn-s {
    height: 40px;
  }

  .btn-icon-right > .icon {
    margin-left: 10px;
  }

  .btn-icon-above {
    flex-direction: column;
    height: 70px;
  }

  .btn-icon-above > .icon {
    margin-bottom: 10px;
  }

  .btn-block {
    display: flex !important;
    width: 100% !important;
  }

  .btn-link {
    min-width: 0 !important;
    background: none !important;
    border: none !important;
  }

  .btn-s {
    min-width: 8em;
    padding: 0 10px;
    height: 30px;
  }

  .btn-s.trim-left {
    margin-left: -10px;
  }

  .btn-s.trim-right {
    margin-left: -10px;
  }

  .btn-ghost {
    background-color: transparent !important;
    border-color: #cfcfcf !important;
  }

  .btn-ghost:hover,
  .btn-ghost:active,
  .btn-ghost:focus {
    border-color: #cfcfcf !important;
    background-color: #cfcfcf !important;
  }

  .btn-scirocco:not(.btn-ghost):not(.btn-link),
  .theme-scirocco .btn-theme:not(.btn-ghost):not(.btn-link) {
    background-color: #4d7396;
  }

  .btn-scirocco.btn-ghost,
  .theme-scirocco .btn-theme.btn-ghost {
    border-color: #4d7396 !important;
  }

  .btn-scirocco:hover,
  .btn-scirocco:active,
  .btn-scirocco:focus,
  .theme-scirocco .btn-theme:hover,
  .theme-scirocco .btn-theme:active,
  .theme-scirocco .btn-theme:focus {
    background-color: #5f6e66 !important;
  }

  .btn-scirocco:hover.btn-ghost,
  .btn-scirocco:active.btn-ghost,
  .btn-scirocco:focus.btn-ghost,
  .theme-scirocco .btn-theme:hover.btn-ghost,
  .theme-scirocco .btn-theme:active.btn-ghost,
  .theme-scirocco .btn-theme:focus.btn-ghost {
    border-color: #5f6e66 !important;
  }

  .btn-satin:not(.btn-ghost):not(.btn-link),
  .theme-satin .btn-theme:not(.btn-ghost):not(.btn-link) {
    background-color: #cfcfcf;
  }

  .btn-satin.btn-ghost,
  .theme-satin .btn-theme.btn-ghost {
    border-color: #cfcfcf !important;
  }

  .btn-satin:hover,
  .btn-satin:active,
  .btn-satin:focus,
  .theme-satin .btn-theme:hover,
  .theme-satin .btn-theme:active,
  .theme-satin .btn-theme:focus {
    background-color: #cfcfcf !important;
  }

  .btn-satin:hover.btn-ghost,
  .btn-satin:active.btn-ghost,
  .btn-satin:focus.btn-ghost,
  .theme-satin .btn-theme:hover.btn-ghost,
  .theme-satin .btn-theme:active.btn-ghost,
  .theme-satin .btn-theme:focus.btn-ghost {
    border-color: #cfcfcf !important;
  }

  .btn-gumleaf:not(.btn-ghost):not(.btn-link),
  .theme-gumleaf .btn-theme:not(.btn-ghost):not(.btn-link) {
    background-color: #afc8b9;
  }

  .btn-gumleaf.btn-ghost,
  .theme-gumleaf .btn-theme.btn-ghost {
    border-color: #afc8b9 !important;
  }

  .btn-gumleaf:hover,
  .btn-gumleaf:active,
  .btn-gumleaf:focus,
  .theme-gumleaf .btn-theme:hover,
  .theme-gumleaf .btn-theme:active,
  .theme-gumleaf .btn-theme:focus {
    background-color: #9eb4a7 !important;
  }

  .btn-gumleaf:hover.btn-ghost,
  .btn-gumleaf:active.btn-ghost,
  .btn-gumleaf:focus.btn-ghost,
  .theme-gumleaf .btn-theme:hover.btn-ghost,
  .theme-gumleaf .btn-theme:active.btn-ghost,
  .theme-gumleaf .btn-theme:focus.btn-ghost {
    border-color: #9eb4a7 !important;
  }

  .btn-limette:not(.btn-ghost):not(.btn-link),
  .theme-limette .btn-theme:not(.btn-ghost):not(.btn-link) {
    background-color: #d2dc3c;
  }

  .btn-limette.btn-ghost,
  .theme-limette .btn-theme.btn-ghost {
    border-color: #d2dc3c !important;
  }

  .btn-limette:hover,
  .btn-limette:active,
  .btn-limette:focus,
  .theme-limette .btn-theme:hover,
  .theme-limette .btn-theme:active,
  .theme-limette .btn-theme:focus {
    background-color: #bdc636 !important;
  }

  .btn-limette:hover.btn-ghost,
  .btn-limette:active.btn-ghost,
  .btn-limette:focus.btn-ghost,
  .theme-limette .btn-theme:hover.btn-ghost,
  .theme-limette .btn-theme:active.btn-ghost,
  .theme-limette .btn-theme:focus.btn-ghost {
    border-color: #bdc636 !important;
  }

  .btn-purple:not(.btn-ghost):not(.btn-link),
  .theme-purple .btn-theme:not(.btn-ghost):not(.btn-link) {
    background-color: #918cc3;
  }

  .btn-purple.btn-ghost,
  .theme-purple .btn-theme.btn-ghost {
    border-color: #918cc3 !important;
  }

  .btn-purple:hover,
  .btn-purple:active,
  .btn-purple:focus,
  .theme-purple .btn-theme:hover,
  .theme-purple .btn-theme:active,
  .theme-purple .btn-theme:focus {
    background-color: #837eb0 !important;
  }

  .btn-purple:hover.btn-ghost,
  .btn-purple:active.btn-ghost,
  .btn-purple:focus.btn-ghost,
  .theme-purple .btn-theme:hover.btn-ghost,
  .theme-purple .btn-theme:active.btn-ghost,
  .theme-purple .btn-theme:focus.btn-ghost {
    border-color: #837eb0 !important;
  }

  .btn-sage:not(.btn-ghost):not(.btn-link),
  .theme-sage .btn-theme:not(.btn-ghost):not(.btn-link) {
    background-color: #9ba578;
  }

  .btn-sage.btn-ghost,
  .theme-sage .btn-theme.btn-ghost {
    border-color: #9ba578 !important;
  }

  .btn-sage:hover,
  .btn-sage:active,
  .btn-sage:focus,
  .theme-sage .btn-theme:hover,
  .theme-sage .btn-theme:active,
  .theme-sage .btn-theme:focus {
    background-color: #8c956c !important;
  }

  .btn-sage:hover.btn-ghost,
  .btn-sage:active.btn-ghost,
  .btn-sage:focus.btn-ghost,
  .theme-sage .btn-theme:hover.btn-ghost,
  .theme-sage .btn-theme:active.btn-ghost,
  .theme-sage .btn-theme:focus.btn-ghost {
    border-color: #8c956c !important;
  }

  .btn-scirocco-contrast:not(.btn-ghost):not(.btn-link),
  .theme-scirocco-contrast .btn-theme:not(.btn-ghost):not(.btn-link) {
    background-color: #fff;
  }

  .btn-scirocco-contrast.btn-ghost,
  .theme-scirocco-contrast .btn-theme.btn-ghost {
    border-color: #fff !important;
  }

  .btn-scirocco-contrast:hover,
  .btn-scirocco-contrast:active,
  .btn-scirocco-contrast:focus,
  .theme-scirocco-contrast .btn-theme:hover,
  .theme-scirocco-contrast .btn-theme:active,
  .theme-scirocco-contrast .btn-theme:focus {
    background-color: null !important;
  }

  .btn-scirocco-darkened:not(.btn-ghost):not(.btn-link),
  .theme-scirocco-darkened .btn-theme:not(.btn-ghost):not(.btn-link) {
    background-color: #5f6e66;
  }

  .btn-scirocco-darkened.btn-ghost,
  .theme-scirocco-darkened .btn-theme.btn-ghost {
    border-color: #5f6e66 !important;
  }

  .btn-scirocco-darkened:hover,
  .btn-scirocco-darkened:active,
  .btn-scirocco-darkened:focus,
  .theme-scirocco-darkened .btn-theme:hover,
  .theme-scirocco-darkened .btn-theme:active,
  .theme-scirocco-darkened .btn-theme:focus {
    background-color: null !important;
  }

  .btn-scirocco-lightened:not(.btn-ghost):not(.btn-link),
  .theme-scirocco-lightened .btn-theme:not(.btn-ghost):not(.btn-link) {
    background-color: #8f9b95;
  }

  .btn-scirocco-lightened.btn-ghost,
  .theme-scirocco-lightened .btn-theme.btn-ghost {
    border-color: #8f9b95 !important;
  }

  .btn-scirocco-lightened:hover,
  .btn-scirocco-lightened:active,
  .btn-scirocco-lightened:focus,
  .theme-scirocco-lightened .btn-theme:hover,
  .theme-scirocco-lightened .btn-theme:active,
  .theme-scirocco-lightened .btn-theme:focus {
    background-color: null !important;
  }

  .btn-haze-contrast:not(.btn-ghost):not(.btn-link),
  .theme-haze-contrast .btn-theme:not(.btn-ghost):not(.btn-link) {
    background-color: #000;
  }

  .btn-haze-contrast.btn-ghost,
  .theme-haze-contrast .btn-theme.btn-ghost {
    border-color: #000 !important;
  }

  .btn-haze-contrast:hover,
  .btn-haze-contrast:active,
  .btn-haze-contrast:focus,
  .theme-haze-contrast .btn-theme:hover,
  .theme-haze-contrast .btn-theme:active,
  .theme-haze-contrast .btn-theme:focus {
    background-color: null !important;
  }

  .btn-haze-darkened:not(.btn-ghost):not(.btn-link),
  .theme-haze-darkened .btn-theme:not(.btn-ghost):not(.btn-link) {
    background-color: #dedfdd;
  }

  .btn-haze-darkened.btn-ghost,
  .theme-haze-darkened .btn-theme.btn-ghost {
    border-color: #dedfdd !important;
  }

  .btn-haze-darkened:hover,
  .btn-haze-darkened:active,
  .btn-haze-darkened:focus,
  .theme-haze-darkened .btn-theme:hover,
  .theme-haze-darkened .btn-theme:active,
  .theme-haze-darkened .btn-theme:focus {
    background-color: null !important;
  }

  .btn-haze-lightened:not(.btn-ghost):not(.btn-link),
  .theme-haze-lightened .btn-theme:not(.btn-ghost):not(.btn-link) {
    background-color: #f9faf8;
  }

  .btn-haze-lightened.btn-ghost,
  .theme-haze-lightened .btn-theme.btn-ghost {
    border-color: #f9faf8 !important;
  }

  .btn-haze-lightened:hover,
  .btn-haze-lightened:active,
  .btn-haze-lightened:focus,
  .theme-haze-lightened .btn-theme:hover,
  .theme-haze-lightened .btn-theme:active,
  .theme-haze-lightened .btn-theme:focus {
    background-color: null !important;
  }

  .btn-twilight-contrast:not(.btn-ghost):not(.btn-link),
  .theme-twilight-contrast .btn-theme:not(.btn-ghost):not(.btn-link) {
    background-color: #000;
  }

  .btn-twilight-contrast.btn-ghost,
  .theme-twilight-contrast .btn-theme.btn-ghost {
    border-color: #000 !important;
  }

  .btn-twilight-contrast:hover,
  .btn-twilight-contrast:active,
  .btn-twilight-contrast:focus,
  .theme-twilight-contrast .btn-theme:hover,
  .theme-twilight-contrast .btn-theme:active,
  .theme-twilight-contrast .btn-theme:focus {
    background-color: null !important;
  }

  .btn-twilight-darkened:not(.btn-ghost):not(.btn-link),
  .theme-twilight-darkened .btn-theme:not(.btn-ghost):not(.btn-link) {
    background-color: #ddddd4;
  }

  .btn-twilight-darkened.btn-ghost,
  .theme-twilight-darkened .btn-theme.btn-ghost {
    border-color: #ddddd4 !important;
  }

  .btn-twilight-darkened:hover,
  .btn-twilight-darkened:active,
  .btn-twilight-darkened:focus,
  .theme-twilight-darkened .btn-theme:hover,
  .theme-twilight-darkened .btn-theme:active,
  .theme-twilight-darkened .btn-theme:focus {
    background-color: null !important;
  }

  .btn-twilight-lightened:not(.btn-ghost):not(.btn-link),
  .theme-twilight-lightened .btn-theme:not(.btn-ghost):not(.btn-link) {
    background-color: #f8f8f0;
  }

  .btn-twilight-lightened.btn-ghost,
  .theme-twilight-lightened .btn-theme.btn-ghost {
    border-color: #f8f8f0 !important;
  }

  .btn-twilight-lightened:hover,
  .btn-twilight-lightened:active,
  .btn-twilight-lightened:focus,
  .theme-twilight-lightened .btn-theme:hover,
  .theme-twilight-lightened .btn-theme:active,
  .theme-twilight-lightened .btn-theme:focus {
    background-color: null !important;
  }

  .btn-satin-contrast:not(.btn-ghost):not(.btn-link),
  .theme-satin-contrast .btn-theme:not(.btn-ghost):not(.btn-link) {
    background-color: #000;
  }

  .btn-satin-contrast.btn-ghost,
  .theme-satin-contrast .btn-theme.btn-ghost {
    border-color: #000 !important;
  }

  .btn-satin-contrast:hover,
  .btn-satin-contrast:active,
  .btn-satin-contrast:focus,
  .theme-satin-contrast .btn-theme:hover,
  .theme-satin-contrast .btn-theme:active,
  .theme-satin-contrast .btn-theme:focus {
    background-color: null !important;
  }

  .btn-satin-darkened:not(.btn-ghost):not(.btn-link),
  .theme-satin-darkened .btn-theme:not(.btn-ghost):not(.btn-link) {
    background-color: #cfcfcf;
  }

  .btn-satin-darkened.btn-ghost,
  .theme-satin-darkened .btn-theme.btn-ghost {
    border-color: #cfcfcf !important;
  }

  .btn-satin-darkened:hover,
  .btn-satin-darkened:active,
  .btn-satin-darkened:focus,
  .theme-satin-darkened .btn-theme:hover,
  .theme-satin-darkened .btn-theme:active,
  .theme-satin-darkened .btn-theme:focus {
    background-color: null !important;
  }

  .btn-satin-lightened:not(.btn-ghost):not(.btn-link),
  .theme-satin-lightened .btn-theme:not(.btn-ghost):not(.btn-link) {
    background-color: #ecece1;
  }

  .btn-satin-lightened.btn-ghost,
  .theme-satin-lightened .btn-theme.btn-ghost {
    border-color: #ecece1 !important;
  }

  .btn-satin-lightened:hover,
  .btn-satin-lightened:active,
  .btn-satin-lightened:focus,
  .theme-satin-lightened .btn-theme:hover,
  .theme-satin-lightened .btn-theme:active,
  .theme-satin-lightened .btn-theme:focus {
    background-color: null !important;
  }

  .btn-gumleaf-contrast:not(.btn-ghost):not(.btn-link),
  .theme-gumleaf-contrast .btn-theme:not(.btn-ghost):not(.btn-link) {
    background-color: #000;
  }

  .btn-gumleaf-contrast.btn-ghost,
  .theme-gumleaf-contrast .btn-theme.btn-ghost {
    border-color: #000 !important;
  }

  .btn-gumleaf-contrast:hover,
  .btn-gumleaf-contrast:active,
  .btn-gumleaf-contrast:focus,
  .theme-gumleaf-contrast .btn-theme:hover,
  .theme-gumleaf-contrast .btn-theme:active,
  .theme-gumleaf-contrast .btn-theme:focus {
    background-color: null !important;
  }

  .btn-gumleaf-darkened:not(.btn-ghost):not(.btn-link),
  .theme-gumleaf-darkened .btn-theme:not(.btn-ghost):not(.btn-link) {
    background-color: #9eb4a7;
  }

  .btn-gumleaf-darkened.btn-ghost,
  .theme-gumleaf-darkened .btn-theme.btn-ghost {
    border-color: #9eb4a7 !important;
  }

  .btn-gumleaf-darkened:hover,
  .btn-gumleaf-darkened:active,
  .btn-gumleaf-darkened:focus,
  .theme-gumleaf-darkened .btn-theme:hover,
  .theme-gumleaf-darkened .btn-theme:active,
  .theme-gumleaf-darkened .btn-theme:focus {
    background-color: null !important;
  }

  .btn-gumleaf-lightened:not(.btn-ghost):not(.btn-link),
  .theme-gumleaf-lightened .btn-theme:not(.btn-ghost):not(.btn-link) {
    background-color: #c3d6cb;
  }

  .btn-gumleaf-lightened.btn-ghost,
  .theme-gumleaf-lightened .btn-theme.btn-ghost {
    border-color: #c3d6cb !important;
  }

  .btn-gumleaf-lightened:hover,
  .btn-gumleaf-lightened:active,
  .btn-gumleaf-lightened:focus,
  .theme-gumleaf-lightened .btn-theme:hover,
  .theme-gumleaf-lightened .btn-theme:active,
  .theme-gumleaf-lightened .btn-theme:focus {
    background-color: null !important;
  }

  .btn-limette-contrast:not(.btn-ghost):not(.btn-link),
  .theme-limette-contrast .btn-theme:not(.btn-ghost):not(.btn-link) {
    background-color: #000;
  }

  .btn-limette-contrast.btn-ghost,
  .theme-limette-contrast .btn-theme.btn-ghost {
    border-color: #000 !important;
  }

  .btn-limette-contrast:hover,
  .btn-limette-contrast:active,
  .btn-limette-contrast:focus,
  .theme-limette-contrast .btn-theme:hover,
  .theme-limette-contrast .btn-theme:active,
  .theme-limette-contrast .btn-theme:focus {
    background-color: null !important;
  }

  .btn-limette-darkened:not(.btn-ghost):not(.btn-link),
  .theme-limette-darkened .btn-theme:not(.btn-ghost):not(.btn-link) {
    background-color: #bdc636;
  }

  .btn-limette-darkened.btn-ghost,
  .theme-limette-darkened .btn-theme.btn-ghost {
    border-color: #bdc636 !important;
  }

  .btn-limette-darkened:hover,
  .btn-limette-darkened:active,
  .btn-limette-darkened:focus,
  .theme-limette-darkened .btn-theme:hover,
  .theme-limette-darkened .btn-theme:active,
  .theme-limette-darkened .btn-theme:focus {
    background-color: null !important;
  }

  .btn-limette-lightened:not(.btn-ghost):not(.btn-link),
  .theme-limette-lightened .btn-theme:not(.btn-ghost):not(.btn-link) {
    background-color: #dde56d;
  }

  .btn-limette-lightened.btn-ghost,
  .theme-limette-lightened .btn-theme.btn-ghost {
    border-color: #dde56d !important;
  }

  .btn-limette-lightened:hover,
  .btn-limette-lightened:active,
  .btn-limette-lightened:focus,
  .theme-limette-lightened .btn-theme:hover,
  .theme-limette-lightened .btn-theme:active,
  .theme-limette-lightened .btn-theme:focus {
    background-color: null !important;
  }

  .btn-purple-contrast:not(.btn-ghost):not(.btn-link),
  .theme-purple-contrast .btn-theme:not(.btn-ghost):not(.btn-link) {
    background-color: #fff;
  }

  .btn-purple-contrast.btn-ghost,
  .theme-purple-contrast .btn-theme.btn-ghost {
    border-color: #fff !important;
  }

  .btn-purple-contrast:hover,
  .btn-purple-contrast:active,
  .btn-purple-contrast:focus,
  .theme-purple-contrast .btn-theme:hover,
  .theme-purple-contrast .btn-theme:active,
  .theme-purple-contrast .btn-theme:focus {
    background-color: null !important;
  }

  .btn-purple-darkened:not(.btn-ghost):not(.btn-link),
  .theme-purple-darkened .btn-theme:not(.btn-ghost):not(.btn-link) {
    background-color: #837eb0;
  }

  .btn-purple-darkened.btn-ghost,
  .theme-purple-darkened .btn-theme.btn-ghost {
    border-color: #837eb0 !important;
  }

  .btn-purple-darkened:hover,
  .btn-purple-darkened:active,
  .btn-purple-darkened:focus,
  .theme-purple-darkened .btn-theme:hover,
  .theme-purple-darkened .btn-theme:active,
  .theme-purple-darkened .btn-theme:focus {
    background-color: null !important;
  }

  .btn-purple-lightened:not(.btn-ghost):not(.btn-link),
  .theme-purple-lightened .btn-theme:not(.btn-ghost):not(.btn-link) {
    background-color: #ada9d2;
  }

  .btn-purple-lightened.btn-ghost,
  .theme-purple-lightened .btn-theme.btn-ghost {
    border-color: #ada9d2 !important;
  }

  .btn-purple-lightened:hover,
  .btn-purple-lightened:active,
  .btn-purple-lightened:focus,
  .theme-purple-lightened .btn-theme:hover,
  .theme-purple-lightened .btn-theme:active,
  .theme-purple-lightened .btn-theme:focus {
    background-color: null !important;
  }

  .btn-sage-contrast:not(.btn-ghost):not(.btn-link),
  .theme-sage-contrast .btn-theme:not(.btn-ghost):not(.btn-link) {
    background-color: #fff;
  }

  .btn-sage-contrast.btn-ghost,
  .theme-sage-contrast .btn-theme.btn-ghost {
    border-color: #fff !important;
  }

  .btn-sage-contrast:hover,
  .btn-sage-contrast:active,
  .btn-sage-contrast:focus,
  .theme-sage-contrast .btn-theme:hover,
  .theme-sage-contrast .btn-theme:active,
  .theme-sage-contrast .btn-theme:focus {
    background-color: null !important;
  }

  .btn-sage-darkened:not(.btn-ghost):not(.btn-link),
  .theme-sage-darkened .btn-theme:not(.btn-ghost):not(.btn-link) {
    background-color: #8c956c;
  }

  .btn-sage-darkened.btn-ghost,
  .theme-sage-darkened .btn-theme.btn-ghost {
    border-color: #8c956c !important;
  }

  .btn-sage-darkened:hover,
  .btn-sage-darkened:active,
  .btn-sage-darkened:focus,
  .theme-sage-darkened .btn-theme:hover,
  .theme-sage-darkened .btn-theme:active,
  .theme-sage-darkened .btn-theme:focus {
    background-color: null !important;
  }

  .btn-sage-lightened:not(.btn-ghost):not(.btn-link),
  .theme-sage-lightened .btn-theme:not(.btn-ghost):not(.btn-link) {
    background-color: #b4bc9a;
  }

  .btn-sage-lightened.btn-ghost,
  .theme-sage-lightened .btn-theme.btn-ghost {
    border-color: #b4bc9a !important;
  }

  .btn-sage-lightened:hover,
  .btn-sage-lightened:active,
  .btn-sage-lightened:focus,
  .theme-sage-lightened .btn-theme:hover,
  .theme-sage-lightened .btn-theme:active,
  .theme-sage-lightened .btn-theme:focus {
    background-color: null !important;
  }

  .btn-rust-contrast:not(.btn-ghost):not(.btn-link),
  .theme-rust-contrast .btn-theme:not(.btn-ghost):not(.btn-link) {
    background-color: #fff;
  }

  .btn-rust-contrast.btn-ghost,
  .theme-rust-contrast .btn-theme.btn-ghost {
    border-color: #fff !important;
  }

  .btn-rust-contrast:hover,
  .btn-rust-contrast:active,
  .btn-rust-contrast:focus,
  .theme-rust-contrast .btn-theme:hover,
  .theme-rust-contrast .btn-theme:active,
  .theme-rust-contrast .btn-theme:focus {
    background-color: null !important;
  }

  .btn-rust-darkened:hover,
  .btn-rust-darkened:active,
  .btn-rust-darkened:focus,
  .theme-rust-darkened .btn-theme:hover,
  .theme-rust-darkened .btn-theme:active,
  .theme-rust-darkened .btn-theme:focus {
    background-color: null !important;
  }

  .btn-rust-lightened:hover,
  .btn-rust-lightened:active,
  .btn-rust-lightened:focus,
  .theme-rust-lightened .btn-theme:hover,
  .theme-rust-lightened .btn-theme:active,
  .theme-rust-lightened .btn-theme:focus {
    background-color: null !important;
  }

  .btn-lite-contrast:not(.btn-ghost):not(.btn-link),
  .theme-lite-contrast .btn-theme:not(.btn-ghost):not(.btn-link) {
    background-color: #000;
  }

  .btn-lite-contrast.btn-ghost,
  .theme-lite-contrast .btn-theme.btn-ghost {
    border-color: #000 !important;
  }

  .btn-lite-contrast:hover,
  .btn-lite-contrast:active,
  .btn-lite-contrast:focus,
  .theme-lite-contrast .btn-theme:hover,
  .theme-lite-contrast .btn-theme:active,
  .theme-lite-contrast .btn-theme:focus {
    background-color: null !important;
  }

  .btn-lite-darkened:not(.btn-ghost):not(.btn-link),
  .theme-lite-darkened .btn-theme:not(.btn-ghost):not(.btn-link) {
    background-color: #e6e6e6;
  }

  .btn-lite-darkened.btn-ghost,
  .theme-lite-darkened .btn-theme.btn-ghost {
    border-color: #e6e6e6 !important;
  }

  .btn-lite-darkened:hover,
  .btn-lite-darkened:active,
  .btn-lite-darkened:focus,
  .theme-lite-darkened .btn-theme:hover,
  .theme-lite-darkened .btn-theme:active,
  .theme-lite-darkened .btn-theme:focus {
    background-color: null !important;
  }

  .btn-lite-lightened:not(.btn-ghost):not(.btn-link),
  .theme-lite-lightened .btn-theme:not(.btn-ghost):not(.btn-link) {
    background-color: #fff;
  }

  .btn-lite-lightened.btn-ghost,
  .theme-lite-lightened .btn-theme.btn-ghost {
    border-color: #fff !important;
  }

  .btn-lite-lightened:hover,
  .btn-lite-lightened:active,
  .btn-lite-lightened:focus,
  .theme-lite-lightened .btn-theme:hover,
  .theme-lite-lightened .btn-theme:active,
  .theme-lite-lightened .btn-theme:focus {
    background-color: null !important;
  }

  .btn-dark-contrast:not(.btn-ghost):not(.btn-link),
  .theme-dark-contrast .btn-theme:not(.btn-ghost):not(.btn-link) {
    background-color: #fff;
  }

  .btn-dark-contrast.btn-ghost,
  .theme-dark-contrast .btn-theme.btn-ghost {
    border-color: #fff !important;
  }

  .btn-dark-contrast:hover,
  .btn-dark-contrast:active,
  .btn-dark-contrast:focus,
  .theme-dark-contrast .btn-theme:hover,
  .theme-dark-contrast .btn-theme:active,
  .theme-dark-contrast .btn-theme:focus {
    background-color: null !important;
  }

  .btn-dark-darkened:not(.btn-ghost):not(.btn-link),
  .theme-dark-darkened .btn-theme:not(.btn-ghost):not(.btn-link) {
    background-color: #000;
  }

  .btn-dark-darkened.btn-ghost,
  .theme-dark-darkened .btn-theme.btn-ghost {
    border-color: #000 !important;
  }

  .btn-dark-darkened:hover,
  .btn-dark-darkened:active,
  .btn-dark-darkened:focus,
  .theme-dark-darkened .btn-theme:hover,
  .theme-dark-darkened .btn-theme:active,
  .theme-dark-darkened .btn-theme:focus {
    background-color: null !important;
  }

  .btn-dark-lightened:not(.btn-ghost):not(.btn-link),
  .theme-dark-lightened .btn-theme:not(.btn-ghost):not(.btn-link) {
    background-color: #404040;
  }

  .btn-dark-lightened.btn-ghost,
  .theme-dark-lightened .btn-theme.btn-ghost {
    border-color: #404040 !important;
  }

  .btn-dark-lightened:hover,
  .btn-dark-lightened:active,
  .btn-dark-lightened:focus,
  .theme-dark-lightened .btn-theme:hover,
  .theme-dark-lightened .btn-theme:active,
  .theme-dark-lightened .btn-theme:focus {
    background-color: null !important;
  }

  .btn-dark:not(.btn-ghost):not(.btn-link) {
    color: #fff !important;
    background-color: #000 !important;
  }

  .btn-dark.btn-ghost {
    border-color: #000 !important;
  }

  .btn-dark:hover.btn-ghost,
  .btn-dark:active.btn-ghost,
  .btn-dark:focus.btn-ghost {
    border-color: #404040 !important;
  }

  .btn-dark:hover:not(.btn-link),
  .btn-dark:active:not(.btn-link),
  .btn-dark:focus:not(.btn-link) {
    color: #fff;
    background-color: #404040 !important;
  }

  .btn-lite:not(.btn-ghost):not(.btn-link) {
    background-color: #fff !important;
  }

  .btn-lite.btn-ghost {
    border-color: #fff !important;
  }

  .btn-lite:hover.btn-ghost,
  .btn-lite:active.btn-ghost,
  .btn-lite:focus.btn-ghost {
    border-color: #e6e6e6 !important;
  }

  .btn-lite:hover:not(.btn-link),
  .btn-lite:active:not(.btn-link),
  .btn-lite:focus:not(.btn-link) {
    background-color: #e6e6e6 !important;
  }

  .pgn-list {
    display: flex;
  }

  .pgn-list > * + * {
    margin-left: 1em;
  }

  .pgn-link {
    min-width: 0 !important;
  }

  .pgn-ellipse {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.2s;
  }

  .fade-enter-to {
    opacity: 1;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .cross-fade-enter-active,
  .cross-fade-leave-active {
    transition: opacity 0.1s;
  }

  .cross-fade-enter-to {
    opacity: 1;
  }

  .cross-fade-enter,
  .cross-fade-leave-to {
    opacity: 0;
  }

  .mono,
  [class^="mono-"],
  [class*=" mono-"] {
    font-weight: normal !important;
  }

  .theme-gumleaf .fg-text {
    color: #4d7396;
  }

  .theme-limette .fg-text {
    color: #6f7755;
  }

  .theme-purple .fg-text {
    color: #6a6793;
  }

  .theme-sage .fg-text {
    color: #6b7016;
  }

  .theme-rust .fg-text {
    color: #b45f2c;
  }

  html {
    touch-action: manipulation;
  }

  .breadcrumb .spacer {
    margin: 0 0.5em;
  }

  @media screen and (min-width: 52em) {
    .breadcrumb .spacer {
      margin: 0 0.75em;
    }
  }

  svg.monad {
    z-index: 1;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
  }

  .banner-monad-txt .txt {
    z-index: 1;
  }

  .banner-monad-txt .txt-inner {
    width: 75%;
    margin-right: 5%;
  }

  .plain-mono-i1 a {
    color: inherit;
    text-decoration: none;
    background-image: linear-gradient(currentColor, currentColor);
    background-size: 1px 1px;
    background-position: 0 bottom;
    background-repeat: repeat-x;
    background-clip: border-box;
    text-decoration: none;
  }

  .plain-mono-i1 a:focus,
  .plain-mono-i1 a:hover,
  .plain-mono-i1 a:active {
    color: inherit;
    text-decoration: none;
  }

  .plain-mono-i1 a.focus-visible {
    box-shadow: 0 0 0 3px #cdcdcd;
  }

  .plain-mono-i1 p {
    font-family: "Signika", monaco, consolas, monospace;
    letter-spacing: calc(0.75px - 0.04688em);
  }

  @media screen and (max-width: 25.999em) {
    .plain-mono-i1 p {
      font-size: 0.875rem;
      line-height: 1.78571;
    }

    .plain-mono-i1 > p {
      margin-top: 0;
      margin-bottom: 0;
    }

    .plain-mono-i1 > * + p {
      margin-top: 30px;
    }
  }

  @media screen and (min-width: 26em) and (max-width: 35.999em) {
    .plain-mono-i1 p {
      font-size: 0.875rem;
      line-height: 1.78571;
    }

    .plain-mono-i1 > p {
      margin-top: 0;
      margin-bottom: 0;
    }

    .plain-mono-i1 > * + p {
      margin-top: 30px;
    }
  }

  @media screen and (min-width: 36em) and (max-width: 51.999em) {
    .plain-mono-i1 p {
      font-size: 0.875rem;
      line-height: 1.78571;
    }

    .plain-mono-i1 > p {
      margin-top: 0;
      margin-bottom: 0;
    }

    .plain-mono-i1 > * + p {
      margin-top: 30px;
    }
  }

  @media screen and (min-width: 52em) and (max-width: 71.999em) {
    .plain-mono-i1 p {
      font-size: 0.875rem;
      line-height: 1.78571;
    }

    .plain-mono-i1 > p {
      margin-top: 0;
      margin-bottom: 0;
    }

    .plain-mono-i1 > * + p {
      margin-top: 30px;
    }
  }

  @media screen and (min-width: 72em) and (max-width: 103.999em) {
    .plain-mono-i1 p {
      font-size: 0.875rem;
      line-height: 1.78571;
    }

    .plain-mono-i1 > p {
      margin-top: 0;
      margin-bottom: 0;
    }

    .plain-mono-i1 > * + p {
      margin-top: 30px;
    }
  }

  @media screen and (min-width: 104em) {
    .plain-mono-i1 p {
      font-size: 0.875rem;
      line-height: 1.78571;
    }

    .plain-mono-i1 > p {
      margin-top: 0;
      margin-bottom: 0;
    }

    .plain-mono-i1 > * + p {
      margin-top: 30px;
    }
  }

  .plain-txt a {
    color: inherit;
    text-decoration: none;
    background-image: linear-gradient(currentColor, currentColor);
    background-size: 1px 1px;
    background-position: 0 bottom;
    background-repeat: repeat-x;
    background-clip: border-box;
    text-decoration: none;
  }

  .plain-txt a:focus,
  .plain-txt a:hover,
  .plain-txt a:active {
    color: inherit;
    text-decoration: none;
  }

  .plain-txt a.focus-visible {
    box-shadow: 0 0 0 3px #cdcdcd;
  }

  .plain-txt > ul,
  .plain-txt > ol {
    list-style-type: none;
  }

  .plain-txt > ul > li:before,
  .plain-txt > ol > li:before {
    display: inline-block;
    width: 1.75em;
    text-align: right;
    position: absolute;
    top: 0;
    right: calc(100% - 1.75em);
    font-size: inherit;
    color: inherit;
    height: 1em;
  }

  .plain-txt > ul {
    counter-reset: ul-li;
  }

  .plain-txt > ul > li {
    counter-increment: ul-li;
  }

  .plain-txt > ol {
    counter-reset: ol-li;
  }

  .plain-txt > ol > li {
    counter-increment: ol-li;
  }

  .plain-txt > ol > li:before {
    content: counter(ol-li, decimal) ".";
  }

  .plain-txt th {
    font-family: "Signika", monaco, consolas, monospace;
    letter-spacing: calc(0.75px - 0.04688em);
    font-size: 16px;
    line-height: 1.78571;
  }

  @media screen and (max-width: 25.999em) {
    .plain-txt > th {
      margin-top: 0;
      margin-bottom: 0;
    }

    .plain-txt > * + th {
      margin-top: 30px;
    }
  }

  @media screen and (min-width: 26em) and (max-width: 35.999em) {
    .plain-txt > th {
      margin-top: 0;
      margin-bottom: 0;
    }

    .plain-txt > * + th {
      margin-top: 30px;
    }
  }

  @media screen and (min-width: 36em) and (max-width: 51.999em) {
    .plain-txt > th {
      margin-top: 0;
      margin-bottom: 0;
    }

    .plain-txt > * + th {
      margin-top: 30px;
    }
  }

  @media screen and (min-width: 52em) and (max-width: 71.999em) {
    .plain-txt > th {
      margin-top: 0;
      margin-bottom: 0;
    }

    .plain-txt > * + th {
      margin-top: 30px;
    }
  }

  @media screen and (min-width: 72em) and (max-width: 103.999em) {
    .plain-txt > th {
      margin-top: 0;
      margin-bottom: 0;
    }

    .plain-txt > * + th {
      margin-top: 30px;
    }
  }

  @media screen and (min-width: 104em) {
    .plain-txt > th {
      margin-top: 0;
      margin-bottom: 0;
    }

    .plain-txt > * + th {
      margin-top: 30px;
    }
  }

  .plain-txt table {
    font-family: "Signika", sans-serif;
    letter-spacing: calc(1px - 0.04167em);
  }

  @media screen and (max-width: 25.999em) {
    .plain-txt table {
      font-size: 1rem;
      line-height: 1.5625;
    }

    .plain-txt > table {
      margin-top: 0;
      margin-bottom: 0;
    }

    .plain-txt > * + table {
      margin-top: 30px;
    }
  }

  @media screen and (min-width: 26em) and (max-width: 35.999em) {
    .plain-txt table {
      font-size: 1rem;
      line-height: 1.5625;
    }

    .plain-txt > table {
      margin-top: 0;
      margin-bottom: 0;
    }

    .plain-txt > * + table {
      margin-top: 30px;
    }
  }

  @media screen and (min-width: 36em) and (max-width: 51.999em) {
    .plain-txt table {
      font-size: 1rem;
      line-height: 1.5625;
    }

    .plain-txt > table {
      margin-top: 0;
      margin-bottom: 0;
    }

    .plain-txt > * + table {
      margin-top: 30px;
    }
  }

  @media screen and (min-width: 52em) and (max-width: 71.999em) {
    .plain-txt table {
      font-size: 1rem;
      line-height: 1.5625;
    }

    .plain-txt > table {
      margin-top: 0;
      margin-bottom: 0;
    }

    .plain-txt > * + table {
      margin-top: 30px;
    }
  }

  @media screen and (min-width: 72em) and (max-width: 103.999em) {
    .plain-txt table {
      font-size: 1rem;
      line-height: 1.5625;
    }

    .plain-txt > table {
      margin-top: 0;
      margin-bottom: 0;
    }

    .plain-txt > * + table {
      margin-top: 30px;
    }
  }

  @media screen and (min-width: 104em) {
    .plain-txt table {
      font-size: 1rem;
      line-height: 1.5625;
    }

    .plain-txt > table {
      margin-top: 0;
      margin-bottom: 0;
    }

    .plain-txt > * + table {
      margin-top: 30px;
    }
  }

  .plain-txt p,
  .plain-txt ul,
  .plain-txt ol,
  .plain-txt dl {
    font-family: "Signika", sans-serif;
    letter-spacing: calc(1px - 0.04167em);
  }

  @media screen and (max-width: 25.999em) {
    .plain-txt p,
    .plain-txt ul,
    .plain-txt ol,
    .plain-txt dl {
      font-size: 1.0625rem;
      line-height: 1.58824;
    }

    .plain-txt > p,
    .plain-txt > ul,
    .plain-txt > ol,
    .plain-txt > dl {
      margin-top: 0;
      margin-bottom: 0;
    }

    .plain-txt > * + p,
    .plain-txt > * + ul,
    .plain-txt > * + ol,
    .plain-txt > * + dl {
      margin-top: 30px;
    }
  }

  @media screen and (min-width: 26em) and (max-width: 35.999em) {
    .plain-txt p,
    .plain-txt ul,
    .plain-txt ol,
    .plain-txt dl {
      font-size: 1.0625rem;
      line-height: 1.58824;
    }

    .plain-txt > p,
    .plain-txt > ul,
    .plain-txt > ol,
    .plain-txt > dl {
      margin-top: 0;
      margin-bottom: 0;
    }

    .plain-txt > * + p,
    .plain-txt > * + ul,
    .plain-txt > * + ol,
    .plain-txt > * + dl {
      margin-top: 30px;
    }
  }

  @media screen and (min-width: 36em) and (max-width: 51.999em) {
    .plain-txt p,
    .plain-txt ul,
    .plain-txt ol,
    .plain-txt dl {
      font-size: 1.0625rem;
      line-height: 1.58824;
    }

    .plain-txt > p,
    .plain-txt > ul,
    .plain-txt > ol,
    .plain-txt > dl {
      margin-top: 0;
      margin-bottom: 0;
    }

    .plain-txt > * + p,
    .plain-txt > * + ul,
    .plain-txt > * + ol,
    .plain-txt > * + dl {
      margin-top: 30px;
    }
  }

  @media screen and (min-width: 52em) and (max-width: 71.999em) {
    .plain-txt p,
    .plain-txt ul,
    .plain-txt ol,
    .plain-txt dl {
      font-size: 1.0625rem;
      line-height: 1.58824;
    }

    .plain-txt > p,
    .plain-txt > ul,
    .plain-txt > ol,
    .plain-txt > dl {
      margin-top: 0;
      margin-bottom: 0;
    }

    .plain-txt > * + p,
    .plain-txt > * + ul,
    .plain-txt > * + ol,
    .plain-txt > * + dl {
      margin-top: 30px;
    }
  }

  @media screen and (min-width: 72em) and (max-width: 103.999em) {
    .plain-txt p,
    .plain-txt ul,
    .plain-txt ol,
    .plain-txt dl {
      font-size: 1.0625rem;
      line-height: 1.58824;
    }

    .plain-txt > p,
    .plain-txt > ul,
    .plain-txt > ol,
    .plain-txt > dl {
      margin-top: 0;
      margin-bottom: 0;
    }

    .plain-txt > * + p,
    .plain-txt > * + ul,
    .plain-txt > * + ol,
    .plain-txt > * + dl {
      margin-top: 30px;
    }
  }

  @media screen and (min-width: 104em) {
    .plain-txt p,
    .plain-txt ul,
    .plain-txt ol,
    .plain-txt dl {
      font-size: 1.0625rem;
      line-height: 1.58824;
    }

    .plain-txt > p,
    .plain-txt > ul,
    .plain-txt > ol,
    .plain-txt > dl {
      margin-top: 0;
      margin-bottom: 0;
    }

    .plain-txt > * + p,
    .plain-txt > * + ul,
    .plain-txt > * + ol,
    .plain-txt > * + dl {
      margin-top: 30px;
    }
  }

  .plain-txt h6,
  .plain-txt blockquote {
    font-family: "Signika", sans-serif;
    letter-spacing: calc(1px - 0.04167em);
  }

  @media screen and (max-width: 25.999em) {
    .plain-txt h6,
    .plain-txt blockquote {
      font-size: 1.25rem;
      line-height: 1.5;
    }

    .plain-txt > h6,
    .plain-txt > blockquote {
      margin-top: 0;
      margin-bottom: 0;
    }

    .plain-txt > * + h6,
    .plain-txt > * + blockquote {
      margin-top: 30px;
    }
  }

  @media screen and (min-width: 26em) and (max-width: 35.999em) {
    .plain-txt h6,
    .plain-txt blockquote {
      font-size: 1.25rem;
      line-height: 1.5;
    }

    .plain-txt > h6,
    .plain-txt > blockquote {
      margin-top: 0;
      margin-bottom: 0;
    }

    .plain-txt > * + h6,
    .plain-txt > * + blockquote {
      margin-top: 30px;
    }
  }

  @media screen and (min-width: 36em) and (max-width: 51.999em) {
    .plain-txt h6,
    .plain-txt blockquote {
      font-size: 1.25rem;
      line-height: 1.5;
    }

    .plain-txt > h6,
    .plain-txt > blockquote {
      margin-top: 0;
      margin-bottom: 0;
    }

    .plain-txt > * + h6,
    .plain-txt > * + blockquote {
      margin-top: 30px;
    }
  }

  @media screen and (min-width: 52em) and (max-width: 71.999em) {
    .plain-txt h6,
    .plain-txt blockquote {
      font-size: 1.25rem;
      line-height: 1.5;
    }

    .plain-txt > h6,
    .plain-txt > blockquote {
      margin-top: 0;
      margin-bottom: 0;
    }

    .plain-txt > * + h6,
    .plain-txt > * + blockquote {
      margin-top: 30px;
    }
  }

  @media screen and (min-width: 72em) and (max-width: 103.999em) {
    .plain-txt h6,
    .plain-txt blockquote {
      font-size: 1.25rem;
      line-height: 1.5;
    }

    .plain-txt > h6,
    .plain-txt > blockquote {
      margin-top: 0;
      margin-bottom: 0;
    }

    .plain-txt > * + h6,
    .plain-txt > * + blockquote {
      margin-top: 30px;
    }
  }

  @media screen and (min-width: 104em) {
    .plain-txt h6,
    .plain-txt blockquote {
      font-size: 1.25rem;
      line-height: 1.5;
    }

    .plain-txt > h6,
    .plain-txt > blockquote {
      margin-top: 0;
      margin-bottom: 0;
    }

    .plain-txt > * + h6,
    .plain-txt > * + blockquote {
      margin-top: 30px;
    }
  }

  .plain-txt h5 {
    font-family: "Signika", sans-serif;
    letter-spacing: calc(1px - 0.04167em);
  }

  @media screen and (max-width: 25.999em) {
    .plain-txt h5 {
      font-size: 1.0625rem;
      line-height: 1.58824;
    }

    .plain-txt > h5 {
      margin-top: 0;
      margin-bottom: 0;
    }

    .plain-txt > * + h5 {
      margin-top: 30px;
    }
  }

  @media screen and (min-width: 26em) and (max-width: 35.999em) {
    .plain-txt h5 {
      font-size: 1.0625rem;
      line-height: 1.58824;
    }

    .plain-txt > h5 {
      margin-top: 0;
      margin-bottom: 0;
    }

    .plain-txt > * + h5 {
      margin-top: 30px;
    }
  }

  @media screen and (min-width: 36em) and (max-width: 51.999em) {
    .plain-txt h5 {
      font-size: 1.0625rem;
      line-height: 1.58824;
    }

    .plain-txt > h5 {
      margin-top: 0;
      margin-bottom: 0;
    }

    .plain-txt > * + h5 {
      margin-top: 30px;
    }
  }

  @media screen and (min-width: 52em) and (max-width: 71.999em) {
    .plain-txt h5 {
      font-size: 1.0625rem;
      line-height: 1.58824;
    }

    .plain-txt > h5 {
      margin-top: 0;
      margin-bottom: 0;
    }

    .plain-txt > * + h5 {
      margin-top: 30px;
    }
  }

  @media screen and (min-width: 72em) and (max-width: 103.999em) {
    .plain-txt h5 {
      font-size: 1.0625rem;
      line-height: 1.58824;
    }

    .plain-txt > h5 {
      margin-top: 0;
      margin-bottom: 0;
    }

    .plain-txt > * + h5 {
      margin-top: 30px;
    }
  }

  @media screen and (min-width: 104em) {
    .plain-txt h5 {
      font-size: 1.0625rem;
      line-height: 1.58824;
    }

    .plain-txt > h5 {
      margin-top: 0;
      margin-bottom: 0;
    }

    .plain-txt > * + h5 {
      margin-top: 30px;
    }
  }

  .plain-txt h4 {
    font-family: "Signika", sans-serif;
    letter-spacing: calc(1px - 0.04167em);
  }

  @media screen and (max-width: 25.999em) {
    .plain-txt h4 {
      font-size: 1.5625rem;
      line-height: 1.4;
    }

    .plain-txt > h4 {
      margin-top: 0;
      margin-bottom: 0;
    }

    .plain-txt > * + h4 {
      margin-top: 30px;
    }
  }

  @media screen and (min-width: 26em) and (max-width: 35.999em) {
    .plain-txt h4 {
      font-size: 1.5625rem;
      line-height: 1.4;
    }

    .plain-txt > h4 {
      margin-top: 0;
      margin-bottom: 0;
    }

    .plain-txt > * + h4 {
      margin-top: 30px;
    }
  }

  @media screen and (min-width: 36em) and (max-width: 51.999em) {
    .plain-txt h4 {
      font-size: 1.5625rem;
      line-height: 1.4;
    }

    .plain-txt > h4 {
      margin-top: 0;
      margin-bottom: 0;
    }

    .plain-txt > * + h4 {
      margin-top: 30px;
    }
  }

  @media screen and (min-width: 52em) and (max-width: 71.999em) {
    .plain-txt h4 {
      font-size: 1.5625rem;
      line-height: 1.4;
    }

    .plain-txt > h4 {
      margin-top: 0;
      margin-bottom: 0;
    }

    .plain-txt > * + h4 {
      margin-top: 30px;
    }
  }

  @media screen and (min-width: 72em) and (max-width: 103.999em) {
    .plain-txt h4 {
      font-size: 1.875rem;
      line-height: 1.33333;
    }

    .plain-txt > h4 {
      margin-top: 0;
      margin-bottom: 0;
    }

    .plain-txt > * + h4 {
      margin-top: 30px;
    }
  }

  @media screen and (min-width: 104em) {
    .plain-txt h4 {
      font-size: 1.875rem;
      line-height: 1.33333;
    }

    .plain-txt > h4 {
      margin-top: 0;
      margin-bottom: 0;
    }

    .plain-txt > * + h4 {
      margin-top: 30px;
    }
  }

  .plain-txt h3 {
    font-family: "Signika", sans-serif;
    letter-spacing: calc(1px - 0.04167em);
  }

  @media screen and (max-width: 25.999em) {
    .plain-txt h3 {
      font-size: 2.5rem;
      line-height: 1.25;
    }

    .plain-txt > h3 {
      margin-top: 0;
      margin-bottom: 0;
    }

    .plain-txt > * + h3 {
      margin-top: 30px;
    }
  }

  @media screen and (min-width: 26em) and (max-width: 35.999em) {
    .plain-txt h3 {
      font-size: 2.5rem;
      line-height: 1.25;
    }

    .plain-txt > h3 {
      margin-top: 0;
      margin-bottom: 0;
    }

    .plain-txt > * + h3 {
      margin-top: 30px;
    }
  }

  @media screen and (min-width: 36em) and (max-width: 51.999em) {
    .plain-txt h3 {
      font-size: 2.5rem;
      line-height: 1.25;
    }

    .plain-txt > h3 {
      margin-top: 0;
      margin-bottom: 0;
    }

    .plain-txt > * + h3 {
      margin-top: 30px;
    }
  }

  @media screen and (min-width: 52em) and (max-width: 71.999em) {
    .plain-txt h3 {
      font-size: 2.5rem;
      line-height: 1.25;
    }

    .plain-txt > h3 {
      margin-top: 0;
      margin-bottom: 0;
    }

    .plain-txt > * + h3 {
      margin-top: 30px;
    }
  }

  @media screen and (min-width: 72em) and (max-width: 103.999em) {
    .plain-txt h3 {
      font-size: 2.8125rem;
      line-height: 1.22222;
    }

    .plain-txt > h3 {
      margin-top: 0;
      margin-bottom: 0;
    }

    .plain-txt > * + h3 {
      margin-top: 30px;
    }
  }

  @media screen and (min-width: 104em) {
    .plain-txt h3 {
      font-size: 2.8125rem;
      line-height: 1.22222;
    }

    .plain-txt > h3 {
      margin-top: 0;
      margin-bottom: 0;
    }

    .plain-txt > * + h3 {
      margin-top: 30px;
    }
  }

  @media screen and (max-width: 25.999em) {
    .plain-txt > * {
      margin-top: 30px;
    }

    .plain-txt p + p {
      margin-top: 10px;
    }

    .plain-txt > * + h3,
    .plain-txt > * + h4,
    .plain-txt > * + h5,
    .plain-txt > * + h6 {
      margin-top: 40px;
    }
  }

  @media screen and (min-width: 26em) and (max-width: 35.999em) {
    .plain-txt > * {
      margin-top: 30px;
    }

    .plain-txt p + p {
      margin-top: 10px;
    }

    .plain-txt > * + h3,
    .plain-txt > * + h4,
    .plain-txt > * + h5,
    .plain-txt > * + h6 {
      margin-top: 40px;
    }
  }

  @media screen and (min-width: 36em) and (max-width: 51.999em) {
    .plain-txt > * {
      margin-top: 30px;
    }

    .plain-txt p + p {
      margin-top: 10px;
    }

    .plain-txt > * + h3,
    .plain-txt > * + h4,
    .plain-txt > * + h5,
    .plain-txt > * + h6 {
      margin-top: 40px;
    }
  }

  @media screen and (min-width: 52em) and (max-width: 71.999em) {
    .plain-txt > * {
      margin-top: 30px;
    }

    .plain-txt p + p {
      margin-top: 10px;
    }

    .plain-txt > * + h3,
    .plain-txt > * + h4,
    .plain-txt > * + h5,
    .plain-txt > * + h6 {
      margin-top: 50px;
    }
  }

  @media screen and (min-width: 72em) and (max-width: 103.999em) {
    .plain-txt > * {
      margin-top: 30px;
    }

    .plain-txt p + p {
      margin-top: 10px;
    }

    .plain-txt > * + h3,
    .plain-txt > * + h4,
    .plain-txt > * + h5,
    .plain-txt > * + h6 {
      margin-top: 50px;
    }
  }

  @media screen and (min-width: 104em) {
    .plain-txt > * {
      margin-top: 30px;
    }

    .plain-txt p + p {
      margin-top: 10px;
    }

    .plain-txt > * + h3,
    .plain-txt > * + h4,
    .plain-txt > * + h5,
    .plain-txt > * + h6 {
      margin-top: 50px;
    }
  }

  .plain-txt table {
    display: table;
    min-width: auto;
    width: auto;
    table-layout: fixed;
    border-collapse: collapse;
    empty-cells: show;
  }

  @media screen and (max-width: 35.999em) {
    .plain-txt table {
      display: block;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar;
    }
  }

  .plain-txt table thead,
  .plain-txt table tbody {
    width: 100%;
  }

  .plain-txt th {
    font-weight: 600;
  }

  .plain-txt tr {
    width: 100%;
  }

  .plain-txt tr {
    display: table-row;
  }

  @media screen and (max-width: 25.999em) {
    .plain-txt tr {
      height: 50px;
    }
  }

  @media screen and (min-width: 26em) and (max-width: 35.999em) {
    .plain-txt tr {
      height: 35px;
    }
  }

  @media screen and (min-width: 36em) and (max-width: 51.999em) {
    .plain-txt tr {
      height: 35px;
    }
  }

  @media screen and (min-width: 52em) and (max-width: 71.999em) {
    .plain-txt tr {
      height: 45px;
    }
  }

  @media screen and (min-width: 72em) and (max-width: 103.999em) {
    .plain-txt tr {
      height: 50px;
    }
  }

  @media screen and (min-width: 104em) {
    .plain-txt tr {
      height: 50px;
    }
  }

  .plain-txt td,
  .plain-txt th {
    padding: 0px 15px;
    display: table-cell;
  }

  thead .plain-txt td,
  thead .plain-txt th {
    padding-top: 0;
  }

  .plain-txt tr:last-child {
    border: none;
  }

  .plain-txt td,
  .plain-txt th {
    text-align: left;
    vertical-align: middle;
  }

  tfoot .plain-txt td,
  tfoot .plain-txt th {
    padding-bottom: 0;
  }

  .plain-txt td:first-child,
  .plain-txt th:first-child {
    padding-left: 0;
  }

  .plain-txt blockquote {
    position: relative;
    font-weight: bold;
  }

  @media screen and (min-width: 36em) and (max-width: 51.999em) {
    .plain-txt blockquote {
      float: left;
      margin-right: 30px;
      margin-bottom: 40px;
    }
  }

  @media screen and (min-width: 52em) and (max-width: 71.999em) {
    .plain-txt blockquote {
      float: left;
      margin-right: 30px;
      margin-bottom: 50px;
    }
  }

  @media screen and (min-width: 72em) and (max-width: 103.999em) {
    .plain-txt blockquote {
      float: left;
      margin-right: 40px;
      margin-bottom: 50px;
    }
  }

  @media screen and (min-width: 104em) {
    .plain-txt blockquote {
      float: left;
      margin-right: 40px;
      margin-bottom: 50px;
    }
  }

  @media screen and (min-width: 36em) and (max-width: 51.999em) {
    .plain-txt blockquote {
      width: calc((99.99999% + 30px) * 2 / 8 - 30px);
    }
  }

  @media screen and (min-width: 52em) and (max-width: 71.999em) {
    .plain-txt blockquote {
      width: calc((99.99999% + 30px) * 4 / 9 - 30px);
      margin-left: calc((99.99999% + 30px) * -1 / 9);
    }
  }

  @media screen and (min-width: 72em) and (max-width: 103.999em) {
    .plain-txt blockquote {
      width: calc((99.99999% + 40px) * 3 / 9 - 40px);
      margin-left: calc((99.99999% + 40px) * -1 / 9);
    }
  }

  @media screen and (min-width: 104em) {
    .plain-txt blockquote {
      width: calc((99.99999% + 40px) * 3 / 9 - 40px);
      margin-left: calc((99.99999% + 40px) * -1 / 9);
    }
  }

  .plain-txt blockquote q {
    display: block;
  }

  .plain-txt blockquote q::before {
    display: block;
    content: "\00bb";
  }

  .plain-txt blockquote q::after {
    display: block;
    content: "\00ab";
  }

  .plain-txt blockquote cite {
    font-size: var(--font-size-mono-i1);
    line-height: var(--line-height-mono-i1);
    display: block;
    font-weight: normal;
    font-style: normal;
    text-transform: uppercase;
  }

  .tag-flow {
    flex-flow: wrap;
  }

  @media screen and (max-width: 25.999em) {
    .tag-flow {
      margin-top: calc(-1 * inner-y(xxs));
      margin-bottom: calc(-1 * inner-y(xxs));
    }

    .tag-flow > * {
      margin-right: calc(1 * 10px);
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  @media screen and (min-width: 26em) and (max-width: 35.999em) {
    .tag-flow {
      margin-top: calc(-1 * inner-y(xxs));
      margin-bottom: calc(-1 * inner-y(xxs));
    }

    .tag-flow > * {
      margin-right: calc(1 * 10px);
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  @media screen and (min-width: 36em) and (max-width: 51.999em) {
    .tag-flow {
      margin-top: calc(-1 * inner-y(xxs));
      margin-bottom: calc(-1 * inner-y(xxs));
    }

    .tag-flow > * {
      margin-right: calc(1 * 10px);
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  @media screen and (min-width: 52em) and (max-width: 71.999em) {
    .tag-flow {
      margin-top: calc(-1 * inner-y(xxs));
      margin-bottom: calc(-1 * inner-y(xxs));
    }

    .tag-flow > * {
      margin-right: calc(1 * 10px);
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  @media screen and (min-width: 72em) and (max-width: 103.999em) {
    .tag-flow {
      margin-top: calc(-1 * inner-y(xxs));
      margin-bottom: calc(-1 * inner-y(xxs));
    }

    .tag-flow > * {
      margin-right: calc(1 * 10px);
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  @media screen and (min-width: 104em) {
    .tag-flow {
      margin-top: calc(-1 * inner-y(xxs));
      margin-bottom: calc(-1 * inner-y(xxs));
    }

    .tag-flow > * {
      margin-right: calc(1 * 10px);
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  legend {
    padding: 0;
    display: block;
  }

  fieldset {
    border: 0;
    padding: 0;
    margin: 0;
    min-width: 0;
  }

  body:not(:-moz-handler-blocked) fieldset {
    display: block;
  }

  .fieldset:disabled a,
  .fieldset:disabled button,
  .fieldset:disabled input,
  .fieldset:disabled select,
  .fieldset:disabled textarea {
    pointer-events: none !important;
  }

  .field,
  [class^="field-"],
  [class*=" field-"] {
    display: block;
    position: relative;
  }

  .field input,
  .field textarea,
  [class^="field-"] input,
  [class^="field-"] textarea,
  [class*=" field-"] input,
  [class*=" field-"] textarea {
    display: block;
    width: 100%;
    padding: 0 15px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
    border: 3px solid #999;
  }

  .field input:focus,
  .field textarea:focus,
  [class^="field-"] input:focus,
  [class^="field-"] textarea:focus,
  [class*=" field-"] input:focus,
  [class*=" field-"] textarea:focus {
    outline: 0;
    border-color: #000;
  }

  .field input:-ms-input-placeholder,
  .field textarea:-ms-input-placeholder,
  [class^="field-"] input:-ms-input-placeholder,
  [class^="field-"] textarea:-ms-input-placeholder,
  [class*=" field-"] input:-ms-input-placeholder,
  [class*=" field-"] textarea:-ms-input-placeholder {
    color: #aaa;
  }

  .field input::-ms-input-placeholder,
  .field textarea::-ms-input-placeholder,
  [class^="field-"] input::-ms-input-placeholder,
  [class^="field-"] textarea::-ms-input-placeholder,
  [class*=" field-"] input::-ms-input-placeholder,
  [class*=" field-"] textarea::-ms-input-placeholder {
    color: #aaa;
  }

  .field input::placeholder,
  .field textarea::placeholder,
  [class^="field-"] input::placeholder,
  [class^="field-"] textarea::placeholder,
  [class*=" field-"] input::placeholder,
  [class*=" field-"] textarea::placeholder {
    color: #aaa;
  }

  .field input,
  [class^="field-"] input,
  [class*=" field-"] input {
    height: 50px;
  }

  .field textarea,
  [class^="field-"] textarea,
  [class*=" field-"] textarea {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .field input[type="checkbox"] + label,
  .field input[type="radio"] + label,
  [class^="field-"] input[type="checkbox"] + label,
  [class^="field-"] input[type="radio"] + label,
  [class*=" field-"] input[type="checkbox"] + label,
  [class*=" field-"] input[type="radio"] + label {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    display: flex;
    align-items: flex-start;
  }

  .field input[type="checkbox"] + label.mono-i1 .label-pre,
  .field input[type="radio"] + label.mono-i1 .label-pre,
  [class^="field-"] input[type="checkbox"] + label.mono-i1 .label-pre,
  [class^="field-"] input[type="radio"] + label.mono-i1 .label-pre,
  [class*=" field-"] input[type="checkbox"] + label.mono-i1 .label-pre,
  [class*=" field-"] input[type="radio"] + label.mono-i1 .label-pre {
    margin-top: 3px;
  }

  .field input[type="checkbox"] + label.sans-t2 .label-pre,
  .field input[type="radio"] + label.sans-t2 .label-pre,
  [class^="field-"] input[type="checkbox"] + label.sans-t2 .label-pre,
  [class^="field-"] input[type="radio"] + label.sans-t2 .label-pre,
  [class*=" field-"] input[type="checkbox"] + label.sans-t2 .label-pre,
  [class*=" field-"] input[type="radio"] + label.sans-t2 .label-pre {
    margin-top: 6px;
  }

  .field input[type="checkbox"] + label .label-pre,
  .field input[type="radio"] + label .label-pre,
  [class^="field-"] input[type="checkbox"] + label .label-pre,
  [class^="field-"] input[type="radio"] + label .label-pre,
  [class*=" field-"] input[type="checkbox"] + label .label-pre,
  [class*=" field-"] input[type="radio"] + label .label-pre {
    margin-top: 3px;
    position: relative;
    flex: 0 0 auto;
    width: 16px;
    height: 16px;
    margin-right: 1em;
    border: 3px solid #000;
    pointer-events: none;
  }

  .field input[type="checkbox"]:checked + label .label-pre,
  .field input[type="radio"]:checked + label .label-pre,
  [class^="field-"] input[type="checkbox"]:checked + label .label-pre,
  [class^="field-"] input[type="radio"]:checked + label .label-pre,
  [class*=" field-"] input[type="checkbox"]:checked + label .label-pre,
  [class*=" field-"] input[type="radio"]:checked + label .label-pre {
    background-color: #000;
  }

  .field input[type="checkbox"]:focus + label .label-pre,
  .field input[type="radio"]:focus + label .label-pre,
  [class^="field-"] input[type="checkbox"]:focus + label .label-pre,
  [class^="field-"] input[type="radio"]:focus + label .label-pre,
  [class*=" field-"] input[type="checkbox"]:focus + label .label-pre,
  [class*=" field-"] input[type="radio"]:focus + label .label-pre {
    box-shadow: 0 0 0 3px #cdcdcd;
  }

  .field input[type="radio"] + label .label-pre,
  [class^="field-"] input[type="radio"] + label .label-pre,
  [class*=" field-"] input[type="radio"] + label .label-pre {
    border-radius: 50%;
  }

  .field .field-pre,
  .field .field-post,
  [class^="field-"] .field-pre,
  [class^="field-"] .field-post,
  [class*=" field-"] .field-pre,
  [class*=" field-"] .field-post {
    position: absolute;
    bottom: 0;
    z-index: 1;
    pointer-events: none;
    color: #e6e6d7;
    padding: 0 15px;
  }

  .field .field-pre .icon,
  .field .field-post .icon,
  [class^="field-"] .field-pre .icon,
  [class^="field-"] .field-post .icon,
  [class*=" field-"] .field-pre .icon,
  [class*=" field-"] .field-post .icon {
    height: 60px !important;
  }

  .field .field-pre,
  [class^="field-"] .field-pre,
  [class*=" field-"] .field-pre {
    left: 0;
  }

  .field .field-post,
  [class^="field-"] .field-post,
  [class*=" field-"] .field-post {
    right: 0;
  }

  .field.field-hidden,
  [class^="field-"].field-hidden,
  [class*=" field-"].field-hidden {
    display: none;
  }

  .field.field-file input,
  [class^="field-"].field-file input,
  [class*=" field-"].field-file input {
    height: auto;
    border: none;
    padding: 0;
  }

  .field.field-scirocco,
  [class^="field-"].field-scirocco,
  [class*=" field-"].field-scirocco {
    --color-btn-bg: var(--color-scirocco);
    --color-btn-bg-active: var(--color-scirocco-darkened);
  }

  .field.field-satin,
  [class^="field-"].field-satin,
  [class*=" field-"].field-satin {
    --color-btn-bg: var(--color-satin);
    --color-btn-bg-active: var(--color-satin-darkened);
  }

  .field.field-gumleaf,
  [class^="field-"].field-gumleaf,
  [class*=" field-"].field-gumleaf {
    --color-btn-bg: var(--color-gumleaf);
    --color-btn-bg-active: var(--color-gumleaf-darkened);
  }

  .field.field-limette,
  [class^="field-"].field-limette,
  [class*=" field-"].field-limette {
    --color-btn-bg: var(--color-limette);
    --color-btn-bg-active: var(--color-limette-darkened);
  }

  .field.field-purple,
  [class^="field-"].field-purple,
  [class*=" field-"].field-purple {
    --color-btn-bg: var(--color-purple);
    --color-btn-bg-active: var(--color-purple-darkened);
  }

  .field.field-sage,
  [class^="field-"].field-sage,
  [class*=" field-"].field-sage {
    --color-btn-bg: var(--color-sage);
    --color-btn-bg-active: var(--color-sage-darkened);
  }

  .field.field-rust,
  [class^="field-"].field-rust,
  [class*=" field-"].field-rust {
    --color-btn-bg: var(--color-rust);
    --color-btn-bg-active: var(--color-rust-darkened);
  }

  .field.field-scirocco-contrast,
  [class^="field-"].field-scirocco-contrast,
  [class*=" field-"].field-scirocco-contrast {
    --color-btn-bg: var(--color-scirocco-contrast);
    --color-btn-bg-active: var(--color-scirocco-contrast-darkened);
  }

  .field.field-scirocco-darkened,
  [class^="field-"].field-scirocco-darkened,
  [class*=" field-"].field-scirocco-darkened {
    --color-btn-bg: var(--color-scirocco-darkened);
    --color-btn-bg-active: var(--color-scirocco-darkened-darkened);
  }

  .field.field-scirocco-lightened,
  [class^="field-"].field-scirocco-lightened,
  [class*=" field-"].field-scirocco-lightened {
    --color-btn-bg: var(--color-scirocco-lightened);
    --color-btn-bg-active: var(--color-scirocco-lightened-darkened);
  }

  .field.field-haze-contrast,
  [class^="field-"].field-haze-contrast,
  [class*=" field-"].field-haze-contrast {
    --color-btn-bg: var(--color-haze-contrast);
    --color-btn-bg-active: var(--color-haze-contrast-darkened);
  }

  .field.field-haze-darkened,
  [class^="field-"].field-haze-darkened,
  [class*=" field-"].field-haze-darkened {
    --color-btn-bg: var(--color-haze-darkened);
    --color-btn-bg-active: var(--color-haze-darkened-darkened);
  }

  .field.field-haze-lightened,
  [class^="field-"].field-haze-lightened,
  [class*=" field-"].field-haze-lightened {
    --color-btn-bg: var(--color-haze-lightened);
    --color-btn-bg-active: var(--color-haze-lightened-darkened);
  }

  .field.field-twilight-contrast,
  [class^="field-"].field-twilight-contrast,
  [class*=" field-"].field-twilight-contrast {
    --color-btn-bg: var(--color-twilight-contrast);
    --color-btn-bg-active: var(--color-twilight-contrast-darkened);
  }

  .field.field-twilight-darkened,
  [class^="field-"].field-twilight-darkened,
  [class*=" field-"].field-twilight-darkened {
    --color-btn-bg: var(--color-twilight-darkened);
    --color-btn-bg-active: var(--color-twilight-darkened-darkened);
  }

  .field.field-twilight-lightened,
  [class^="field-"].field-twilight-lightened,
  [class*=" field-"].field-twilight-lightened {
    --color-btn-bg: var(--color-twilight-lightened);
    --color-btn-bg-active: var(--color-twilight-lightened-darkened);
  }

  .field.field-satin-contrast,
  [class^="field-"].field-satin-contrast,
  [class*=" field-"].field-satin-contrast {
    --color-btn-bg: var(--color-satin-contrast);
    --color-btn-bg-active: var(--color-satin-contrast-darkened);
  }

  .field.field-satin-darkened,
  [class^="field-"].field-satin-darkened,
  [class*=" field-"].field-satin-darkened {
    --color-btn-bg: var(--color-satin-darkened);
    --color-btn-bg-active: var(--color-satin-darkened-darkened);
  }

  .field.field-satin-lightened,
  [class^="field-"].field-satin-lightened,
  [class*=" field-"].field-satin-lightened {
    --color-btn-bg: var(--color-satin-lightened);
    --color-btn-bg-active: var(--color-satin-lightened-darkened);
  }

  .field.field-gumleaf-contrast,
  [class^="field-"].field-gumleaf-contrast,
  [class*=" field-"].field-gumleaf-contrast {
    --color-btn-bg: var(--color-gumleaf-contrast);
    --color-btn-bg-active: var(--color-gumleaf-contrast-darkened);
  }

  .field.field-gumleaf-darkened,
  [class^="field-"].field-gumleaf-darkened,
  [class*=" field-"].field-gumleaf-darkened {
    --color-btn-bg: var(--color-gumleaf-darkened);
    --color-btn-bg-active: var(--color-gumleaf-darkened-darkened);
  }

  .field.field-gumleaf-lightened,
  [class^="field-"].field-gumleaf-lightened,
  [class*=" field-"].field-gumleaf-lightened {
    --color-btn-bg: var(--color-gumleaf-lightened);
    --color-btn-bg-active: var(--color-gumleaf-lightened-darkened);
  }

  .field.field-limette-contrast,
  [class^="field-"].field-limette-contrast,
  [class*=" field-"].field-limette-contrast {
    --color-btn-bg: var(--color-limette-contrast);
    --color-btn-bg-active: var(--color-limette-contrast-darkened);
  }

  .field.field-limette-darkened,
  [class^="field-"].field-limette-darkened,
  [class*=" field-"].field-limette-darkened {
    --color-btn-bg: var(--color-limette-darkened);
    --color-btn-bg-active: var(--color-limette-darkened-darkened);
  }

  .field.field-limette-lightened,
  [class^="field-"].field-limette-lightened,
  [class*=" field-"].field-limette-lightened {
    --color-btn-bg: var(--color-limette-lightened);
    --color-btn-bg-active: var(--color-limette-lightened-darkened);
  }

  .field.field-purple-contrast,
  [class^="field-"].field-purple-contrast,
  [class*=" field-"].field-purple-contrast {
    --color-btn-bg: var(--color-purple-contrast);
    --color-btn-bg-active: var(--color-purple-contrast-darkened);
  }

  .field.field-purple-darkened,
  [class^="field-"].field-purple-darkened,
  [class*=" field-"].field-purple-darkened {
    --color-btn-bg: var(--color-purple-darkened);
    --color-btn-bg-active: var(--color-purple-darkened-darkened);
  }

  .field.field-purple-lightened,
  [class^="field-"].field-purple-lightened,
  [class*=" field-"].field-purple-lightened {
    --color-btn-bg: var(--color-purple-lightened);
    --color-btn-bg-active: var(--color-purple-lightened-darkened);
  }

  .field.field-sage-contrast,
  [class^="field-"].field-sage-contrast,
  [class*=" field-"].field-sage-contrast {
    --color-btn-bg: var(--color-sage-contrast);
    --color-btn-bg-active: var(--color-sage-contrast-darkened);
  }

  .field.field-sage-darkened,
  [class^="field-"].field-sage-darkened,
  [class*=" field-"].field-sage-darkened {
    --color-btn-bg: var(--color-sage-darkened);
    --color-btn-bg-active: var(--color-sage-darkened-darkened);
  }

  .field.field-sage-lightened,
  [class^="field-"].field-sage-lightened,
  [class*=" field-"].field-sage-lightened {
    --color-btn-bg: var(--color-sage-lightened);
    --color-btn-bg-active: var(--color-sage-lightened-darkened);
  }

  .field.field-rust-contrast,
  [class^="field-"].field-rust-contrast,
  [class*=" field-"].field-rust-contrast {
    --color-btn-bg: var(--color-rust-contrast);
    --color-btn-bg-active: var(--color-rust-contrast-darkened);
  }

  .field.field-rust-darkened,
  [class^="field-"].field-rust-darkened,
  [class*=" field-"].field-rust-darkened {
    --color-btn-bg: var(--color-rust-darkened);
    --color-btn-bg-active: var(--color-rust-darkened-darkened);
  }

  .field.field-rust-lightened,
  [class^="field-"].field-rust-lightened,
  [class*=" field-"].field-rust-lightened {
    --color-btn-bg: var(--color-rust-lightened);
    --color-btn-bg-active: var(--color-rust-lightened-darkened);
  }

  .field.field-lite-contrast,
  [class^="field-"].field-lite-contrast,
  [class*=" field-"].field-lite-contrast {
    --color-btn-bg: var(--color-lite-contrast);
    --color-btn-bg-active: var(--color-lite-contrast-darkened);
  }

  .field.field-lite-darkened,
  [class^="field-"].field-lite-darkened,
  [class*=" field-"].field-lite-darkened {
    --color-btn-bg: var(--color-lite-darkened);
    --color-btn-bg-active: var(--color-lite-darkened-darkened);
  }

  .field.field-lite-lightened,
  [class^="field-"].field-lite-lightened,
  [class*=" field-"].field-lite-lightened {
    --color-btn-bg: var(--color-lite-lightened);
    --color-btn-bg-active: var(--color-lite-lightened-darkened);
  }

  .field.field-dark-contrast,
  [class^="field-"].field-dark-contrast,
  [class*=" field-"].field-dark-contrast {
    --color-btn-bg: var(--color-dark-contrast);
    --color-btn-bg-active: var(--color-dark-contrast-darkened);
  }

  .field.field-dark-darkened,
  [class^="field-"].field-dark-darkened,
  [class*=" field-"].field-dark-darkened {
    --color-btn-bg: var(--color-dark-darkened);
    --color-btn-bg-active: var(--color-dark-darkened-darkened);
  }

  .field.field-dark-lightened,
  [class^="field-"].field-dark-lightened,
  [class*=" field-"].field-dark-lightened {
    --color-btn-bg: var(--color-dark-lightened);
    --color-btn-bg-active: var(--color-dark-lightened-darkened);
  }

  *[dir="rtl"] .select-css {
    background-position:
      left 0.7em top 50%,
      0 0;
    padding: 0.6em 0.8em 0.5em 1.4em;
  }

  :root:lang(ar) .select-css,
  :root:lang(iw) .select-css {
    background-position:
      left 0.7em top 50%,
      0 0;
    padding: 0.6em 0.8em 0.5em 1.4em;
  }

  .field select,
  [class^="field-"] select,
  [class*=" field-"] select {
    display: block;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 16px;
    font-family: inherit;
    font-weight: inherit;
    line-height: inherit;
    height: 50px;
    padding: 0 15px;
    border-radius: 0;
    border: 3px solid #999;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    background-image: linear-gradient(to bottom, #fff 0%, #fff 100%);
    background-repeat: repeat;
    background-position: 0 0;
    background-size: 100%;
  }

  .field select::-ms-expand,
  [class^="field-"] select::-ms-expand,
  [class*=" field-"] select::-ms-expand {
    display: none;
  }

  .field select:hover,
  [class^="field-"] select:hover,
  [class*=" field-"] select:hover {
    border-color: #000;
  }

  .field select:focus,
  [class^="field-"] select:focus,
  [class*=" field-"] select:focus {
    outline: 0;
    outline: none;
    border-color: #000;
  }

  .field select option,
  [class^="field-"] select option,
  [class*=" field-"] select option {
    font-weight: normal;
  }

  .form-span,
  [class^="form-span-"],
  [class*=" form-span-"] {
    width: 100%;
  }

  .form-span-auto {
    flex: 1 1 0%;
  }

  .form-span-s {
    width: calc(99.99999% * 2 / 4);
    margin-left: calc(99.99999% * 0 / 4);
    margin-right: calc(99.99999% * 0 / 4);
  }

  @media screen and (min-width: 36em) {
    .form-span-s {
      width: calc(99.99999% * 2 / 8);
      margin-left: calc(99.99999% * 0 / 8);
      margin-right: calc(99.99999% * 0 / 8);
    }
  }

  @media screen and (min-width: 52em) {
    .form-span-s {
      width: calc(99.99999% * 2 / 12);
      margin-left: calc(99.99999% * 0 / 12);
      margin-right: calc(99.99999% * 0 / 12);
    }
  }

  @media screen and (min-width: 26em) {
    .form-span-m {
      width: calc(99.99999% * 2 / 4);
      margin-left: calc(99.99999% * 0 / 4);
      margin-right: calc(99.99999% * 0 / 4);
    }
  }

  @media screen and (min-width: 36em) {
    .form-span-m {
      width: calc(99.99999% * 3 / 8);
      margin-left: calc(99.99999% * 0 / 8);
      margin-right: calc(99.99999% * 0 / 8);
    }
  }

  @media screen and (min-width: 52em) {
    .form-span-m {
      width: calc(99.99999% * 5 / 12);
      margin-left: calc(99.99999% * 0 / 12);
      margin-right: calc(99.99999% * 0 / 12);
    }
  }

  .form-span-l {
    width: calc(99.99999% * 4 / 4);
    margin-left: calc(99.99999% * 0 / 4);
    margin-right: calc(99.99999% * 0 / 4);
  }

  @media screen and (min-width: 36em) {
    .form-span-l {
      width: calc(99.99999% * 4 / 8);
      margin-left: calc(99.99999% * 0 / 8);
      margin-right: calc(99.99999% * 0 / 8);
    }
  }

  @media screen and (min-width: 52em) {
    .form-span-l {
      width: calc(99.99999% * 6 / 12);
      margin-left: calc(99.99999% * 0 / 12);
      margin-right: calc(99.99999% * 0 / 12);
    }
  }

  @media screen and (min-width: 52em) {
    .form-span-xl {
      width: calc(99.99999% * 10 / 12);
      margin-left: calc(99.99999% * 0 / 12);
      margin-right: calc(99.99999% * 0 / 12);
    }
  }

  @media screen and (min-width: 52em) {
    .form-m {
      width: calc((99.99999% + 30px) * 10 / 12 - 30px);
      margin-left: calc((99.99999% + 30px) * 1 / 12);
    }
  }

  @media screen and (min-width: 52em) {
    .form-m .form-span-s {
      width: calc(99.99999% * 2 / 10);
      margin-left: calc(99.99999% * 0 / 10);
      margin-right: calc(99.99999% * 0 / 10);
    }
  }

  @media screen and (min-width: 52em) {
    .form-m .form-span-m {
      width: calc(99.99999% * 4 / 10);
      margin-left: calc(99.99999% * 0 / 10);
      margin-right: calc(99.99999% * 0 / 10);
    }
  }

  @media screen and (min-width: 52em) {
    .form-m .form-span-l {
      width: calc(99.99999% * 5 / 10);
      margin-left: calc(99.99999% * 0 / 10);
      margin-right: calc(99.99999% * 0 / 10);
    }
  }

  @media screen and (min-width: 52em) {
    .form-m .form-span-xl {
      width: calc(99.99999% * 8 / 10);
      margin-left: calc(99.99999% * 0 / 10);
      margin-right: calc(99.99999% * 0 / 10);
    }
  }

  @media screen and (min-width: 52em) {
    .heading-index-flow .heading {
      width: calc(99.99999% * 9 / 12);
      margin-left: calc(99.99999% * 1 / 12);
    }
  }

  @media screen and (min-width: 72em) {
    .heading-index-flow .heading {
      width: calc(99.99999% * 7 / 12);
      margin-left: calc(99.99999% * 1 / 12);
    }
  }

  @media screen and (min-width: 52em) {
    .heading-index-flow .index {
      width: calc(99.99999% * 5 / 12);
      margin-left: calc(99.99999% * 1 / 12);
    }
  }

  @media screen and (min-width: 72em) {
    .heading-index-flow .index {
      width: calc(99.99999% * 4 / 12);
      margin-left: calc(99.99999% * 0 / 12);
    }
  }

  .heading-index-flow .index .spacer {
    height: 5px;
  }

  @media screen and (min-width: 52em) {
    .span-content-center {
      width: calc((99.99999% + 30px) * 10 / 12 - 30px);
      margin-left: calc((99.99999% + 30px) * 1 / 12);
    }
  }

  @media screen and (min-width: 52em) {
    .span-content-left {
      width: calc((99.99999% + 30px) * 9 / 12 - 30px);
      margin-left: calc((99.99999% + 30px) * 1 / 12);
    }
  }

  @media screen and (min-width: 52em) {
    .article-content-figure {
      width: calc(99.99999% * 10 / 9);
      margin-left: calc(99.99999% * 0 / 9);
      margin-right: calc(99.99999% * -1 / 9);
    }
  }

  .lazy-video .video-button {
    height: 100%;
  }

  .lazy-video .video-button > button:focus {
    outline: 0;
    box-shadow: 0 0 0 3px #cdcdcd;
  }

  .lazy-video .video-button > iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .article-cover-img {
    box-shadow: 10px 10px 30px rgba(var(--color-dark), 0.25);
  }

  @media screen and (min-width: 52em) {
    .article-special {
      width: calc((99.99999% + 30px) * 11 / 12 - 30px);
      margin-left: calc((99.99999% + 30px) * 1 / 12);
    }
  }

  @media screen and (min-width: 36em) {
    .article-special .article-special-text {
      width: calc(99.99999% * 5 / 8);
    }
  }

  @media screen and (min-width: 52em) {
    .article-special .article-special-text {
      width: calc(99.99999% * 8 / 11);
      margin-left: calc(99.99999% * 0 / 11);
      margin-right: calc(99.99999% * 0 / 11);
    }
  }

  .article-special .article-special-illu {
    width: calc(99.99999% * 2 / 4);
  }

  @media screen and (min-width: 36em) {
    .article-special .article-special-illu {
      width: calc(99.99999% * 3 / 8);
    }
  }

  @media screen and (min-width: 52em) {
    .article-special .article-special-illu {
      width: calc(99.99999% * 3 / 11);
      margin-left: calc(99.99999% * 0 / 11);
      margin-right: calc(99.99999% * 0 / 11);
    }
  }

  @media screen and (min-width: 52em) {
    .article-source {
      width: calc((99.99999% + 30px) * 6 / 12 - 30px);
      margin-left: calc((99.99999% + 30px) * 1 / 12);
    }
  }

  .article-source .fig-img,
  .article-source .fig-cap {
    background-color: #eee;
    width: calc(99.99999% * 3 / 4);
  }

  .article-figure-slider {
    padding-top: 2rem;
  }

  .article-figure-slider .tester {
    bottom: 100%;
    left: 0;
    background-color: pink;
  }

  @media screen and (max-width: 25.999em) {
    .article-figure-slider .tester {
      width: calc((100% - 40px) * 0.75);
      margin-left: calc(((100% - 40px) * 0.125) + 20px);
    }
  }

  @media screen and (min-width: 26em) and (max-width: 35.999em) {
    .article-figure-slider .tester {
      width: calc((100% - 40px) * 0.75);
      margin-left: calc(((100% - 40px) * 0.125) + 20px);
    }
  }

  @media screen and (min-width: 36em) and (max-width: 51.999em) {
    .article-figure-slider .tester {
      width: calc(
        (100% - calc((15.625vw + -3.75rem) + (15.625vw + -3.75rem))) * 0.75
      );
      margin-left: calc(
        ((100% - calc((15.625vw + -3.75rem) + (15.625vw + -3.75rem))) * 0.125) +
          (15.625vw + -3.75rem)
      );
    }
  }

  @media screen and (min-width: 52em) and (max-width: 71.999em) {
    .article-figure-slider .tester {
      width: calc(
        (100% - calc((15.625vw + -3.75rem) + (15.625vw + -3.75rem))) * 0.66667
      );
      margin-left: calc(
        ((100% - calc((15.625vw + -3.75rem) + (15.625vw + -3.75rem))) * 0.16667) +
          (15.625vw + -3.75rem)
      );
    }
  }

  @media screen and (min-width: 72em) and (max-width: 103.999em) {
    .article-figure-slider .tester {
      width: calc(
        (100% - calc((23.4375vw + -8.75rem) + (23.4375vw + -8.75rem))) * 0.66667
      );
      margin-left: calc(
        (
            (100% - calc((23.4375vw + -8.75rem) + (23.4375vw + -8.75rem))) *
              0.16667
          ) + (23.4375vw + -8.75rem)
      );
    }
  }

  @media screen and (min-width: 104em) {
    .article-figure-slider .tester {
      width: calc((100% - 500px) * 0.66667);
      margin-left: calc(((100% - 500px) * 0.16667) + 250px);
    }
  }

  .article-figure-slider .flickity-viewport {
    transition: height 200ms ease;
  }

  .article-figure-slider .flickity-flow.focus-visible {
    outline: 0;
    box-shadow: 0 0 0 3px #cdcdcd;
  }

  .article-figure-slider .flickity-flow .flickity-page-dots {
    bottom: calc(100% + 30px);
    left: 0;
    z-index: -1;
  }

  @media screen and (max-width: 25.999em) {
    .article-figure-slider .flickity-flow .flickity-page-dots {
      width: calc((100% - 40px) * 0.75);
      margin-left: calc(((100% - 40px) * 0.125) + 20px);
    }
  }

  @media screen and (min-width: 26em) and (max-width: 35.999em) {
    .article-figure-slider .flickity-flow .flickity-page-dots {
      width: calc((100% - 40px) * 0.75);
      margin-left: calc(((100% - 40px) * 0.125) + 20px);
    }
  }

  @media screen and (min-width: 36em) and (max-width: 51.999em) {
    .article-figure-slider .flickity-flow .flickity-page-dots {
      width: calc(
        (100% - calc((15.625vw + -3.75rem) + (15.625vw + -3.75rem))) * 0.75
      );
      margin-left: calc(
        ((100% - calc((15.625vw + -3.75rem) + (15.625vw + -3.75rem))) * 0.125) +
          (15.625vw + -3.75rem)
      );
    }
  }

  @media screen and (min-width: 52em) and (max-width: 71.999em) {
    .article-figure-slider .flickity-flow .flickity-page-dots {
      width: calc(
        (100% - calc((15.625vw + -3.75rem) + (15.625vw + -3.75rem))) * 0.66667
      );
      margin-left: calc(
        ((100% - calc((15.625vw + -3.75rem) + (15.625vw + -3.75rem))) * 0.16667) +
          (15.625vw + -3.75rem)
      );
    }
  }

  @media screen and (min-width: 72em) and (max-width: 103.999em) {
    .article-figure-slider .flickity-flow .flickity-page-dots {
      width: calc(
        (100% - calc((23.4375vw + -8.75rem) + (23.4375vw + -8.75rem))) * 0.66667
      );
      margin-left: calc(
        (
            (100% - calc((23.4375vw + -8.75rem) + (23.4375vw + -8.75rem))) *
              0.16667
          ) + (23.4375vw + -8.75rem)
      );
    }
  }

  @media screen and (min-width: 104em) {
    .article-figure-slider .flickity-flow .flickity-page-dots {
      width: calc((100% - 500px) * 0.66667);
      margin-left: calc(((100% - 500px) * 0.16667) + 250px);
    }
  }

  .article-figure-slider .flickity-flow .flickity-page-dots .dot {
    width: 8px;
    height: 8px;
    margin: 0 8px;
  }

  .article-figure-slider .flickity-flow .flickity-prev-next-button {
    top: calc(-0.5rem - 30px);
    width: 3rem;
    height: 3rem;
    border-radius: 0%;
  }

  @media screen and (max-width: 35.999em) {
    .article-figure-slider .flickity-flow .flickity-prev-next-button {
      display: none;
    }
  }

  @media screen and (min-width: 36em) and (max-width: 51.999em) {
    .article-figure-slider .flickity-flow .flickity-prev-next-button.previous {
      left: calc(
        (
            (
                (100% - calc((15.625vw + -3.75rem) + (15.625vw + -3.75rem))) *
                  0.125
              ) + (15.625vw + -3.75rem)
          ) + 10px
      ) !important;
    }

    .article-figure-slider .flickity-flow .flickity-prev-next-button.next {
      right: calc(
        (
            (
                (100% - calc((15.625vw + -3.75rem) + (15.625vw + -3.75rem))) *
                  0.125
              ) + (15.625vw + -3.75rem)
          ) + 10px
      ) !important;
    }
  }

  @media screen and (min-width: 52em) and (max-width: 71.999em) {
    .article-figure-slider .flickity-flow .flickity-prev-next-button.previous {
      left: calc(
        (
            (
                (100% - calc((15.625vw + -3.75rem) + (15.625vw + -3.75rem))) *
                  0.16667
              ) + (15.625vw + -3.75rem)
          ) + 10px
      ) !important;
    }

    .article-figure-slider .flickity-flow .flickity-prev-next-button.next {
      right: calc(
        (
            (
                (100% - calc((15.625vw + -3.75rem) + (15.625vw + -3.75rem))) *
                  0.16667
              ) + (15.625vw + -3.75rem)
          ) + 10px
      ) !important;
    }
  }

  @media screen and (min-width: 72em) and (max-width: 103.999em) {
    .article-figure-slider .flickity-flow .flickity-prev-next-button.previous {
      left: calc(
        (
            (
                (100% - calc((23.4375vw + -8.75rem) + (23.4375vw + -8.75rem))) *
                  0.16667
              ) + (23.4375vw + -8.75rem)
          ) + 10px
      ) !important;
    }

    .article-figure-slider .flickity-flow .flickity-prev-next-button.next {
      right: calc(
        (
            (
                (100% - calc((23.4375vw + -8.75rem) + (23.4375vw + -8.75rem))) *
                  0.16667
              ) + (23.4375vw + -8.75rem)
          ) + 10px
      ) !important;
    }
  }

  @media screen and (min-width: 104em) {
    .article-figure-slider .flickity-flow .flickity-prev-next-button.previous {
      left: calc((((100% - 500px) * 0.16667) + 250px) + 10px) !important;
    }

    .article-figure-slider .flickity-flow .flickity-prev-next-button.next {
      right: calc((((100% - 500px) * 0.16667) + 250px) + 10px) !important;
    }
  }

  @media screen and (max-width: 25.999em) {
    .article-figure-slider {
      transform: translateX(calc((99.99999% + 30px) * -1 / 8));
    }

    .article-figure-slider .cell-next {
      transform-origin: 15px top;
    }

    .article-figure-slider .cell-prev {
      transform-origin: calc(100% - 15px) top;
    }

    .article-figure-slider .cell {
      width: calc((100% - 40px) * 0.75);
    }
  }

  @media screen and (min-width: 26em) and (max-width: 35.999em) {
    .article-figure-slider {
      transform: translateX(calc((99.99999% + 30px) * -1 / 8));
    }

    .article-figure-slider .cell-next {
      transform-origin: 15px top;
    }

    .article-figure-slider .cell-prev {
      transform-origin: calc(100% - 15px) top;
    }

    .article-figure-slider .cell {
      width: calc((100% - 40px) * 0.75);
    }
  }

  @media screen and (min-width: 36em) and (max-width: 51.999em) {
    .article-figure-slider {
      transform: translateX(calc((99.99999% + 30px) * -1 / 8));
    }

    .article-figure-slider .cell-next {
      transform-origin: 15px top;
    }

    .article-figure-slider .cell-prev {
      transform-origin: calc(100% - 15px) top;
    }

    .article-figure-slider .cell {
      width: calc(
        (100% - ((15.625vw + -3.75rem) + (15.625vw + -3.75rem))) * 0.75
      );
    }
  }

  @media screen and (min-width: 52em) and (max-width: 71.999em) {
    .article-figure-slider {
      transform: translateX(calc((99.99999% + 30px) * -1 / 12));
    }

    .article-figure-slider .cell-next {
      transform-origin: 15px top;
    }

    .article-figure-slider .cell-prev {
      transform-origin: calc(100% - 15px) top;
    }

    .article-figure-slider .cell {
      width: calc(
        (100% - ((15.625vw + -3.75rem) + (15.625vw + -3.75rem))) * 0.66667
      );
    }
  }

  @media screen and (min-width: 72em) and (max-width: 103.999em) {
    .article-figure-slider {
      transform: translateX(calc((99.99999% + 40px) * -1 / 12));
    }

    .article-figure-slider .cell-next {
      transform-origin: 20px top;
    }

    .article-figure-slider .cell-prev {
      transform-origin: calc(100% - 20px) top;
    }

    .article-figure-slider .cell {
      width: calc(
        (100% - ((23.4375vw + -8.75rem) + (23.4375vw + -8.75rem))) * 0.66667
      );
    }
  }

  @media screen and (min-width: 104em) {
    .article-figure-slider {
      transform: translateX(calc((99.99999% + 40px) * -1 / 12));
    }

    .article-figure-slider .cell-next {
      transform-origin: 20px top;
    }

    .article-figure-slider .cell-prev {
      transform-origin: calc(100% - 20px) top;
    }

    .article-figure-slider .cell {
      width: calc((100% - 500px) * 0.66667);
    }
  }

  @media screen and (min-width: 36em) {
    .article-figure-1up-tall {
      width: calc((99.99999% + 30px) * 6 / 8 - 30px);
      margin-left: calc((99.99999% + 30px) * 1 / 8);
    }
  }

  @media screen and (min-width: 52em) {
    .article-figure-1up-tall {
      width: calc((99.99999% + 30px) * 8 / 12 - 30px);
      margin-left: calc((99.99999% + 30px) * 2 / 12);
    }
  }

  @media screen and (min-width: 52em) {
    .article-figure-2up .fig {
      width: calc(99.99999% * 6 / 12);
    }
  }

  @media screen and (min-width: 36em) {
    .article-figure-2up-left .fig-left {
      width: calc(99.99999% * 3 / 8);
    }
  }

  @media screen and (min-width: 52em) {
    .article-figure-2up-left .fig-left {
      width: calc(99.99999% * 5 / 12);
    }
  }

  @media screen and (min-width: 36em) {
    .article-figure-2up-left .fig-right {
      width: calc(99.99999% * 5 / 8);
    }
  }

  @media screen and (min-width: 52em) {
    .article-figure-2up-left .fig-right {
      width: calc(99.99999% * 7 / 12);
    }
  }

  @media screen and (min-width: 36em) {
    .article-figure-2up-right .fig-right {
      width: calc(99.99999% * 3 / 8);
    }
  }

  @media screen and (min-width: 52em) {
    .article-figure-2up-right .fig-right {
      width: calc(99.99999% * 5 / 12);
    }
  }

  @media screen and (min-width: 36em) {
    .article-figure-2up-right .fig-left {
      width: calc(99.99999% * 5 / 8);
    }
  }

  @media screen and (min-width: 52em) {
    .article-figure-2up-right .fig-left {
      width: calc(99.99999% * 7 / 12);
    }
  }

  body.has-modal {
    overflow: hidden;
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }

  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }

  .modal .modal-toggle button {
    color: inherit;
  }

  .modal .modal-toggle button:focus {
    outline: 0;
  }

  .modal .modal-toggle button .bars {
    display: block;
    width: 25px;
    height: 25px;
    position: relative;
  }

  .modal .modal-toggle button .bars:before,
  .modal .modal-toggle button .bars:after {
    width: 100%;
    height: 3px;
    background-color: currentColor;
    content: "";
    display: block;
    position: absolute;
    transition:
      transform 100ms ease,
      background-color 100ms ease;
    transform-origin: 50% 50%;
  }

  .modal .modal-toggle button .bars:after {
    transform: translateY(17px) rotate(0deg);
  }

  .modal .modal-toggle button .bars:before {
    transform: translateY(5px) rotate(0deg);
  }

  .modal .modal-toggle button[aria-expanded~="true"] .bars:after {
    transform: translateY(11px) rotate(-45deg);
  }

  .modal .modal-toggle button[aria-expanded~="true"] .bars:before {
    transform: translateY(11px) rotate(45deg);
  }

  .flickity-play-button-container {
    display: inline-block;
    margin: 0 32px;
  }

  .flickity-play-button {
    border: 0;
    background: transparent;
    box-sizing: border-box;
    width: 0;
    height: 16px;
    border-color: transparent transparent transparent currentColor;
    transition: 100ms all ease;
    cursor: pointer;
    border-style: solid;
    border-width: 8px 0 8px 16px;
  }

  .flickity-play-button.paused {
    border-style: double;
    border-width: 0px 0 0px 16px;
  }

  .banner-start-monads .flickity-button .flickity-button-icon {
    display: none;
  }

  .banner-start-monads .flickity-prev-next-button {
    top: 0 !important;
    height: 100% !important;
    transform: none !important;
  }

  @media screen and (max-width: 35.999em) {
    .banner-start-monads .flickity-prev-next-button {
      display: none;
    }
  }

  .banner-start-monads .flickity-prev-next-button.previous {
    left: 0 !important;
  }

  .banner-start-monads .flickity-prev-next-button.next {
    right: 0 !important;
  }

  @media screen and (min-width: 36em) and (max-width: 51.999em) {
    .banner-start-monads .flickity-prev-next-button {
      width: calc(
        (15.625vw + -3.75rem) + ((99.99999% + 30px) * 1 / 8 - 30px)
      ) !important;
    }
  }

  @media screen and (min-width: 52em) and (max-width: 71.999em) {
    .banner-start-monads .flickity-prev-next-button {
      width: calc(
        (15.625vw + -3.75rem) + ((99.99999% + 30px) * 1 / 12 - 30px)
      ) !important;
    }
  }

  @media screen and (min-width: 72em) and (max-width: 103.999em) {
    .banner-start-monads .flickity-prev-next-button {
      width: calc(
        (23.4375vw + -8.75rem) + ((99.99999% + 40px) * 1 / 12 - 40px)
      ) !important;
    }
  }

  @media screen and (min-width: 104em) {
    .banner-start-monads .flickity-prev-next-button {
      width: calc(250px + ((99.99999% + 40px) * 1 / 12 - 40px)) !important;
    }
  }

  .banner-start-monads .flickity-page-dots {
    bottom: -40px;
  }

  .banner-start-monads .controls {
    width: calc((99.99999% + 30px) * 1 / 4 - 30px);
    margin-left: calc((99.99999% + 30px) * 3 / 4);
  }

  @media screen and (min-width: 36em) {
    .banner-start-monads .controls {
      width: calc((99.99999% + 30px) * 2 / 8 - 30px);
      margin-left: calc((99.99999% + 30px) * 6 / 8);
    }
  }

  @media screen and (min-width: 52em) {
    .banner-start-monads .controls {
      width: calc((99.99999% + 30px) * 3 / 12 - 30px);
      margin-left: calc((99.99999% + 30px) * 8 / 12);
    }
  }

  @media screen and (min-width: 72em) {
    .banner-start-monads .controls {
      width: calc((99.99999% + 40px) * 3 / 12 - 40px);
      margin-left: calc((99.99999% + 40px) * 8 / 12);
    }
  }

  @media screen and (max-width: 25.999em) {
    .banner-start-monads .cell {
      width: calc(100% - 40px);
    }
  }

  @media screen and (min-width: 26em) and (max-width: 35.999em) {
    .banner-start-monads .cell {
      width: calc(100% - 40px);
    }
  }

  @media screen and (min-width: 36em) and (max-width: 51.999em) {
    .banner-start-monads .cell {
      width: calc(100% - ((15.625vw + -3.75rem) + (15.625vw + -3.75rem)));
    }
  }

  @media screen and (min-width: 52em) and (max-width: 71.999em) {
    .banner-start-monads .cell {
      width: calc(100% - ((15.625vw + -3.75rem) + (15.625vw + -3.75rem)));
    }
  }

  @media screen and (min-width: 72em) and (max-width: 103.999em) {
    .banner-start-monads .cell {
      width: calc(100% - ((23.4375vw + -8.75rem) + (23.4375vw + -8.75rem)));
    }
  }

  @media screen and (min-width: 104em) {
    .banner-start-monads .cell {
      width: calc(100% - 500px);
    }
  }

  .banner-start-monads .monad-solid {
    opacity: 0.9;
  }

  .banner-start-monads .hold-image {
    will-change: transform;
    transform: translate3d(0, 0, 0);
  }

  .banner-start-monads .mini-monad {
    z-index: 1;
    width: calc((99.99999% + 30px) * 2 / 4 - 30px);
  }

  @media screen and (min-width: 36em) {
    .banner-start-monads .mini-monad {
      width: calc((99.99999% + 30px) * 2 / 6 - 30px);
      margin-left: calc((99.99999% + 30px) * 0 / 6);
      margin-right: calc((99.99999% + 30px) * 0 / 6);
    }
  }

  @media screen and (min-width: 52em) {
    .banner-start-monads .mini-monad {
      width: calc((99.99999% + 30px) * 3 / 7 - 30px);
      margin-left: calc((99.99999% + 30px) * 0 / 7);
      margin-right: calc((99.99999% + 30px) * 0 / 7);
    }
  }

  .banner-start-monads .mini-text {
    z-index: 2;
    width: 100%;
    height: 90%;
  }

  @media screen and (min-width: 36em) {
    .banner-start-monads .slide-illu-inner {
      width: calc(99.99999% * 6 / 8);
    }
  }

  @media screen and (min-width: 52em) {
    .banner-start-monads .slide-illu-inner {
      width: calc(99.99999% * 7 / 12);
    }
  }

  .banner-start-monads .slide-text {
    will-change: opacity;
    opacity: 1;
    z-index: -1;
  }

  @media screen and (min-width: 52em) {
    .banner-start-monads .slide-text-inner {
      width: calc(99.99999% * 4 / 12);
      margin-left: calc(99.99999% * 7 / 12);
    }
  }

  @media screen and (min-width: 52em) {
    .banner-start-monads .slide-text {
      margin-top: 0 !important;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .banner-theme-monads .flickity-button .flickity-button-icon {
    display: none;
  }

  .banner-theme-monads .flickity-prev-next-button {
    top: 0 !important;
    height: 100% !important;
    transform: none !important;
  }

  @media screen and (max-width: 35.999em) {
    .banner-theme-monads .flickity-prev-next-button {
      display: none;
    }
  }

  .banner-theme-monads .flickity-prev-next-button.previous {
    left: 0 !important;
  }

  .banner-theme-monads .flickity-prev-next-button.next {
    right: 0 !important;
  }

  @media screen and (min-width: 36em) and (max-width: 51.999em) {
    .banner-theme-monads .flickity-prev-next-button {
      width: calc(15.625vw + -3.75rem) !important;
    }
  }

  @media screen and (min-width: 52em) and (max-width: 71.999em) {
    .banner-theme-monads .flickity-prev-next-button {
      width: calc(15.625vw + -3.75rem) !important;
    }
  }

  @media screen and (min-width: 72em) and (max-width: 103.999em) {
    .banner-theme-monads .flickity-prev-next-button {
      width: calc(23.4375vw + -8.75rem) !important;
    }
  }

  @media screen and (min-width: 104em) {
    .banner-theme-monads .flickity-prev-next-button {
      width: 250px !important;
    }
  }

  .banner-theme-monads .flickity-page-dots {
    bottom: -40px;
  }

  @media screen and (max-width: 25.999em) {
    .banner-theme-monads .cell {
      width: calc(100% - 40px);
    }
  }

  @media screen and (min-width: 26em) and (max-width: 35.999em) {
    .banner-theme-monads .cell {
      width: calc(100% - 40px);
    }
  }

  @media screen and (min-width: 36em) and (max-width: 51.999em) {
    .banner-theme-monads .cell {
      width: calc(100% - ((15.625vw + -3.75rem) + (15.625vw + -3.75rem)));
    }
  }

  @media screen and (min-width: 52em) and (max-width: 71.999em) {
    .banner-theme-monads .cell {
      width: calc(100% - ((15.625vw + -3.75rem) + (15.625vw + -3.75rem)));
    }
  }

  @media screen and (min-width: 72em) and (max-width: 103.999em) {
    .banner-theme-monads .cell {
      width: calc(100% - ((23.4375vw + -8.75rem) + (23.4375vw + -8.75rem)));
    }
  }

  @media screen and (min-width: 104em) {
    .banner-theme-monads .cell {
      width: calc(100% - 500px);
    }
  }

  .banner-theme-monads .monad-solid {
    opacity: 0.9;
  }

  .banner-theme-monads .hold-image {
    transform: translate3d(0, 0, 0);
  }

  .banner-theme-monads .hold-solid,
  .banner-theme-monads .slide-text {
    opacity: 1;
  }

  @media screen and (min-width: 36em) {
    .banner-theme-monads .hold-image {
      width: calc(99.99999% * 5 / 8);
      margin-left: calc(99.99999% * 0 / 8);
      margin-right: calc(99.99999% * 0 / 8);
    }
  }

  @media screen and (min-width: 52em) {
    .banner-theme-monads .hold-image {
      width: calc(99.99999% * 7 / 12);
    }
  }

  @media screen and (min-width: 52em) {
    .banner-theme-monads .hold-solid {
      width: calc(99.99999% * 7 / 12);
      margin-left: calc(99.99999% * -2 / 12);
    }
  }

  @media screen and (min-width: 52em) {
    .banner-theme-monads .slide-text {
      margin-top: 0 !important;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    .banner-theme-monads .headings {
      position: relative;
      left: calc(var(--inner-x) * -1);
    }
  }

  @media screen and (min-width: 52em) {
    .banner-theme-monads .slide-text-inner {
      width: calc(99.99999% * 4 / 12);
      margin-left: calc(99.99999% * 7 / 12);
    }
  }

  @media screen and (min-width: 52em) {
    .banner-monads-super-txt {
      width: calc((99.99999% + 30px) * 14 / 12 - 30px);
      margin-left: calc((99.99999% + 30px) * -1 / 12);
      margin-right: calc((99.99999% + 30px) * -1 / 12);
    }
  }

  @media screen and (min-width: 72em) {
    .banner-monads-super-txt {
      width: calc((99.99999% + 40px) * 14 / 12 - 40px);
      margin-left: calc((99.99999% + 40px) * -1 / 12);
      margin-right: calc((99.99999% + 40px) * -1 / 12);
    }
  }

  .banner-monads-super-txt .monad-solid {
    opacity: 0.9;
  }

  .banner-monads-super-txt .monad-1 {
    display: none;
  }

  @media screen and (min-width: 36em) {
    .banner-monads-super-txt .monad-1 {
      display: block;
    }
  }

  .banner-monads-super-txt .monad-0,
  .banner-monads-super-txt .monad-2,
  .banner-monads-super-txt .monad-3 {
    width: calc(99.99999% * 3 / 4);
    margin-left: calc(99.99999% * 0.5 / 4);
  }

  @media screen and (min-width: 36em) {
    .banner-monads-super-txt .monad-0,
    .banner-monads-super-txt .monad-2,
    .banner-monads-super-txt .monad-3 {
      width: calc(99.99999% * 5 / 8);
      margin-left: calc(99.99999% * 0 / 8);
      margin-right: calc(99.99999% * -1 / 8);
    }
  }

  @media screen and (min-width: 52em) {
    .banner-monads-super-txt .monad-0,
    .banner-monads-super-txt .monad-2,
    .banner-monads-super-txt .monad-3 {
      width: calc(99.99999% * 7 / 14);
      margin-left: calc(99.99999% * 0 / 14);
      margin-right: calc(99.99999% * 0 / 14);
    }
  }

  .banner-monads-super-txt .monad-1 {
    width: calc(99.99999% * 3 / 4);
    margin-left: calc(99.99999% * 0.5 / 4);
  }

  @media screen and (min-width: 36em) {
    .banner-monads-super-txt .monad-1 {
      width: calc(99.99999% * 5 / 8);
      margin-left: calc(99.99999% * -1 / 8);
      margin-right: calc(99.99999% * 0 / 8);
    }
  }

  @media screen and (min-width: 52em) {
    .banner-monads-super-txt .monad-1 {
      width: calc(99.99999% * 7 / 14);
      margin-left: calc(99.99999% * -1 / 14);
      margin-right: calc(99.99999% * 0 / 14);
    }
  }

  @media screen and (max-width: 35.999em) {
    .banner-monads-super-txt .monad-0 {
      transform: translateX(calc(30px - 100%));
    }

    .banner-monads-super-txt .monad-3 {
      transform: translateX(calc(75% - 30px));
    }
  }

  @media screen and (min-width: 36em) and (max-width: 51.999em) {
    .banner-monads-super-txt .monad-0 {
      display: none;
      transform: translateX(calc(30px - 100%));
    }

    .banner-monads-super-txt .monad-1 {
      transform: translateX(30px);
    }

    .banner-monads-super-txt .monad-3 {
      display: none;
      transform: translateX(calc(66.66667% - 30px));
    }
  }

  @media screen and (min-width: 52em) and (max-width: 71.999em) {
    .banner-monads-super-txt .monad-0 {
      transform: translateX(calc(30px - 85.71429%));
    }

    .banner-monads-super-txt .monad-1 {
      transform: translateX(30px);
    }

    .banner-monads-super-txt .monad-3 {
      transform: translateX(71.42857%);
    }
  }

  @media screen and (min-width: 72em) and (max-width: 103.999em) {
    .banner-monads-super-txt .monad-0 {
      transform: translateX(calc(40px - 85.71429%));
    }

    .banner-monads-super-txt .monad-1 {
      transform: translateX(40px);
    }

    .banner-monads-super-txt .monad-3 {
      transform: translateX(71.42857%);
    }
  }

  @media screen and (min-width: 104em) {
    .banner-monads-super-txt .monad-0 {
      transform: translateX(calc(40px - 85.71429%));
    }

    .banner-monads-super-txt .monad-1 {
      transform: translateX(40px);
    }

    .banner-monads-super-txt .monad-3 {
      transform: translateX(71.42857%);
    }
  }

  @media screen and (min-width: 36em) {
    .banner-monads-super-txt .title {
      width: calc(99.99999% * 4 / 8);
      margin-left: calc(99.99999% * 0 / 8);
      margin-right: calc(99.99999% * 0 / 8);
    }
  }

  @media screen and (min-width: 52em) {
    .banner-monads-super-txt .title {
      width: calc(99.99999% * 5 / 14);
      margin-left: calc(99.99999% * 1 / 14);
      margin-right: calc(99.99999% * 0 / 14);
    }
  }

  @media screen and (min-width: 36em) {
    .banner-monads-super-txt .title {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 90%;
      z-index: 1;
      display: flex;
      align-items: center;
    }
  }

  @media screen and (min-width: 52em) {
    .banner-monads-super-img {
      width: calc((99.99999% + 30px) * 14 / 12 - 30px);
      margin-left: calc((99.99999% + 30px) * -1 / 12);
      margin-right: calc((99.99999% + 30px) * -1 / 12);
    }
  }

  @media screen and (min-width: 72em) {
    .banner-monads-super-img {
      width: calc((99.99999% + 40px) * 14 / 12 - 40px);
      margin-left: calc((99.99999% + 40px) * -1 / 12);
      margin-right: calc((99.99999% + 40px) * -1 / 12);
    }
  }

  .banner-monads-super-img .monad-0 {
    display: none;
  }

  @media screen and (min-width: 36em) {
    .banner-monads-super-img .monad-0 {
      display: block;
    }
  }

  .banner-monads-super-img .monad-0,
  .banner-monads-super-img .monad-3 {
    width: calc(99.99999% * 3 / 4);
    margin-left: calc(99.99999% * 0.5 / 4);
  }

  @media screen and (min-width: 36em) {
    .banner-monads-super-img .monad-0,
    .banner-monads-super-img .monad-3 {
      width: calc(99.99999% * 5 / 8);
      margin-left: calc(99.99999% * 0 / 8);
      margin-right: calc(99.99999% * 0 / 8);
    }
  }

  @media screen and (min-width: 52em) {
    .banner-monads-super-img .monad-0,
    .banner-monads-super-img .monad-3 {
      width: calc(99.99999% * 7 / 14);
      margin-left: calc(99.99999% * 0 / 14);
      margin-right: calc(99.99999% * 0 / 14);
    }
  }

  .banner-monads-super-img .monad-1 {
    width: calc(99.99999% * 3 / 4);
    margin-left: calc(99.99999% * -1.5 / 4);
  }

  @media screen and (min-width: 36em) {
    .banner-monads-super-img .monad-1 {
      width: calc(99.99999% * 5 / 8);
      margin-left: calc(99.99999% * 0 / 8);
      margin-right: calc(99.99999% * -1 / 8);
    }
  }

  @media screen and (min-width: 52em) {
    .banner-monads-super-img .monad-1 {
      width: calc(99.99999% * 7 / 14);
      margin-left: calc(99.99999% * 1 / 14);
      margin-right: calc(99.99999% * 0 / 14);
    }
  }

  .banner-monads-super-img .monad-2 {
    width: calc(99.99999% * 3 / 4);
    margin-left: calc(99.99999% * -1 / 4);
  }

  @media screen and (min-width: 36em) {
    .banner-monads-super-img .monad-2 {
      width: calc(99.99999% * 5 / 8);
      margin-left: calc(99.99999% * -1 / 8);
      margin-right: calc(99.99999% * 0 / 8);
    }
  }

  @media screen and (min-width: 52em) {
    .banner-monads-super-img .monad-2 {
      width: calc(99.99999% * 7 / 14);
      margin-left: calc(99.99999% * -2 / 14);
      margin-right: calc(99.99999% * 0 / 14);
    }
  }

  @media screen and (max-width: 35.999em) {
    .banner-monads-super-img .monad-0 {
      transform: translateX(calc(30px - 83.33333%));
    }

    .banner-monads-super-img .monad-3 {
      transform: translateX(calc(83.33333% - 30px));
    }
  }

  @media screen and (min-width: 36em) and (max-width: 51.999em) {
    .banner-monads-super-img .monad-0 {
      transform: translateX(calc(30px - 100%));
    }

    .banner-monads-super-img .monad-3 {
      transform: translateX(calc(100% - 30px));
    }
  }

  @media screen and (min-width: 52em) and (max-width: 71.999em) {
    .banner-monads-super-img .monad-0 {
      transform: translateX(calc(30px - 85.71429%));
    }

    .banner-monads-super-img .monad-3 {
      transform: translateX(calc(85.71429% - 30px));
    }
  }

  @media screen and (min-width: 72em) and (max-width: 103.999em) {
    .banner-monads-super-img .monad-0 {
      transform: translateX(calc(40px - 85.71429%));
    }

    .banner-monads-super-img .monad-3 {
      transform: translateX(calc(85.71429% - 40px));
    }
  }

  @media screen and (min-width: 104em) {
    .banner-monads-super-img .monad-0 {
      transform: translateX(calc(40px - 85.71429%));
    }

    .banner-monads-super-img .monad-3 {
      transform: translateX(calc(85.71429% - 40px));
    }
  }

  .header-magazin .masthead {
    font-size: 80px;
  }

  @media screen and (min-width: 52em) {
    .header-magazin .masthead {
      font-size: 100px;
    }
  }

  @media screen and (min-width: 72em) {
    .header-magazin .masthead {
      font-size: 150px;
    }
  }

  @media screen and (min-width: 52em) {
    .header-magazin .header-image {
      margin-bottom: -20%;
    }
  }

  .header-magazin .header-image-heading {
    width: 100%;
    left: 0;
  }

  @media screen and (max-width: 51.999em) {
    .header-magazin .header-image-heading {
      height: 100%;
      top: 0;
    }
  }

  @media screen and (min-width: 52em) {
    .header-magazin .header-image-heading {
      height: 40%;
      top: 12%;
    }
  }

  @media screen and (max-width: 51.999em) {
    .header-magazin .header-texts {
      background-color: #fff;
    }
  }

  @media screen and (min-width: 52em) {
    .header-magazin .header-texts-outer {
      width: calc((99.99999% + 30px) * 14 / 12 - 30px);
      margin-left: calc((99.99999% + 30px) * -1 / 12);
      margin-right: calc((99.99999% + 30px) * -1 / 12);
    }
  }

  @media screen and (min-width: 72em) {
    .header-magazin .header-texts-outer {
      width: calc((99.99999% + 40px) * 14 / 12 - 40px);
      margin-left: calc((99.99999% + 40px) * -1 / 12);
      margin-right: calc((99.99999% + 40px) * -1 / 12);
    }
  }

  @media screen and (min-width: 104em) {
    .header-magazin .header-texts-outer {
      width: calc((99.99999% + 40px) * 14 / 12 - 40px);
      margin-left: calc((99.99999% + 40px) * -1 / 12);
      margin-right: calc((99.99999% + 40px) * -1 / 12);
    }
  }

  @media screen and (min-width: 52em) {
    .header-magazin .header-texts-inner {
      width: calc((99.99999% + 30px) * 12 / 14 - 30px);
      margin-left: calc((99.99999% + 30px) * 1 / 14);
      margin-right: calc((99.99999% + 30px) * 1 / 14);
    }
  }

  @media screen and (min-width: 72em) {
    .header-magazin .header-texts-inner {
      width: calc((99.99999% + 40px) * 12 / 14 - 40px);
      margin-left: calc((99.99999% + 40px) * 1 / 14);
      margin-right: calc((99.99999% + 40px) * 1 / 14);
    }
  }

  @media screen and (min-width: 104em) {
    .header-magazin .header-texts-inner {
      width: calc((99.99999% + 40px) * 12 / 14 - 40px);
      margin-left: calc((99.99999% + 40px) * 1 / 14);
      margin-right: calc((99.99999% + 40px) * 1 / 14);
    }
  }

  .header-generic-index .monad-solid {
    opacity: 0.9;
  }

  @media screen and (min-width: 52em) and (max-width: 71.999em) {
    .header-generic-index .image {
      margin-right: calc(30px * -1);
      margin-left: calc(30px * -1);
    }
  }

  @media screen and (min-width: 72em) and (max-width: 103.999em) {
    .header-generic-index .image {
      margin-right: calc(40px * -1);
      margin-left: calc(40px * -1);
    }
  }

  @media screen and (min-width: 104em) {
    .header-generic-index .image {
      margin-right: calc(40px * -1);
      margin-left: calc(40px * -1);
    }
  }

  @media screen and (min-width: 52em) and (max-width: 71.999em) {
    .header-generic-index .contain-monad {
      padding-bottom: 110px;
    }
  }

  @media screen and (min-width: 72em) {
    .header-generic-index .contain-monad {
      padding-bottom: 150px;
    }
  }

  .header-generic-index .monad-outer {
    position: absolute;
    width: calc((99.99999% + 30px) * 2 / 4 - 30px);
    left: 0;
  }

  @media screen and (min-width: 36em) {
    .header-generic-index .monad-outer {
      width: calc((99.99999% + 30px) * 3 / 8 - 30px);
    }
  }

  @media screen and (min-width: 52em) {
    .header-generic-index .monad-outer {
      width: calc((99.99999% + 30px) * 7 / 12 - 30px);
      margin-left: calc((99.99999% + 30px) * -1 / 12);
    }
  }

  @media screen and (min-width: 72em) {
    .header-generic-index .monad-outer {
      width: calc((99.99999% + 40px) * 7 / 12 - 40px);
      margin-left: calc((99.99999% + 40px) * 0 / 12);
    }
  }

  @media screen and (max-width: 51.999em) {
    .header-generic-index .monad-outer {
      display: none;
    }
  }

  @media screen and (min-width: 52em) and (max-width: 71.999em) {
    .header-generic-index .monad-outer {
      bottom: -110px;
    }
  }

  @media screen and (min-width: 72em) {
    .header-generic-index .monad-outer {
      bottom: -150px;
    }
  }

  @media screen and (max-width: 25.999em) {
    .header-generic-index .monad-outer {
      left: 30px;
    }
  }

  @media screen and (min-width: 26em) and (max-width: 35.999em) {
    .header-generic-index .monad-outer {
      left: 30px;
    }
  }

  @media screen and (min-width: 36em) and (max-width: 51.999em) {
    .header-generic-index .monad-outer {
      left: 30px;
    }
  }

  .header-generic-index .title-outer {
    z-index: 1;
    bottom: 80px;
  }

  @media screen and (min-width: 52em) {
    .header-generic-index .title-outer {
      width: calc((99.99999% + 30px) * 5 / 12 - 30px);
    }
  }

  @media screen and (min-width: 72em) {
    .header-generic-index .title-outer {
      width: calc((99.99999% + 40px) * 5 / 12 - 40px);
      margin-left: calc((99.99999% + 40px) * 1 / 12);
    }
  }

  @media screen and (min-width: 52em) {
    .header-generic-index .title-outer {
      display: flex;
      align-items: center;
      position: absolute;
      margin-top: 0 !important;
    }
  }

  @media screen and (min-width: 52em) and (max-width: 71.999em) {
    .header-generic-index .title-outer {
      bottom: 110px;
    }
  }

  @media screen and (min-width: 72em) {
    .header-generic-index .title-outer {
      bottom: 150px;
    }
  }

  @media screen and (min-width: 52em) and (max-width: 71.999em) {
    .header-generic-index .title-outer {
      height: calc(21.875vw + -4.5em);
    }
  }

  @media screen and (min-width: 72em) and (max-width: 103.999em) {
    .header-generic-index .title-outer {
      height: calc(15.625vw + -3.125em);
    }
  }

  @media screen and (min-width: 104em) {
    .header-generic-index .title-outer {
      height: 210px;
    }
  }

  .advert-custom .advert-illu {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: stretch;
  }

  .advert-custom .advert-illu > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .advert-custom .advert-illu::before {
    content: "";
    display: block;
    width: 0.01px;
    height: 0;
    padding-bottom: 50%;
  }

  @media screen and (min-width: 72em) {
    .advert-custom .advert-illu::before {
      padding-bottom: 56.25%;
    }
  }

  @media screen and (min-width: 72em) {
    .advert-custom .advert-illu {
      width: calc(99.99999% * 8 / 12);
    }
  }

  @media screen and (min-width: 72em) {
    .advert-custom .advert-main {
      width: calc(99.99999% * 4 / 12);
    }
  }

  .advert-magazin .advert-illu {
    width: calc(99.99999% * 3 / 4);
  }

  @media screen and (min-width: 26em) {
    .advert-magazin .advert-illu {
      width: calc(99.99999% * 2 / 4);
    }
  }

  @media screen and (min-width: 36em) {
    .advert-magazin .advert-illu {
      width: calc(99.99999% * 3 / 8);
    }
  }

  @media screen and (min-width: 52em) {
    .advert-magazin .advert-illu {
      width: calc(99.99999% * 5 / 12);
    }
  }

  @media screen and (min-width: 72em) {
    .advert-magazin .advert-illu {
      width: calc(99.99999% * 4 / 12);
    }
  }

  @media screen and (min-width: 36em) {
    .advert-magazin .advert-main {
      width: calc(99.99999% * 4 / 8);
      margin-left: calc(99.99999% * 1 / 8);
    }
  }

  @media screen and (min-width: 52em) {
    .advert-magazin .advert-main {
      width: calc(99.99999% * 5 / 12);
      margin-left: calc(99.99999% * 1 / 12);
    }
  }

  @media screen and (min-width: 72em) {
    .advert-magazin .advert-main {
      width: calc(99.99999% * 4 / 12);
      margin-left: calc(99.99999% * 1 / 12);
    }
  }

  @media screen and (min-width: 72em) {
    .advert-magazin .advert-xtra {
      width: calc(99.99999% * 3 / 12);
    }
  }

  @media screen and (min-width: 36em) {
    .advert-abo .advert-main {
      width: calc(99.99999% * 4 / 8);
    }
  }

  @media screen and (min-width: 52em) {
    .advert-abo .advert-main {
      width: calc(99.99999% * 5 / 12);
    }
  }

  .advert-abo .advert-illu {
    width: calc(99.99999% * 3 / 4);
  }

  @media screen and (max-width: 35.999em) {
    .advert-abo .advert-illu {
      order: -1;
    }
  }

  @media screen and (min-width: 26em) {
    .advert-abo .advert-illu {
      width: calc(99.99999% * 2 / 4);
    }
  }

  @media screen and (min-width: 36em) {
    .advert-abo .advert-illu {
      width: calc(99.99999% * 3 / 8);
      margin-left: calc(99.99999% * 1 / 8);
    }
  }

  @media screen and (min-width: 52em) {
    .advert-abo .advert-illu {
      width: calc(99.99999% * 5 / 12);
      margin-left: calc(99.99999% * 1 / 12);
    }
  }

  .aspect-tall::before {
    padding-bottom: 133.33333%;
  }

  .aspect-wide::before {
    padding-bottom: 75%;
  }

  .teaser-flow-generic .flickity-flow {
    justify-content: center;
  }

  .teaser-flow-generic .flickity-flow::after {
    content: "flickity";
    display: none;
  }

  @media screen and (min-width: 72em) {
    .teaser-flow-generic .flickity-flow::after {
      content: "";
    }
  }

  @media screen and (min-width: 72em) {
    .teaser-flow-generic [data-ref~="pagination"] {
      display: none;
    }

    .teaser-flow-generic [data-ref~="button-prev"] {
      display: none;
    }

    .teaser-flow-generic [data-ref~="button-next"] {
      display: none;
    }

    .teaser-flow-generic [data-ref~="wrapper"] {
      justify-content: center;
    }
  }

  .teaser-flow-generic.teaser-slider-3up .teaser-item {
    width: calc(99.99999% * 3 / 4);
  }

  @media screen and (min-width: 36em) {
    .teaser-flow-generic.teaser-slider-3up .teaser-item {
      width: calc(99.99999% * 3.5 / 8);
    }
  }

  @media screen and (min-width: 52em) {
    .teaser-flow-generic.teaser-slider-3up .teaser-item {
      width: calc(99.99999% * 5 / 12);
    }
  }

  @media screen and (min-width: 72em) {
    .teaser-flow-generic.teaser-slider-3up .teaser-item {
      width: calc(99.99999% * 4 / 12);
    }
  }

  .teaser-flow-generic.teaser-slider-4up .teaser-item {
    width: calc(99.99999% * 2.5 / 4);
  }

  @media screen and (min-width: 36em) {
    .teaser-flow-generic.teaser-slider-4up .teaser-item {
      width: calc(99.99999% * 3 / 8);
    }
  }

  @media screen and (min-width: 52em) {
    .teaser-flow-generic.teaser-slider-4up .teaser-item {
      width: calc(99.99999% * 3.5 / 12);
    }
  }

  @media screen and (min-width: 72em) {
    .teaser-flow-generic.teaser-slider-4up .teaser-item {
      width: calc(99.99999% * 3 / 12);
    }
  }

  .teaser-flow-events .flickity-flow .flickity-viewport {
    overflow: hidden !important;
  }

  .teaser-flow-events .flickity-flow::after {
    content: "flickity";
    display: none;
  }

  @media screen and (min-width: 72em) {
    .teaser-flow-events .flickity-flow::after {
      content: "";
    }
  }

  @media screen and (min-width: 72em) {
    .teaser-flow-events [data-ref~="pagination"] {
      display: none;
    }

    .teaser-flow-events [data-ref~="button-prev"] {
      display: none;
    }

    .teaser-flow-events [data-ref~="button-next"] {
      display: none;
    }
  }

  @media screen and (min-width: 26em) {
    .teaser-flow-events {
      width: calc((99.99999% + 30px) * 3 / 4 - 30px);
      margin-left: calc((99.99999% + 30px) * 0.5 / 4);
    }
  }

  @media screen and (min-width: 36em) {
    .teaser-flow-events {
      width: calc((99.99999% + 30px) * 8 / 8 - 30px);
      margin-left: calc((99.99999% + 30px) * 0 / 8);
    }
  }

  @media screen and (min-width: 52em) {
    .teaser-flow-events {
      width: calc((99.99999% + 30px) * 10 / 12 - 30px);
      margin-left: calc((99.99999% + 30px) * 1 / 12);
    }
  }

  @media screen and (min-width: 72em) {
    .teaser-flow-events {
      width: calc((99.99999% + 40px) * 12 / 12 - 40px);
      margin-left: calc((99.99999% + 40px) * 0 / 12);
    }
  }

  .teaser-flow-media .flickity-flow .flickity-viewport {
    overflow: hidden !important;
  }

  .teaser-flow-media .flickity-flow::after {
    content: "flickity";
    display: none;
  }

  @media screen and (min-width: 72em) {
    .teaser-flow-media .flickity-flow::after {
      content: "";
    }
  }

  .teaser-flow-media .teaser-item-inner {
    padding-right: 10px;
  }

  .teaser-flow-media .item-illu::after {
    width: calc(100% + 10px + 30px * 2);
  }

  @media screen and (min-width: 26em) {
    .teaser-flow-media .teaser-item-inner {
      padding-right: 20px;
    }

    .teaser-flow-media .item-illu::after {
      width: calc(100% + 20px + 30px * 2);
    }
  }

  @media screen and (min-width: 36em) {
    .teaser-flow-media .teaser-item-inner {
      padding-right: 30px;
    }

    .teaser-flow-media .item-illu::after {
      width: calc(100% + 30px + 30px * 2);
    }
  }

  @media screen and (min-width: 52em) and (max-width: 71.999em) {
    .teaser-flow-media .teaser-item-inner {
      padding-right: 40px;
    }

    .teaser-flow-media .item-illu::after {
      width: calc(100% + 40px + 30px * 2);
    }
  }

  @media screen and (min-width: 72em) and (max-width: 103.999em) {
    .teaser-flow-media .teaser-item-inner {
      padding-right: 40px;
    }

    .teaser-flow-media .item-illu::after {
      width: calc(100% + 40px + 40px * 2);
    }
  }

  @media screen and (min-width: 104em) {
    .teaser-flow-media .teaser-item-inner {
      padding-right: 40px;
    }

    .teaser-flow-media .item-illu::after {
      width: calc(100% + 40px + 40px * 2);
    }
  }

  .teaser-flow-media .item-illu::after {
    content: "";
    position: absolute;
    top: 100%;
    height: 4px;
    background-color: #000;
  }

  @media screen and (max-width: 25.999em) {
    .teaser-flow-media .item-illu::after {
      left: calc(0px - 30px);
    }
  }

  @media screen and (min-width: 26em) and (max-width: 35.999em) {
    .teaser-flow-media .item-illu::after {
      left: calc(0px - 30px);
    }
  }

  @media screen and (min-width: 36em) and (max-width: 51.999em) {
    .teaser-flow-media .item-illu::after {
      left: calc(0px - 30px);
    }
  }

  @media screen and (min-width: 52em) and (max-width: 71.999em) {
    .teaser-flow-media .item-illu::after {
      left: calc(0px - 30px);
    }
  }

  @media screen and (min-width: 72em) and (max-width: 103.999em) {
    .teaser-flow-media .item-illu::after {
      left: calc(0px - 40px);
    }
  }

  @media screen and (min-width: 104em) {
    .teaser-flow-media .item-illu::after {
      left: calc(0px - 40px);
    }
  }

  .teaser-flow-media .teaser-item {
    width: calc(99.99999% * 2.25 / 4);
  }

  @media screen and (min-width: 26em) {
    .teaser-flow-media .teaser-item {
      width: calc(99.99999% * 2.25 / 4);
    }
  }

  @media screen and (min-width: 36em) {
    .teaser-flow-media .teaser-item {
      width: calc(99.99999% * 3.5 / 8);
    }
  }

  @media screen and (min-width: 52em) {
    .teaser-flow-media .teaser-item {
      width: calc(99.99999% * 4 / 12);
    }
  }

  @media screen and (min-width: 72em) {
    .teaser-flow-media .teaser-item {
      width: calc(99.99999% * 3 / 12);
    }
  }

  .teaser-flow-exhibitions .flickity-flow .flickity-viewport {
    overflow: hidden !important;
  }

  .teaser-flow-exhibitions .flickity-flow::after {
    content: "flickity";
    display: none;
  }

  @media screen and (min-width: 36em) {
    .teaser-flow-exhibitions .flickity-flow::after {
      content: "";
    }
  }

  @media screen and (min-width: 26em) {
    .teaser-flow-exhibitions .teaser-frame-inner {
      width: calc((99.99999% + 30px) * 3 / 4 - 30px);
      margin-left: calc((99.99999% + 30px) * 0.5 / 4);
    }
  }

  @media screen and (min-width: 36em) {
    .teaser-flow-exhibitions .teaser-frame-inner {
      width: calc((99.99999% + 30px) * 8 / 8 - 30px);
      margin-left: calc((99.99999% + 30px) * 0 / 8);
    }
  }

  .teaser-flow-exhibitions .spacer {
    position: relative;
  }

  @media screen and (min-width: 52em) {
    .teaser-flow-exhibitions .spacer {
      width: calc(99.99999% * 2 / 12);
    }
  }

  .teaser-flow-exhibitions .spacer::after {
    content: "";
    position: absolute;
    left: calc(50% - 2px);
    width: 4px;
    background-color: #000;
  }

  @media screen and (min-width: 52em) and (max-width: 71.999em) {
    .teaser-flow-exhibitions .spacer::after {
      top: calc(40px / 2);
      height: calc(100% - 50px);
    }
  }

  @media screen and (min-width: 72em) and (max-width: 103.999em) {
    .teaser-flow-exhibitions .spacer::after {
      top: calc(40px / 2);
      height: calc(100% - 50px);
    }
  }

  @media screen and (min-width: 104em) {
    .teaser-flow-exhibitions .spacer::after {
      top: calc(40px / 2);
      height: calc(100% - 50px);
    }
  }

  .teaser-flow-exhibitions .exhibitions-feat,
  .teaser-flow-exhibitions .exhibitions-list {
    width: calc(99.99999% * 4 / 4);
  }

  @media screen and (min-width: 36em) {
    .teaser-flow-exhibitions .exhibitions-feat,
    .teaser-flow-exhibitions .exhibitions-list {
      width: calc(99.99999% * 4 / 8);
      margin-left: calc(99.99999% * 0 / 8);
      margin-right: calc(99.99999% * 0 / 8);
    }
  }

  @media screen and (min-width: 52em) {
    .teaser-flow-exhibitions .exhibitions-feat,
    .teaser-flow-exhibitions .exhibitions-list {
      width: calc(99.99999% * 5 / 12);
    }
  }

  .teaser-flow-exhibitions .teaser-item {
    width: 100%;
    flex: 0 0 auto !important;
  }

  .teaser-flow-articles .flickity-viewport {
    overflow: hidden !important;
  }

  .teaser-flow-articles .flickity-flow::after {
    content: "flickity";
    display: none;
  }

  .teaser-flow-articles .flickity-flow .flickity-prev-next-button {
    top: 50%;
    width: 4rem;
    height: 4rem;
    border-radius: 0%;
    transform: translateY(-50%);
  }

  .teaser-flow-articles .flickity-flow .flickity-prev-next-button.previous {
    left: auto;
    right: calc(100% - 10px);
  }

  .teaser-flow-articles .flickity-flow .flickity-prev-next-button.next {
    left: auto;
    left: calc(100% - 10px);
  }

  .teaser-flow-articles .frame-inner {
    width: calc((99.99999% + 30px) * 3 / 4 - 30px);
    margin-left: calc((99.99999% + 30px) * 0.5 / 4);
  }

  @media screen and (min-width: 36em) {
    .teaser-flow-articles .frame-inner {
      width: calc((99.99999% + 30px) * 6 / 8 - 30px);
      margin-left: calc((99.99999% + 30px) * 1 / 8);
    }
  }

  @media screen and (min-width: 52em) {
    .teaser-flow-articles .frame-inner {
      width: calc((99.99999% + 30px) * 12 / 12 - 30px);
      margin-left: calc((99.99999% + 30px) * 0 / 12);
    }
  }

  .teaser-flow-articles .teaser-item {
    width: calc(99.99999% * 4 / 4);
  }

  @media screen and (min-width: 52em) {
    .teaser-flow-articles .teaser-item {
      width: calc(99.99999% * 6 / 12);
    }
  }

  @media screen and (min-width: 36em) {
    .teaser-flow-articles .teaser-item .item-illu,
    .teaser-flow-articles .teaser-item .item-text {
      width: calc(99.99999% * 3 / 6);
      margin-left: calc(99.99999% * 0 / 6);
      margin-right: calc(99.99999% * 0 / 6);
    }
  }

  @media screen and (min-width: 52em) {
    .teaser-flow-articles .teaser-item .item-illu,
    .teaser-flow-articles .teaser-item .item-text {
      width: calc(99.99999% * 6 / 12);
    }
  }

  @media screen and (min-width: 52em) {
    .teaser-flow-community {
      width: calc((99.99999% + 30px) * 10 / 12 - 30px);
      margin-left: calc((99.99999% + 30px) * 1 / 12);
    }
  }

  @media screen and (min-width: 72em) {
    .teaser-flow-community {
      width: calc((99.99999% + 40px) * 12 / 12 - 40px);
      margin-left: calc((99.99999% + 40px) * 0 / 12);
    }
  }

  .teaser-flow-community .teaser-item-single {
    width: calc(99.99999% * 4 / 4);
  }

  @media screen and (max-width: 71.999em) {
    .teaser-flow-community .teaser-item-single {
      order: -1;
    }
  }

  @media screen and (min-width: 26em) {
    .teaser-flow-community .teaser-item-single {
      width: calc(99.99999% * 3 / 4);
      margin-left: calc(99.99999% * 0.5 / 4);
      margin-right: calc(99.99999% * 0.5 / 4);
    }
  }

  @media screen and (min-width: 36em) {
    .teaser-flow-community .teaser-item-single {
      width: calc(99.99999% * 4 / 8);
      margin-left: calc(99.99999% * 0 / 8);
      margin-right: calc(99.99999% * 0 / 8);
    }
  }

  @media screen and (min-width: 52em) {
    .teaser-flow-community .teaser-item-single {
      width: calc(99.99999% * 5 / 10);
      margin-left: calc(99.99999% * 0 / 10);
      margin-right: calc(99.99999% * 0 / 10);
    }
  }

  @media screen and (min-width: 72em) {
    .teaser-flow-community .teaser-item-single {
      width: calc(99.99999% * 4 / 12);
      margin-left: calc(99.99999% * 0 / 12);
      margin-right: calc(99.99999% * 0 / 12);
    }
  }

  @media screen and (min-width: 52em) {
    .teaser-flow-community .teaser-item-double {
      width: calc(99.99999% * 10 / 10);
      margin-left: calc(99.99999% * 0 / 10);
      margin-right: calc(99.99999% * 0 / 10);
    }
  }

  @media screen and (min-width: 72em) {
    .teaser-flow-community .teaser-item-double {
      width: calc(99.99999% * 8 / 12);
      margin-left: calc(99.99999% * 0 / 12);
      margin-right: calc(99.99999% * 0 / 12);
    }
  }

  .teaser-flow-community .teaser-item-double .item-illu {
    width: calc(99.99999% * 4 / 4);
  }

  @media screen and (min-width: 26em) {
    .teaser-flow-community .teaser-item-double .item-illu {
      width: calc(99.99999% * 3 / 4);
      margin-left: calc(99.99999% * 0.5 / 4);
      margin-right: calc(99.99999% * 0.5 / 4);
    }
  }

  @media screen and (min-width: 36em) {
    .teaser-flow-community .teaser-item-double .item-illu {
      width: calc(99.99999% * 4 / 8);
      margin-left: calc(99.99999% * 0 / 8);
      margin-right: calc(99.99999% * 0 / 8);
    }
  }

  @media screen and (min-width: 52em) {
    .teaser-flow-community .teaser-item-double .item-illu {
      width: calc(99.99999% * 5 / 10);
      margin-left: calc(99.99999% * 0 / 10);
      margin-right: calc(99.99999% * 0 / 10);
    }
  }

  @media screen and (min-width: 72em) {
    .teaser-flow-community .teaser-item-double .item-illu {
      width: calc(99.99999% * 4 / 8);
      margin-left: calc(99.99999% * 0 / 8);
      margin-right: calc(99.99999% * 0 / 8);
    }
  }

  .teaser-flow-community .teaser-item-double .item-text {
    width: calc(99.99999% * 4 / 4);
  }

  @media screen and (min-width: 26em) {
    .teaser-flow-community .teaser-item-double .item-text {
      width: calc(99.99999% * 3 / 4);
      margin-left: calc(99.99999% * 0.5 / 4);
      margin-right: calc(99.99999% * 0.5 / 4);
    }
  }

  @media screen and (min-width: 36em) {
    .teaser-flow-community .teaser-item-double .item-text {
      width: calc(99.99999% * 4 / 8);
      margin-left: calc(99.99999% * 0 / 8);
      margin-right: calc(99.99999% * 0 / 8);
    }
  }

  @media screen and (min-width: 52em) {
    .teaser-flow-community .teaser-item-double .item-text {
      width: calc(99.99999% * 5 / 10);
      margin-left: calc(99.99999% * 0 / 10);
      margin-right: calc(99.99999% * 0 / 10);
    }
  }

  @media screen and (min-width: 72em) {
    .teaser-flow-community .teaser-item-double .item-text {
      width: calc(99.99999% * 4 / 8);
      margin-left: calc(99.99999% * 0 / 8);
      margin-right: calc(99.99999% * 0 / 8);
    }
  }

  .teaser-flow-community .teaser-item-feat-monad .item-text {
    z-index: 1;
  }

  @media screen and (min-width: 72em) {
    .teaser-flow-community .teaser-item-feat-monad .item-text {
      padding-right: 40px !important;
    }
  }

  @media screen and (min-width: 36em) and (max-width: 51.999em) {
    .teaser-flow-community .teaser-item-feat-monad .item-text-heading {
      margin-left: calc((99.99999% + 30px) * -1 / 4);
    }
  }

  @media screen and (min-width: 52em) and (max-width: 71.999em) {
    .teaser-flow-community .teaser-item-feat-monad .item-text-heading {
      margin-left: calc((99.99999% + 30px) * -1 / 4);
    }
  }

  @media screen and (min-width: 72em) and (max-width: 103.999em) {
    .teaser-flow-community .teaser-item-feat-monad .item-text-heading {
      margin-left: calc((99.99999% + 40px) * -1 / 4);
    }
  }

  @media screen and (min-width: 104em) {
    .teaser-flow-community .teaser-item-feat-monad .item-text-heading {
      margin-left: calc((99.99999% + 40px) * -1 / 4);
    }
  }

  @media screen and (min-width: 72em) and (max-width: 103.999em) {
    .teaser-flow-community .teaser-item-feat-monad {
      position: relative;
    }

    .teaser-flow-community .teaser-item-feat-monad::after {
      content: "";
      position: absolute;
      right: calc(40px / 2);
      top: calc(50px / 2);
      height: calc(100% - 50px);
      width: 4px;
      background-color: #cfcfcf;
    }
  }

  @media screen and (min-width: 104em) {
    .teaser-flow-community .teaser-item-feat-monad {
      position: relative;
    }

    .teaser-flow-community .teaser-item-feat-monad::after {
      content: "";
      position: absolute;
      right: calc(40px / 2);
      top: calc(50px / 2);
      height: calc(100% - 50px);
      width: 4px;
      background-color: #cfcfcf;
    }
  }

  @media screen and (min-width: 52em) {
    .teaser-flow-posts {
      width: calc(99.99999% * 10 / 12);
      margin-left: calc(99.99999% * 1 / 12);
    }
  }

  @media screen and (min-width: 72em) {
    .teaser-flow-posts {
      width: calc(99.99999% * 12 / 12);
      margin-left: calc(99.99999% * 0 / 12);
    }
  }

  .teaser-flow-posts .teaser-item-post {
    width: calc(99.99999% * 4 / 4);
  }

  @media screen and (min-width: 26em) {
    .teaser-flow-posts .teaser-item-post {
    }
  }

  @media screen and (min-width: 36em) {
    .teaser-flow-posts .teaser-item-post {
      width: calc(99.99999% * 4 / 8);
      margin-left: calc(99.99999% * 0 / 8);
      margin-right: calc(99.99999% * 0 / 8);
    }
  }

  @media screen and (min-width: 52em) {
    .teaser-flow-posts .teaser-item-post {
      width: calc(99.99999% * 5 / 10);
      margin-left: calc(99.99999% * 0 / 10);
      margin-right: calc(99.99999% * 0 / 10);
    }
  }

  @media screen and (min-width: 72em) {
    .teaser-flow-posts .teaser-item-post {
      width: calc(99.99999% * 4 / 12);
      margin-left: calc(99.99999% * 0 / 12);
      margin-right: calc(99.99999% * 0 / 12);
    }
  }

  .teaser-item .overlay {
    opacity: 0;
    transition: opacity 150ms linear;
  }

  @media screen and (min-width: 52em) {
    .teaser-item:hover .overlay,
    .teaser-item:focus .overlay {
      opacity: 1;
    }
  }

  @media screen and (min-width: 52em) {
    .teaser-item-press {
      width: calc(99.99999% * 6 / 12);
    }
  }

  .teaser-item-press .inner {
    border-bottom: 3px solid #cfcfcf;
  }

  .teaser-item-press:nth-child(1) .inner {
    border-bottom: 3px solid #cfcfcf;
  }

  @media screen and (min-width: 52em) {
    .teaser-item-press:nth-child(-n + 2) .inner {
      border-bottom: 3px solid #cfcfcf;
    }
  }

  .teaser-item-event {
    width: calc(99.99999% * 4 / 4);
  }

  @media screen and (min-width: 72em) {
    .teaser-item-event {
      width: calc(99.99999% * 6 / 12);
    }
  }

  .teaser-item-event .item-illu,
  .teaser-item-event .item-text {
    width: calc(99.99999% * 4 / 4);
  }

  @media screen and (min-width: 36em) {
    .teaser-item-event .item-illu,
    .teaser-item-event .item-text {
      width: calc(99.99999% * 4 / 8);
    }
  }

  @media screen and (min-width: 72em) and (max-width: 103.999em) {
    .teaser-item-event .item-text-inner {
      padding-right: calc((99.99999% + 40px) * 1 / 3 - 40px);
    }

    .teaser-item-event:nth-child(odd) {
      position: relative;
    }

    .teaser-item-event:nth-child(odd)::after {
      content: "";
      position: absolute;
      right: calc(40px / 2);
      top: calc(50px / 2);
      height: calc(100% - 50px);
      width: 4px;
      background-color: #cfcfcf;
    }
  }

  @media screen and (min-width: 104em) {
    .teaser-item-event .item-text-inner {
      padding-right: calc((99.99999% + 40px) * 1 / 3 - 40px);
    }

    .teaser-item-event:nth-child(odd) {
      position: relative;
    }

    .teaser-item-event:nth-child(odd)::after {
      content: "";
      position: absolute;
      right: calc(40px / 2);
      top: calc(50px / 2);
      height: calc(100% - 50px);
      width: 4px;
      background-color: #cfcfcf;
    }
  }

  .teaser-item-stelle .item-inner {
    display: flex;
    flex-direction: column;
  }

  .teaser-item-stelle .item-main {
    flex: 1 1 0%;
  }

  .teaser-item-stelle .item-foot {
    flex: 0 0 auto;
  }

  @media screen and (min-width: 36em) {
    .teaser-item-post {
      width: calc(99.99999% * 4 / 8);
    }
  }

  @media screen and (min-width: 52em) {
    .teaser-item-post {
      width: calc(99.99999% * 4 / 12);
    }
  }

  .teaser-item-media .teaser-link .icon {
    margin-right: 5px;
    margin-left: -5px;
  }

  @media screen and (min-width: 26em) {
    .listing-item-article .item-img-wide {
      width: calc(99.99999% * 3 / 4);
      margin-left: calc(99.99999% * 0.5 / 4);
      margin-right: calc(99.99999% * 0.5 / 4);
    }
  }

  @media screen and (min-width: 36em) {
    .listing-item-article .item-img-wide {
      width: calc(99.99999% * 4 / 8);
      margin-left: calc(99.99999% * 0 / 8);
      margin-right: calc(99.99999% * 0 / 8);
    }
  }

  @media screen and (min-width: 52em) {
    .listing-item-article .item-img-wide {
      width: calc(99.99999% * 6 / 12);
    }
  }

  @media screen and (min-width: 26em) {
    .listing-item-article .item-img-tall {
      width: calc(99.99999% * 3 / 4);
      margin-left: calc(99.99999% * 0.5 / 4);
      margin-right: calc(99.99999% * 0.5 / 4);
    }
  }

  @media screen and (min-width: 36em) {
    .listing-item-article .item-img-tall {
      width: calc(99.99999% * 4 / 8);
      margin-left: calc(99.99999% * 0 / 8);
      margin-right: calc(99.99999% * 0 / 8);
    }
  }

  @media screen and (min-width: 52em) {
    .listing-item-article .item-img-tall {
      width: calc(99.99999% * 4 / 12);
    }
  }

  @media screen and (min-width: 26em) {
    .listing-item-article .item-txt {
      width: calc(99.99999% * 3 / 4);
      margin-left: calc(99.99999% * 0.5 / 4);
      margin-right: calc(99.99999% * 0.5 / 4);
    }
  }

  @media screen and (min-width: 36em) {
    .listing-item-article .item-txt {
      width: calc(99.99999% * 4 / 8);
      margin-left: calc(99.99999% * 0 / 8);
      margin-right: calc(99.99999% * 0 / 8);
    }
  }

  @media screen and (min-width: 52em) {
    .listing-item-article .item-txt {
      width: calc(99.99999% * 5 / 12);
      margin-left: calc(99.99999% * 1 / 12);
      margin-right: calc(99.99999% * 1 / 12);
    }
  }

  @media screen and (min-width: 72em) {
    .listing-item-article .item-txt {
      width: calc(99.99999% * 4 / 12);
    }
  }

  @media screen and (min-width: 52em) and (max-width: 71.999em) {
    .listing-item-article:nth-child(2n) .item-txt {
      margin-left: 0 !important;
    }

    .listing-item-article:nth-child(2n + 1) .item-txt {
      margin-right: 0 !important;
    }
  }

  @media screen and (min-width: 36em) {
    .listing-item-article .item-img {
      margin-left: auto;
    }

    .listing-item-article:nth-child(2n) .item-img {
      margin-left: 0;
      order: 1;
    }
  }

  @media screen and (min-width: 36em) {
    .listing-item-img-wide .item-img {
      width: calc(99.99999% * 3 / 8);
    }
  }

  @media screen and (min-width: 52em) {
    .listing-item-img-wide .item-img {
      width: calc(99.99999% * 5 / 12);
    }
  }

  @media screen and (min-width: 72em) {
    .listing-item-img-wide .item-img {
      width: calc(99.99999% * 4 / 12);
    }
  }

  @media screen and (min-width: 36em) {
    .listing-item-img-wide .item-txt {
      width: calc(99.99999% * 5 / 8);
    }
  }

  @media screen and (min-width: 52em) {
    .listing-item-img-wide .item-txt {
      width: calc(99.99999% * 7 / 12);
    }
  }

  @media screen and (min-width: 26em) {
    .listing-item-img-tall .item-img {
      width: calc(99.99999% * 3 / 4);
    }
  }

  @media screen and (min-width: 36em) {
    .listing-item-img-tall .item-img {
      width: calc(99.99999% * 3 / 8);
    }
  }

  @media screen and (min-width: 52em) {
    .listing-item-img-tall .item-img {
      width: calc(99.99999% * 4 / 12);
      margin-left: calc(99.99999% * 1 / 12);
    }
  }

  @media screen and (min-width: 72em) {
    .listing-item-img-tall .item-img {
      width: calc(99.99999% * 3 / 12);
      margin-left: calc(99.99999% * 1 / 12);
    }
  }

  @media screen and (min-width: 36em) {
    .listing-item-img-tall .item-txt {
      width: calc(99.99999% * 5 / 8);
    }
  }

  @media screen and (min-width: 52em) {
    .listing-item-img-tall .item-txt {
      width: calc(99.99999% * 6 / 12);
    }
  }

  @media screen and (min-width: 72em) {
    .listing-item-img-tall .item-txt {
      width: calc(99.99999% * 7 / 12);
    }
  }

  @media screen and (min-width: 52em) {
    .listing-item-txt {
      width: calc(99.99999% * 9 / 12);
      margin-left: calc(99.99999% * 1 / 12);
    }
  }

  @media screen and (min-width: 72em) {
    .listing-item-txt {
      width: calc(99.99999% * 8 / 12);
      margin-left: calc(99.99999% * 1 / 12);
    }
  }

  @media screen and (min-width: 26em) {
    .listing-item-event .item-img {
      width: calc(99.99999% * 3 / 4);
    }
  }

  @media screen and (min-width: 36em) {
    .listing-item-event .item-img {
      width: calc(99.99999% * 3 / 8);
    }
  }

  @media screen and (min-width: 52em) {
    .listing-item-event .item-img {
      width: calc(99.99999% * 5 / 12);
    }
  }

  @media screen and (min-width: 72em) {
    .listing-item-event .item-img {
      width: calc(99.99999% * 3 / 12);
    }
  }

  @media screen and (min-width: 36em) {
    .listing-item-event .item-txt {
      width: calc(99.99999% * 5 / 8);
    }
  }

  @media screen and (min-width: 52em) {
    .listing-item-event .item-txt {
      width: calc(99.99999% * 7 / 12);
    }
  }

  @media screen and (min-width: 72em) {
    .listing-item-event .item-txt {
      width: calc(99.99999% * 9 / 12);
    }
  }

  @media screen and (min-width: 72em) {
    .listing-item-event .event-date {
      width: calc(99.99999% * 3 / 9);
      margin-left: calc(99.99999% * 0 / 9);
      margin-right: calc(99.99999% * 0 / 9);
    }
  }

  @media screen and (min-width: 72em) {
    .listing-item-event .event-text {
      width: calc(99.99999% * 6 / 9);
      margin-left: calc(99.99999% * 0 / 9);
      margin-right: calc(99.99999% * 0 / 9);
    }
  }

  @media screen and (min-width: 36em) {
    .listing-item-post .item-img {
      width: calc(99.99999% * 3 / 8);
    }
  }

  @media screen and (min-width: 52em) {
    .listing-item-post .item-img {
      width: calc(99.99999% * 5 / 12);
    }
  }

  @media screen and (min-width: 72em) {
    .listing-item-post .item-img {
      width: calc(99.99999% * 4 / 12);
    }
  }

  @media screen and (min-width: 36em) {
    .listing-item-post .item-txt {
      width: calc(99.99999% * 5 / 8);
    }
  }

  @media screen and (min-width: 52em) {
    .listing-item-post .item-txt {
      width: calc(99.99999% * 7 / 12);
    }
  }

  .listing-table.js-scroll-collapse .toggle-icon svg {
    transform: rotate(0deg);
    transition: transform 200ms ease;
  }

  .listing-table.js-scroll-collapse
    .js-collapse-content[aria-hidden="true"]
    > .listing-cell
    .open-text,
  .listing-table.js-scroll-collapse
    .js-collapse-content[aria-hidden="true"]
    + .listing-cell
    .open-text {
    display: inline;
  }

  .listing-table.js-scroll-collapse
    .js-collapse-content[aria-hidden="true"]
    > .listing-cell
    .close-text,
  .listing-table.js-scroll-collapse
    .js-collapse-content[aria-hidden="true"]
    + .listing-cell
    .close-text {
    display: none;
  }

  .listing-table.js-scroll-collapse
    .js-collapse-content[aria-hidden="false"]
    > .listing-cell.end-cell,
  .listing-table.js-scroll-collapse
    .js-collapse-content[aria-hidden="false"]
    + .listing-cell.end-cell {
    border-top: none;
  }

  .listing-table.js-scroll-collapse
    .js-collapse-content[aria-hidden="false"]
    > .listing-cell
    .open-text,
  .listing-table.js-scroll-collapse
    .js-collapse-content[aria-hidden="false"]
    + .listing-cell
    .open-text {
    display: none;
  }

  .listing-table.js-scroll-collapse
    .js-collapse-content[aria-hidden="false"]
    > .listing-cell
    .close-text,
  .listing-table.js-scroll-collapse
    .js-collapse-content[aria-hidden="false"]
    + .listing-cell
    .close-text {
    display: inline;
  }

  .listing-table.js-scroll-collapse
    .js-collapse-content[aria-hidden="false"]
    > .listing-cell
    .toggle-icon
    svg,
  .listing-table.js-scroll-collapse
    .js-collapse-content[aria-hidden="false"]
    + .listing-cell
    .toggle-icon
    svg {
    transform: rotate(45deg);
  }

  .listing-cell {
    border-top: 3px solid #999;
  }

  .listing-cell:last-child {
    border-bottom: 3px solid #999;
  }

  .listing-cell .cell-content {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 0%;
  }

  .listing-cell .cell-icon {
    flex: 0 0 auto;
  }

  .listing-cell .cell-code {
    flex: 0 0 auto;
    width: 100%;
  }

  @media screen and (min-width: 52em) {
    .listing-cell .cell-code {
      width: 6rem;
    }
  }

  @media screen and (max-width: 25.999em) {
    .listing-cell .cell-code {
      margin-right: 30px;
    }
  }

  @media screen and (min-width: 26em) and (max-width: 35.999em) {
    .listing-cell .cell-code {
      margin-right: 30px;
    }
  }

  @media screen and (min-width: 36em) and (max-width: 51.999em) {
    .listing-cell .cell-code {
      margin-right: 30px;
    }
  }

  @media screen and (min-width: 52em) and (max-width: 71.999em) {
    .listing-cell .cell-code {
      margin-right: 30px;
    }
  }

  @media screen and (min-width: 72em) and (max-width: 103.999em) {
    .listing-cell .cell-code {
      margin-right: 30px;
    }
  }

  @media screen and (min-width: 104em) {
    .listing-cell .cell-code {
      margin-right: 30px;
    }
  }

  .listing-cell .cell-text {
    flex: 1 1 0%;
  }

  .listing-cell:not(.js-collapse) a .icon {
    transform: translateX(-5px);
    transition: transform 100ms ease;
  }

  .listing-cell:not(.js-collapse) a:hover .icon {
    transform: translateX(0);
  }

  .listing-cell .acronym {
    width: 5rem;
  }

  @media screen and (min-width: 52em) {
    .listing-flow .listing-filter {
      width: calc((99.99999% + 30px) * 10 / 12 - 30px);
      margin-left: calc((99.99999% + 30px) * 1 / 12);
    }
  }

  .listing-filter .js-collapse-trigger[aria-expanded="true"] .open-text {
    display: none;
  }

  .listing-filter .js-collapse-trigger[aria-expanded="true"] .close-text {
    display: inline;
  }

  .listing-filter .js-collapse-trigger[aria-expanded="false"] .open-text {
    display: inline;
  }

  .listing-filter .js-collapse-trigger[aria-expanded="false"] .close-text {
    display: none;
  }

  .listing-filter .filter-content {
    border-top: 3px solid #000;
    border-bottom: 3px solid #000;
  }

  @media screen and (min-width: 36em) {
    .listing-filter .filter-content .filter-block {
      width: calc(99.99999% * 4 / 8);
    }
  }

  @media screen and (min-width: 52em) {
    .listing-filter .filter-content .filter-block {
      width: calc(99.99999% * 6 / 12);
    }
  }

  @media screen and (min-width: 72em) {
    .listing-filter .filter-content .filter-block {
      width: calc(99.99999% * 4 / 12);
    }
  }

  .metainfo-left {
    width: calc(99.99999% * 4 / 4);
  }

  @media screen and (min-width: 26em) {
    .metainfo-left {
      width: calc(99.99999% * 3 / 4);
      margin-left: calc(99.99999% * 0.5 / 4);
      margin-right: calc(99.99999% * 0.5 / 4);
    }
  }

  @media screen and (min-width: 36em) {
    .metainfo-left {
      width: calc(99.99999% * 6 / 8);
      margin-left: calc(99.99999% * 1 / 8);
      margin-right: calc(99.99999% * 0 / 8);
    }
  }

  @media screen and (min-width: 52em) {
    .metainfo-left {
      width: calc(99.99999% * 5 / 12);
      margin-left: calc(99.99999% * 0 / 12);
    }
  }

  @media screen and (min-width: 72em) {
    .metainfo-left {
      width: calc(99.99999% * 8 / 12);
    }
  }

  .metainfo-right {
    width: calc(99.99999% * 4 / 4);
  }

  @media screen and (min-width: 26em) {
    .metainfo-right {
      width: calc(99.99999% * 3 / 4);
      margin-left: calc(99.99999% * 0.5 / 4);
      margin-right: calc(99.99999% * 0.5 / 4);
    }
  }

  @media screen and (min-width: 36em) {
    .metainfo-right {
      width: calc(99.99999% * 6 / 8);
      margin-left: calc(99.99999% * 1 / 8);
      margin-right: calc(99.99999% * 0 / 8);
    }
  }

  @media screen and (min-width: 52em) {
    .metainfo-right {
      width: calc(99.99999% * 5 / 12);
      margin-left: calc(99.99999% * 0 / 12);
    }
  }

  @media screen and (min-width: 72em) {
    .metainfo-right {
      width: calc(99.99999% * 3 / 12);
    }
  }

  .metainfo-gap {
    position: relative;
  }

  @media screen and (min-width: 52em) {
    .metainfo-gap {
      width: calc(99.99999% * 2 / 12);
    }
  }

  @media screen and (min-width: 72em) {
    .metainfo-gap {
      width: calc(99.99999% * 1 / 12);
    }
  }

  @media screen and (min-width: 52em) {
    .metainfo-gap::after {
      content: "";
      position: absolute;
      left: calc(50% - 2px);
      top: calc(60px / 2);
      height: calc(100% - 60px);
      width: 4px;
      background-color: #cfcfcf;
    }
  }

  @media screen and (min-width: 72em) and (max-width: 103.999em) {
    .metainfo-gap::after {
      left: calc(40px / -2);
      left: calc(0%);
    }
  }

  @media screen and (min-width: 104em) {
    .metainfo-gap::after {
      left: calc(40px / -2);
      left: calc(0%);
    }
  }

  @media screen and (min-width: 72em) {
    .metainfo-left .metainfo-block-maxi {
      width: calc(99.99999% * 7 / 8);
      margin-left: calc(99.99999% * 0 / 8);
      margin-right: calc(99.99999% * 0 / 8);
    }
  }

  @media screen and (min-width: 36em) {
    .metainfo-block-mini {
      width: calc(99.99999% * 5 / 6);
      margin-left: calc(99.99999% * 0 / 6);
      margin-right: calc(99.99999% * 0 / 6);
    }
  }

  @media screen and (min-width: 52em) {
    .metainfo-block-mini {
      width: calc(99.99999% * 5 / 5);
      margin-left: calc(99.99999% * 0 / 5);
      margin-right: calc(99.99999% * 0 / 5);
    }
  }

  @media screen and (min-width: 72em) {
    .metainfo-left .metainfo-block-mini {
      width: calc(99.99999% * 3 / 8);
      margin-left: calc(99.99999% * 0 / 8);
      margin-right: calc(99.99999% * 1 / 8);
    }
  }

  .fotonachweis .icon {
    transform: rotate(0);
    transition: transform 200ms ease;
  }

  .fotonachweis button[aria-expanded="false"] .icon {
    transform: rotate(0.5turn);
  }

  .marquee {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding-bottom: 4.19561%;
    position: relative;
  }

  @media screen and (max-width: 25.999em) {
    .marquee {
      padding-top: 40px;
    }
  }

  @media screen and (min-width: 26em) and (max-width: 35.999em) {
    .marquee {
      padding-top: 40px;
    }
  }

  @media screen and (min-width: 36em) and (max-width: 51.999em) {
    .marquee {
      padding-top: 40px;
    }
  }

  @media screen and (min-width: 52em) and (max-width: 71.999em) {
    .marquee {
      padding-top: 50px;
    }
  }

  @media screen and (min-width: 72em) and (max-width: 103.999em) {
    .marquee {
      padding-top: 50px;
    }
  }

  @media screen and (min-width: 104em) {
    .marquee {
      padding-top: 50px;
    }
  }

  .marquee .marquee-inner {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform-origin: right top;
    transform: rotate(-4deg) translate3d(0, -50%, 0);
  }

  .marquee .marquee-holder {
    overflow: hidden;
    height: 50px;
    position: relative;
    width: calc(100% + 20px);
    margin-left: -10px;
  }

  .marquee .slider-marquee .cell {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    height: 50px;
  }

  .marquee .slider-marquee .dots {
    margin: 0 1em;
  }

  .marquee a {
    color: inherit;
  }

  #menu-desktop .menu-back {
    opacity: 0;
    transition: opacity 200ms;
  }

  #menu-desktop .menu-back .icon {
    margin-left: -6px;
  }

  #menu-desktop .menu-back .link-inner {
    font-weight: normal !important;
  }

  #menu-desktop .menu-back.active {
    opacity: 1;
  }

  #menu-desktop .menu-push {
    transform: translateX(0);
    transition: transform 200ms ease-in-out;
  }

  #menu-desktop .tabs-list {
    height: 80px;
    transition: height 200ms ease;
  }

  #menu-desktop .tabs-list .menu-toggle {
    text-transform: uppercase;
  }

  #menu-desktop .tabs-list .home-link {
    height: 80px;
    right: 100%;
    top: 0;
    visibility: hidden;
    opacity: 0;
  }

  #menu-desktop .tabs-outer {
    height: 80px;
  }

  #menu-desktop .tabs-inner.fix {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 2;
  }

  #menu-desktop .tabs-inner.fix .tabs-frame {
    box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.25);
  }

  #menu-desktop .tabs-inner.fix .home-link {
    visibility: visible;
    opacity: 1;
  }

  #menu-desktop .panels-inner {
    background: #4d7396;
    min-height: calc(100vh - 80px);
  }

  #menu-desktop .menu-toggle:hover,
  #menu-desktop .menu-toggle:active,
  #menu-desktop .menu-toggle.inpath {
    background-image: linear-gradient(currentColor, currentColor);
    background-size: 1px 3px;
    background-position: 0 bottom;
    background-repeat: repeat-x;
    background-clip: border-box;
    text-decoration: none;
  }

  #menu-phablet-outer {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition:
      opacity 150ms linear,
      visibility 0ms linear 150ms;
  }

  #menu-phablet-outer.active {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
    transition-delay: 0s;
  }

  #menu-phablet .menu-clip {
    padding: 3px;
    margin-left: -3px;
    margin-right: -3px;
  }

  #menu-phablet .menu-back {
    opacity: 0;
    transition: opacity 200ms;
  }

  #menu-phablet .menu-back .icon {
    margin-left: -6px;
  }

  #menu-phablet .menu-back .link-inner {
    font-weight: normal !important;
  }

  #menu-phablet .menu-back.active {
    opacity: 1;
  }

  #menu-phablet .menu-push {
    transform: translateX(0);
    transition: transform 200ms ease-in-out;
  }

  .menu-tree-panel {
    position: relative;
  }

  @media screen and (min-width: 52em) {
    .menu-tree-panel {
      width: calc(99.99999% * 4 / 12);
    }
  }

  @media screen and (min-width: 104em) {
    .menu-tree-panel {
      width: calc(99.99999% * 4 / 12);
    }
  }

  .menu-tree-level span.icon {
    flex: 0 0 auto;
  }

  .menu-tree-root > .menu-tree-level > button .link-inner {
    text-transform: uppercase;
  }

  .menu-tree-level a > span:not(.icon),
  .menu-tree-level button > span:not(.icon) {
    flex: 0 1 auto;
    min-width: 0%;
  }

  .menu-tree-level .link-inner {
    font-weight: normal !important;
  }

  .menu-tree-level .inpath > span > .link-inner {
    background-image: linear-gradient(currentColor, currentColor);
    background-size: 1px 1px;
    background-position: 0 bottom;
    background-repeat: repeat-x;
    background-clip: border-box;
    text-decoration: none;
  }

  .menu-tree-level .icon {
    margin-right: -6px;
  }

  .menu-tree-level .menu-tree-child {
    position: absolute;
    width: 100%;
    top: 0;
    left: 100%;
  }

  @media screen and (max-width: 25.999em) {
    .menu-tree-level .menu-tree-child {
      padding-top: calc(30px / 2) !important;
      padding-bottom: calc(30px / 2) !important;
      padding-left: calc(30px / 2) !important;
      padding-right: calc(30px / 2) !important;
    }
  }

  @media screen and (min-width: 26em) and (max-width: 35.999em) {
    .menu-tree-level .menu-tree-child {
      padding-top: calc(30px / 2) !important;
      padding-bottom: calc(30px / 2) !important;
      padding-left: calc(30px / 2) !important;
      padding-right: calc(30px / 2) !important;
    }
  }

  @media screen and (min-width: 36em) and (max-width: 51.999em) {
    .menu-tree-level .menu-tree-child {
      padding-top: calc(30px / 2) !important;
      padding-bottom: calc(30px / 2) !important;
      padding-left: calc(30px / 2) !important;
      padding-right: calc(30px / 2) !important;
    }
  }

  @media screen and (min-width: 52em) and (max-width: 71.999em) {
    .menu-tree-level .menu-tree-child {
      padding-top: calc(30px / 2) !important;
      padding-bottom: calc(30px / 2) !important;
      padding-left: calc(30px / 2) !important;
      padding-right: calc(30px / 2) !important;
    }
  }

  @media screen and (min-width: 72em) and (max-width: 103.999em) {
    .menu-tree-level .menu-tree-child {
      padding-top: calc(30px / 2) !important;
      padding-bottom: calc(30px / 2) !important;
      padding-left: calc(40px / 2) !important;
      padding-right: calc(40px / 2) !important;
    }
  }

  @media screen and (min-width: 104em) {
    .menu-tree-level .menu-tree-child {
      padding-top: calc(30px / 2) !important;
      padding-bottom: calc(30px / 2) !important;
      padding-left: calc(40px / 2) !important;
      padding-right: calc(40px / 2) !important;
    }
  }

  #menu-desktop-side {
    position: fixed;
    left: 50px;
    top: 40%;
    z-index: -1;
  }

  #menu-desktop-side:focus:not(.focus-visible) {
    outline: none;
  }

  #menu-desktop-side.focus-visible {
    box-shadow: 0 0 0 3px #cdcdcd;
  }

  #menu-desktop-side .menu-toggle {
    position: relative;
  }

  #menu-desktop-side .menu-toggle .hold-svg {
    width: 75px;
    height: 65px;
  }

  #menu-desktop-side .menu-toggle .dots,
  #menu-desktop-side .menu-toggle .bars {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
  }

  #menu-desktop-side .menu-toggle .dots,
  #menu-desktop-side .menu-toggle .dots::before,
  #menu-desktop-side .menu-toggle .dots::after {
    height: 5px;
    width: 5px;
    left: 50%;
    top: 50%;
    content: "";
    display: block;
    background-color: currentColor;
    border-radius: 50%;
  }

  #menu-desktop-side .menu-toggle .dots {
    opacity: 1;
    transition: opacity 200ms ease;
    transform: translate3d(-50%, -50%, 0);
  }

  #menu-desktop-side .menu-toggle .dots::before {
    transform: translateY(-12px);
  }

  #menu-desktop-side .menu-toggle .dots::after {
    transform: translateY(7px);
  }

  #menu-desktop-side .menu-toggle .bars {
    opacity: 0;
    transition: opacity 200ms ease;
  }

  #menu-desktop-side .menu-toggle .bars::before,
  #menu-desktop-side .menu-toggle .bars::after {
    background-color: currentColor;
    content: "";
    display: block;
    position: absolute;
    height: 40%;
    width: 3px;
    top: 30%;
    left: 50%;
    transition: transform 200ms ease;
  }

  #menu-desktop-side .menu-toggle .bars::before {
    transform: translateX(-50%) rotate(180deg);
  }

  #menu-desktop-side .menu-toggle .bars::after {
    transform-origin: 50% 50%;
    transform: translateX(-50%) rotate(0deg);
  }

  #menu-desktop-side .menu-toggle[aria-expanded="true"] .dots {
    opacity: 0;
  }

  #menu-desktop-side .menu-toggle[aria-expanded="true"] .bars {
    opacity: 1;
  }

  #menu-desktop-side .menu-toggle[aria-expanded="true"] .bars::before {
    transform: translateX(-50%) rotate(135deg);
  }

  #menu-desktop-side .menu-toggle[aria-expanded="true"] .bars::after {
    transform: translateX(-50%) rotate(45deg);
  }

  #menu-desktop-side .menu-content {
    margin-top: 15px;
    width: 330px;
    overflow: hidden;
  }

  #menu-desktop-side .menu-content .crumbs {
    text-transform: uppercase;
  }

  #menu-desktop-side .menu-content .spacer {
    display: inline-block;
    margin: 0 0.75em;
  }

  #menu-desktop-side .menu-content .menu-link {
    font-size: 14px;
    line-height: 1.5;
  }

  input,
  button,
  textarea,
  select {
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    color: inherit;
    opacity: 1;
  }

  #search-layer {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition:
      opacity 100ms linear,
      visibility 0ms linear 100ms;
    z-index: 20;
    display: flex;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
  }

  #search-layer[aria-hidden="false"] {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
    transition-delay: 0s;
  }

  #search-layer > * {
    margin-bottom: auto;
  }

  #search-layer .search-close {
    margin-bottom: auto;
  }

  .search-form {
    display: flex;
    width: 100%;
    border-bottom: 3px solid currentColor;
  }

  .search-form .search-input {
    border: none;
    position: relative;
    flex: 1 1 0%;
    display: block;
  }

  .search-form .search-submit {
    flex: 0 0 auto;
  }

  #page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  .page-partial {
    flex: 0 0 auto;
    position: relative;
  }

  .page-partial a:not(.link-outer).focus-visible,
  .page-partial button:not(.link-outer).focus-visible {
    box-shadow: 0 0 0 3px #cdcdcd;
  }

  #page-header {
    z-index: 1;
  }

  #page-header .layer.fix {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  #page-header button .bars {
    display: block;
    width: 25px;
    height: 25px;
    position: relative;
  }

  #page-header button .bars:before,
  #page-header button .bars:after {
    width: 100%;
    height: 3px;
    background-color: currentColor;
    content: "";
    display: block;
    position: absolute;
    transition:
      transform 100ms ease,
      background-color 100ms ease;
    transform-origin: 50% 50%;
  }

  #page-header button .bars:after {
    transform: translateY(17px) rotate(0deg);
  }

  #page-header button .bars:before {
    transform: translateY(5px) rotate(0deg);
  }

  #page-header button[aria-expanded~="true"] {
    color: inherit;
  }

  #page-header button[aria-expanded~="true"] .bars:after {
    transform: translateY(11px) rotate(-45deg);
  }

  #page-header button[aria-expanded~="true"] .bars:before {
    transform: translateY(11px) rotate(45deg);
  }

  #page-header .logo {
    flex-wrap: wrap;
  }

  @media screen and (max-width: 35.999em) {
    #page-header .logo .text {
      min-width: 100%;
    }
  }

  @media screen and (max-width: 35.999em) {
    #page-header .logo-inner {
      height: 80px;
    }

    #page-header .logo-inner img {
      transform-origin: left top;
      transform: scale(0.8);
    }
  }

  #page-header .menu-meta a {
    color: inherit;
    text-decoration: none;
  }

  #page-header .menu-meta a:not(.icon-only):focus,
  #page-header .menu-meta a:not(.icon-only):hover,
  #page-header .menu-meta a:not(.icon-only):active {
    background-image: linear-gradient(currentColor, currentColor);
    background-size: 1px 1px;
    background-position: 0 bottom;
    background-repeat: repeat-x;
    background-clip: border-box;
    text-decoration: none;
  }

  #page-header .phablet-meta.below {
    background-color: white;
    width: 100%;
    box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.25);
  }

  .phablet-layer {
    display: flex;
    flex-direction: column;
    -webkit-overflow-scrolling: touch;
  }

  .phablet-layer .menu-head {
    flex: 0 0 auto;
  }

  .phablet-layer .menu-main {
    flex: 1 0 auto;
  }

  @media screen and (max-width: 25.999em) {
    .phablet-layer .menu-main {
      margin-top: 35px;
    }
  }

  @media screen and (min-width: 26em) and (max-width: 35.999em) {
    .phablet-layer .menu-main {
      margin-top: 35px;
    }
  }

  @media screen and (min-width: 36em) and (max-width: 51.999em) {
    .phablet-layer .menu-main {
      margin-top: 35px;
    }
  }

  @media screen and (min-width: 52em) and (max-width: 71.999em) {
    .phablet-layer .menu-main {
      margin-top: 40px;
    }
  }

  .phablet-layer .menu-foot {
    flex: 0 0 auto;
  }

  @media screen and (max-width: 25.999em) {
    .phablet-layer .menu-foot {
      margin-top: 60px;
    }
  }

  @media screen and (min-width: 26em) and (max-width: 35.999em) {
    .phablet-layer .menu-foot {
      margin-top: 60px;
    }
  }

  @media screen and (min-width: 36em) and (max-width: 51.999em) {
    .phablet-layer .menu-foot {
      margin-top: 60px;
    }
  }

  @media screen and (min-width: 52em) and (max-width: 71.999em) {
    .phablet-layer .menu-foot {
      margin-top: 80px;
    }
  }

  #page-main {
    flex: 1 1 auto;
    position: relative;
  }

  #page-main-fg {
    position: relative;
  }

  #page-main-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 300px;
    background: linear-gradient(#f5f5eb, white);
  }

  .page-footer-inner .site-links {
    flex: 0 0 auto;
  }

  @media screen and (min-width: 36em) {
    .page-footer-inner {
      justify-content: space-between;
    }
  }

  @media screen and (max-width: 35.999em) {
    .page-footer-inner {
      flex-direction: column;
    }

    .page-footer-inner .meta-links {
      margin-top: 10px;
    }

    .page-footer-inner .logo {
      height: 80px;
    }

    .page-footer-inner .logo img {
      transform-origin: center center;
      transform: scale(0.8);
    }
  }

  .page-footer-inner .menu-meta {
    flex: 0 0 auto;
    padding-top: 10px;
    justify-content: center;
    flex-wrap: wrap;
  }

  .page-footer-inner .menu-meta > * {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .page-footer-inner .menu-meta > * + * {
    margin-left: 2rem;
  }

  @media screen and (min-width: 36em) {
    .page-footer-inner .menu-meta {
      justify-content: flex-start;
      max-width: calc(100% - 125px - 2rem);
      margin-left: 2rem;
    }
  }

  .page-footer-inner .menu-meta a {
    color: inherit;
    text-decoration: none;
    text-transform: uppercase;
  }

  .page-footer-inner .menu-meta a:not(.icon-only):focus,
  .page-footer-inner .menu-meta a:not(.icon-only):hover,
  .page-footer-inner .menu-meta a:not(.icon-only):active {
    background-image: linear-gradient(currentColor, currentColor);
    background-size: 1px 1px;
    background-position: 0 bottom;
    background-repeat: repeat-x;
    background-clip: border-box;
    text-decoration: none;
  }

  :root {
    --swiper-pagination-gap: 20px;
  }

  .swiper-container {
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
  }

  .swiper-container-no-flexbox .swiper-slide {
    float: left;
  }

  .swiper-container-vertical > .swiper-wrapper {
    flex-direction: column;
  }

  .swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
  }

  .swiper-container-android .swiper-slide,
  .swiper-wrapper {
    transform: translate3d(0px, 0, 0);
  }

  .swiper-container-multirow > .swiper-wrapper {
    flex-wrap: wrap;
  }

  .swiper-container-free-mode > .swiper-wrapper {
    transition-timing-function: ease-out;
    margin: 0 auto;
  }

  .swiper-slide {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
    transition-property: transform;
  }

  .swiper-invisible-blank-slide {
    visibility: hidden;
  }

  .swiper-container-autoheight {
    height: auto;
  }

  .swiper-container-autoheight .swiper-slide {
    height: auto;
  }

  .swiper-container-autoheight .swiper-wrapper {
    align-items: flex-start;
    transition-property: transform, height;
  }

  .swiper-container-wp8-horizontal {
    touch-action: pan-y;
  }

  .swiper-container-wp8-horizontal > .swiper-wrapper {
    touch-action: pan-y;
  }

  .swiper-container-wp8-vertical {
    touch-action: pan-x;
  }

  .swiper-container-wp8-vertical > .swiper-wrapper {
    touch-action: pan-x;
  }

  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    top: 50%;
    width: 27px;
    height: 44px;
    margin-top: -22px;
    z-index: 10;
    cursor: pointer;
    background-size: 27px 44px;
    background-position: center;
    background-repeat: no-repeat;
  }

  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
    opacity: 0.35;
    cursor: auto;
    pointer-events: none;
  }

  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 17 29' xmlns='http://www.w3.org/2000/svg'%3E %3Cg fill='none' fill-rule='evenodd' stroke-linecap='square'%3E %3Cg stroke='currentColor' stroke-width='4'%3E %3Cpath d='M14 26L3 14.5 14 3'/%3E %3C/g%3E %3C/g%3E %3C/svg%3E ");
    left: 10px;
    right: auto;
  }

  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 17 29' xmlns='http://www.w3.org/2000/svg'%3E %3Cg fill='none' fill-rule='evenodd' stroke-linecap='square'%3E %3Cg stroke='currentColor' stroke-width='4'%3E %3Cpath d='M3 3l11 11.5L3 26'/%3E %3C/g%3E %3C/g%3E %3C/svg%3E ");
    right: 10px;
    left: auto;
  }

  .swiper-button-prev.swiper-button-white,
  .swiper-container-rtl .swiper-button-next.swiper-button-white {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 17 29' xmlns='http://www.w3.org/2000/svg'%3E %3Cg fill='none' fill-rule='evenodd' stroke-linecap='square'%3E %3Cg stroke='%23FFF' stroke-width='4'%3E %3Cpath d='M14 26L3 14.5 14 3'/%3E %3C/g%3E %3C/g%3E %3C/svg%3E ");
  }

  .swiper-button-next.swiper-button-white,
  .swiper-container-rtl .swiper-button-prev.swiper-button-white {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 17 29' xmlns='http://www.w3.org/2000/svg'%3E %3Cg fill='none' fill-rule='evenodd' stroke-linecap='square'%3E %3Cg stroke='%23FFF' stroke-width='4'%3E %3Cpath d='M3 3l11 11.5L3 26'/%3E %3C/g%3E %3C/g%3E %3C/svg%3E ");
  }

  .swiper-button-prev.swiper-button-black,
  .swiper-container-rtl .swiper-button-next.swiper-button-black {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 17 29' xmlns='http://www.w3.org/2000/svg'%3E %3Cg fill='none' fill-rule='evenodd' stroke-linecap='square'%3E %3Cg stroke='%23000' stroke-width='4'%3E %3Cpath d='M14 26L3 14.5 14 3'/%3E %3C/g%3E %3C/g%3E %3C/svg%3E ");
  }

  .swiper-button-next.swiper-button-black,
  .swiper-container-rtl .swiper-button-prev.swiper-button-black {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 17 29' xmlns='http://www.w3.org/2000/svg'%3E %3Cg fill='none' fill-rule='evenodd' stroke-linecap='square'%3E %3Cg stroke='%23000' stroke-width='4'%3E %3Cpath d='M3 3l11 11.5L3 26'/%3E %3C/g%3E %3C/g%3E %3C/svg%3E ");
  }

  .swiper-button-lock {
    display: none;
  }

  .swiper-pagination {
    margin-top: var(--swiper-pagination-gap);
    line-height: 10px;
    overflow: visible;
    text-align: center;
    transition: 300ms opacity;
    transform: translate3d(0, 0, 0);
    z-index: 10;
  }

  .swiper-pagination.swiper-pagination-hidden {
    opacity: 0;
  }

  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-container-horizontal > .swiper-pagination-bullets {
    width: 100%;
  }

  .swiper-pagination-bullets-dynamic {
    overflow: hidden;
    font-size: 0;
  }

  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    transform: scale(0.33);
    position: relative;
  }

  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active,
  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
    transform: scale(1);
  }

  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
    transform: scale(0.66);
  }

  .swiper-pagination-bullets-dynamic
    .swiper-pagination-bullet-active-prev-prev {
    transform: scale(0.33);
  }

  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
    transform: scale(0.66);
  }

  .swiper-pagination-bullets-dynamic
    .swiper-pagination-bullet-active-next-next {
    transform: scale(0.33);
  }

  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    display: inline-block;
    border-radius: 100%;
    background: #000;
    opacity: 0.2;
  }

  button.swiper-pagination-bullet {
    border: none;
    margin: 0;
    padding: 0;
    box-shadow: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .swiper-pagination-clickable .swiper-pagination-bullet {
    cursor: pointer;
  }

  .swiper-pagination-bullet-active {
    opacity: 1;
    background: #000;
  }

  .swiper-container-vertical > .swiper-pagination-bullets {
    right: 10px;
    top: 50%;
    transform: translate3d(0px, -50%, 0);
  }

  .swiper-container-vertical
    > .swiper-pagination-bullets
    .swiper-pagination-bullet {
    margin: 6px 0;
    display: block;
  }

  .swiper-container-vertical
    > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
    top: 50%;
    transform: translateY(-50%);
    width: 8px;
  }

  .swiper-container-vertical
    > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic
    .swiper-pagination-bullet {
    display: inline-block;
    transition:
      200ms transform,
      200ms top;
  }

  .swiper-container-horizontal
    > .swiper-pagination-bullets
    .swiper-pagination-bullet {
    margin: 0 4px;
  }

  .swiper-container-horizontal
    > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
  }

  .swiper-container-horizontal
    > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic
    .swiper-pagination-bullet {
    transition:
      200ms transform,
      200ms left;
  }

  .swiper-container-horizontal.swiper-container-rtl
    > .swiper-pagination-bullets-dynamic
    .swiper-pagination-bullet {
    transition:
      200ms transform,
      200ms right;
  }

  .swiper-pagination-progressbar {
    background: rgba(0, 0, 0, 0.25);
    position: absolute;
  }

  .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    background: #007aff;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform: scale(0);
    transform-origin: left top;
  }

  .swiper-container-rtl
    .swiper-pagination-progressbar
    .swiper-pagination-progressbar-fill {
    transform-origin: right top;
  }

  .swiper-container-horizontal > .swiper-pagination-progressbar {
    width: 100%;
    height: 4px;
    left: 0;
    top: 0;
  }

  .swiper-container-vertical > .swiper-pagination-progressbar {
    width: 4px;
    height: 100%;
    left: 0;
    top: 0;
  }

  .swiper-container-vertical
    > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
    width: 100%;
    height: 4px;
    left: 0;
    top: 0;
  }

  .swiper-container-horizontal
    > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
    width: 4px;
    height: 100%;
    left: 0;
    top: 0;
  }

  .swiper-pagination-white .swiper-pagination-bullet-active {
    background: #ffffff;
  }

  .swiper-pagination-progressbar.swiper-pagination-white {
    background: rgba(255, 255, 255, 0.25);
  }

  .swiper-pagination-progressbar.swiper-pagination-white
    .swiper-pagination-progressbar-fill {
    background: #ffffff;
  }

  .swiper-pagination-black .swiper-pagination-bullet-active {
    background: #000000;
  }

  .swiper-pagination-progressbar.swiper-pagination-black {
    background: rgba(0, 0, 0, 0.25);
  }

  .swiper-pagination-progressbar.swiper-pagination-black
    .swiper-pagination-progressbar-fill {
    background: #000000;
  }

  .swiper-pagination-lock {
    display: none;
  }

  .swiper-scrollbar {
    border-radius: 10px;
    position: relative;
    -ms-touch-action: none;
    background: rgba(0, 0, 0, 0.1);
  }

  .swiper-container-horizontal > .swiper-scrollbar {
    position: absolute;
    left: 1%;
    bottom: 3px;
    z-index: 50;
    height: 5px;
    width: 98%;
  }

  .swiper-container-vertical > .swiper-scrollbar {
    position: absolute;
    right: 3px;
    top: 1%;
    z-index: 50;
    width: 5px;
    height: 98%;
  }

  .swiper-scrollbar-drag {
    height: 100%;
    width: 100%;
    position: relative;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    left: 0;
    top: 0;
  }

  .swiper-scrollbar-cursor-drag {
    cursor: move;
  }

  .swiper-scrollbar-lock {
    display: none;
  }

  .swiper-zoom-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .swiper-zoom-container > img,
  .swiper-zoom-container > svg,
  .swiper-zoom-container > canvas {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  .swiper-slide-zoomed {
    cursor: move;
  }

  .swiper-lazy-preloader {
    width: 42px;
    height: 42px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -21px;
    margin-top: -21px;
    z-index: 10;
    transform-origin: 50%;
    animation: swiper-preloader-spin 1s steps(12, end) infinite;
  }

  .swiper-lazy-preloader:after {
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
    background-position: 50%;
    background-size: 100%;
    background-repeat: no-repeat;
  }

  .swiper-lazy-preloader-white:after {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  }

  @keyframes swiper-preloader-spin {
    100% {
      transform: rotate(360deg);
    }
  }

  .swiper-container .swiper-notification {
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    opacity: 0;
    z-index: -1000;
  }

  .swiper-container-fade.swiper-container-free-mode .swiper-slide {
    transition-timing-function: ease-out;
  }

  .swiper-container-fade .swiper-slide {
    pointer-events: none;
    transition-property: opacity;
  }

  .swiper-container-fade .swiper-slide .swiper-slide {
    pointer-events: none;
  }

  .swiper-container-fade .swiper-slide-active {
    pointer-events: auto;
  }

  .swiper-container-fade .swiper-slide-active .swiper-slide-active {
    pointer-events: auto;
  }

  .swiper-container-cube {
    overflow: visible;
  }

  .swiper-container-cube .swiper-slide {
    pointer-events: none;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    z-index: 1;
    visibility: hidden;
    transform-origin: 0 0;
    width: 100%;
    height: 100%;
  }

  .swiper-container-cube .swiper-slide .swiper-slide {
    pointer-events: none;
  }

  .swiper-container-cube.swiper-container-rtl .swiper-slide {
    transform-origin: 100% 0;
  }

  .swiper-container-cube .swiper-slide-active {
    pointer-events: auto;
    pointer-events: auto;
    visibility: visible;
  }

  .swiper-container-cube .swiper-slide-active .swiper-slide-active {
    pointer-events: auto;
  }

  .swiper-container-cube .swiper-slide-next,
  .swiper-container-cube .swiper-slide-prev,
  .swiper-container-cube .swiper-slide-next + .swiper-slide {
    pointer-events: auto;
    visibility: visible;
  }

  .swiper-container-cube .swiper-slide-shadow-top,
  .swiper-container-cube .swiper-slide-shadow-bottom,
  .swiper-container-cube .swiper-slide-shadow-left,
  .swiper-container-cube .swiper-slide-shadow-right {
    z-index: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .swiper-container-cube .swiper-cube-shadow {
    position: absolute;
    left: 0;
    bottom: 0px;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.6;
    filter: blur(50px);
    z-index: 0;
  }

  .swiper-container-flip {
    overflow: visible;
  }

  .swiper-container-flip .swiper-slide {
    pointer-events: none;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    z-index: 1;
  }

  .swiper-container-flip .swiper-slide .swiper-slide {
    pointer-events: none;
  }

  .swiper-container-flip .swiper-slide-active {
    pointer-events: auto;
  }

  .swiper-container-flip .swiper-slide-active .swiper-slide-active {
    pointer-events: auto;
  }

  .swiper-container-flip .swiper-slide-shadow-top,
  .swiper-container-flip .swiper-slide-shadow-bottom,
  .swiper-container-flip .swiper-slide-shadow-left,
  .swiper-container-flip .swiper-slide-shadow-right {
    z-index: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .swiper-container-coverflow .swiper-wrapper {
    -ms-perspective: 1200px;
  }

  .flickity-flow.flickity-enabled {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .flickity-flow.flickity-resized .flickity-slider > * {
    min-height: 100%;
  }

  .flickity-flow > .flickity-viewport {
    overflow: visible !important;
    padding: 0 !important;
  }

  .flickity-flow .flickity-button,
  .flickity-flow .flickity-page-dots {
    padding: 0 !important;
  }

  .flickity-flow .flickity-button {
    background: none;
  }

  .flickity-flow .flickity-button:hover {
    background: none;
  }

  .flickity-flow .flickity-prev-next-button {
    top: 50%;
    width: 4rem;
    height: 4rem;
    border-radius: 0%;
    transform: translateY(-50%);
  }

  .flickity-flow .flickity-prev-next-button.previous {
    left: 10px;
  }

  .flickity-flow .flickity-prev-next-button.next {
    right: 10px;
  }

  .flickity-flow .flickity-prev-next-button .flickity-button-icon {
    position: absolute;
    left: 20%;
    top: 20%;
    width: 60%;
    height: 60%;
  }

  @media screen and (max-width: 25.999em) {
    .flickity-flow .flickity-slider > * {
      background-clip: content-box;
      padding-left: calc(30px / 2) !important;
      padding-right: calc(30px / 2) !important;
    }
  }

  @media screen and (min-width: 26em) and (max-width: 35.999em) {
    .flickity-flow .flickity-slider > * {
      background-clip: content-box;
      padding-left: calc(30px / 2) !important;
      padding-right: calc(30px / 2) !important;
    }
  }

  @media screen and (min-width: 36em) and (max-width: 51.999em) {
    .flickity-flow .flickity-slider > * {
      background-clip: content-box;
      padding-left: calc(30px / 2) !important;
      padding-right: calc(30px / 2) !important;
    }
  }

  @media screen and (min-width: 52em) and (max-width: 71.999em) {
    .flickity-flow .flickity-slider > * {
      background-clip: content-box;
      padding-left: calc(30px / 2) !important;
      padding-right: calc(30px / 2) !important;
    }
  }

  @media screen and (min-width: 72em) and (max-width: 103.999em) {
    .flickity-flow .flickity-slider > * {
      background-clip: content-box;
      padding-left: calc(40px / 2) !important;
      padding-right: calc(40px / 2) !important;
    }
  }

  @media screen and (min-width: 104em) {
    .flickity-flow .flickity-slider > * {
      background-clip: content-box;
      padding-left: calc(40px / 2) !important;
      padding-right: calc(40px / 2) !important;
    }
  }

  .flickity-enabled {
    position: relative;
  }

  .flickity-enabled:focus {
    outline: none;
  }

  .flickity-enabled.is-draggable {
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .flickity-enabled.is-draggable .flickity-viewport {
    cursor: grab;
  }

  .flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
    cursor: grabbing;
  }

  .flickity-viewport {
    overflow: hidden;
    position: relative;
    height: 100%;
  }

  .flickity-slider {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .flickity-button {
    position: absolute;
    background: rgba(255, 255, 255, 0.75);
    border: none;
    color: #333;
  }

  .flickity-button:hover {
    background: #fff;
    cursor: pointer;
  }

  .flickity-button:focus {
    outline: none;
    box-shadow: 0 0 0 5px #19f;
  }

  .flickity-button:active {
    opacity: 0.6;
  }

  .flickity-button:disabled {
    opacity: 0.3;
    cursor: auto;
    pointer-events: none;
  }

  .flickity-button-icon {
    fill: #333;
  }

  .flickity-prev-next-button {
    top: 50%;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    transform: translateY(-50%);
  }

  .flickity-prev-next-button.previous {
    left: 10px;
  }

  .flickity-prev-next-button.next {
    right: 10px;
  }

  .flickity-prev-next-button .flickity-button-icon {
    position: absolute;
    left: 20%;
    top: 20%;
    width: 60%;
    height: 60%;
  }

  .flickity-rtl .flickity-prev-next-button.previous {
    left: auto;
    right: 10px;
  }

  .flickity-rtl .flickity-prev-next-button.next {
    right: auto;
    left: 10px;
  }

  .flickity-rtl .flickity-page-dots {
    direction: rtl;
  }

  .flickity-page-dots {
    position: absolute;
    width: 100%;
    bottom: -25px;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    line-height: 1;
  }

  .flickity-page-dots .dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 8px;
    background: #333;
    border-radius: 50%;
    opacity: 0.25;
    cursor: pointer;
  }

  .flickity-page-dots .dot.is-selected {
    opacity: 1;
  }

  .lazyload,
  .lazyloading {
    opacity: 0;
    z-index: 1;
  }

  .lazyloaded {
    opacity: 1;
    transition: opacity 200ms;
  }

  .lazyhold,
  .lazyholding {
    opacity: 0;
  }

  .lazyholded {
    opacity: 1;
    transition: opacity 200ms;
  }

  .cookie-banner {
    display: none;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
  }

  @media screen and (max-width: 51.999em) {
    .cookie-banner .inner {
      flex-direction: column;
    }

    .cookie-banner .text {
      padding-right: 0;
      padding-bottom: 1.5em;
    }
  }

  .cookie-banner .text {
    padding-right: 2em;
    min-width: 0%;
    flex: 1 1 0%;
  }

  .cookie-banner .btns {
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content;
    flex: 0 0 auto;
  }
}
